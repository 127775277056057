import { createSelector } from '@reduxjs/toolkit'
import { connectionStatusSelector } from '../dataConnection'
import { syncStatusSelector } from '../dataSync'

const userStateSelector = (state) => state.user

const usersSelector = (state) => state.user.users

const latestUsersSelector = (state) => state.user.latestUsers

const clientInfoSelector = (state) => state.user.clientInfo

const userByIdSelector = (state) => state.user.userById

const userLoadingSelector = (state) => state.user.loading

const metricsSelector = (state) => state.user.metrics

const groupsSelector = (state) => state.user.groups

const userStatusSelector = (state) => state.user.status

const clientSettingsSelector = (state) => state.user.clientSettings

const apiKeySelector = (state) => state.user.apiKey

const appStatusSelector = createSelector(
  userStatusSelector,
  syncStatusSelector,
  connectionStatusSelector,
  (status1, status2, status3) => {
    const status = [status1, status2, status3]

    return {
      notFound: status.includes(404),
      maintenanceMode: status.includes(503),
    }
  }
)

export {
  userStateSelector,
  usersSelector,
  clientInfoSelector,
  latestUsersSelector,
  userByIdSelector,
  userLoadingSelector,
  metricsSelector,
  appStatusSelector,
  clientSettingsSelector,
  apiKeySelector,
  groupsSelector,
}
