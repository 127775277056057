import Button from '@mui/material/Button'
import React, { useCallback, useState } from 'react'

import DataSyncIcon from '../../../components/icons/DataSyncIcon'
import { useI18n } from '../../../context'
import SyncDataTypes from './SyncDataTypes'

const AddSyncButton = () => {
  const { t } = useI18n()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <Button
        variant="contained"
        size="large"
        className="Add"
        onClick={handleOpen}
        color="inherit"
        sx={{
          backgroundColor: '#fff',
          '&:hover': { borderColor: 'whitesmoke' },
        }}
        startIcon={
          <DataSyncIcon
            style={{ fontSize: '10px' }}
            // className={loadingTypesFound?.length ? 'rotate' : ''}
          />
        }
        disableElevation
      >
        {t('common.Sync')}
      </Button>

      <SyncDataTypes open={open} onClose={handleClose} />
    </>
  )
}

export default React.memo(AddSyncButton)
