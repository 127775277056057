import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { useAuth, useI18n, useNav } from '../../context'
import Breadcrumb from '../Breadcrumb'
import AddConnectionButton from '../../pages/DataConnections/AddConnection'
import AddSyncButton from '../../pages/DataSyncs/SyncDataTypes'
import CompanyPCL from '../CompanyPCL/CompanyPCL'
import { NavBreadcrumbRoot } from './NavBreadcrumb.style'

const NavBreadcrumb = ({ paths, className, ...other }) => {
  const { currentNav, getNavUrl, getNavTitle } = useNav()
  const { currentUser } = useAuth()
  const { t } = useI18n()

  const parentNav = currentNav?.parent
  const primary = currentNav?.requires?.includes('DASHBOARD')

  const showPclInfo =
    currentUser &&
    !primary &&
    getNavTitle(currentNav) !== t('common.collective_procedures')

  const segments = paths?.length
    ? paths
    : [
        parentNav && {
          name: getNavTitle(parentNav),
          path: getNavUrl(parentNav),
        },
        currentNav && {
          name: getNavTitle(currentNav),
          path: getNavUrl(currentNav),
        },
      ].filter(Boolean)

  return (
    <NavBreadcrumbRoot
      {...other}
      className={className + (primary ? ' Primary' : '')}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={{ xs: 1, sm: 3 }}
        flexWrap="wrap"
      >
        <Box>
          {currentUser && !primary && (
            <Typography variant="h6" fontWeight="600">
              {currentUser.name}
            </Typography>
          )}
          <Breadcrumb paths={segments} />
        </Box>

        {showPclInfo && <CompanyPCL />}
      </Box>

      {currentUser && !primary && (
        <Box display="flex" gap="8px">
          <AddSyncButton />
          <AddConnectionButton />
        </Box>
      )}
    </NavBreadcrumbRoot>
  )
}

export default NavBreadcrumb
