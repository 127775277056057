const groupBy = (getter, items) => {
  const get = (val) =>
    typeof getter === 'function' ? getter(val) : val?.[getter]

  return (
    items?.reduce(
      (result, item) => ({
        ...result,
        [get(item)]: [...(result[get(item)] || []), item],
      }),
      {}
    ) || {}
  )
}

const sum = (arr, getter) => {
  if (!arr) return 0

  const getCurrentVal = (val) =>
    typeof getter === 'function'
      ? +getter(val) || 0
      : +val?.[getter] || +val || 0

  return arr.reduce((p, c) => (p || 0) + getCurrentVal(c), 0)
}

const avg = (arr, getter, getter2) => {
  return sum(arr, getter) / (getter2 ? sum(arr, getter2) : arr.length)
}

const mapBy = (field, data) => {
  const res = {}

  data?.forEach?.((c) => {
    res[typeof field === 'function' ? field(c) : c[field]] = c
  })

  return res
}

const unique = (field, data) => {
  const by = groupBy(field, data)

  return [...new Set(Object.keys(by))].map((k) => by[k][0])
}

const splitInTwo = (arr, minLength = 1) => {
  if (arr.length <= minLength) return [arr, []]

  const middle = Math.ceil(
    Math.max(arr.length > minLength ? arr.length / 2 : arr.length, minLength)
  )

  return [arr.slice(0, middle), arr.slice(middle)]
}

const mergeObj = (objToOveride, input, skip = '') => {
  Object.keys({ ...input }).forEach((k) => {
    if (skip !== k) objToOveride[k] = input[k]
  })

  return objToOveride
}

export { groupBy, sum, avg, mapBy, splitInTwo, mergeObj, unique }
