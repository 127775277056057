/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import officerService from '../../services/officer'

const initialState = {
  officers: null,
  companyOfficers: null,
}

export const getOfficer = createAsyncThunk(
  'officer/getOfficer',
  officerService.getOfficer
)

export const getCompanyOfficers = createAsyncThunk(
  'officer/getCompanyOfficers',
  officerService.getCompanyOfficers
)

export const getOfficers = createAsyncThunk(
  'officer/getOfficers',
  officerService.getOfficers
)

const officer = createSlice({
  name: 'officer',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getOfficers.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.officers = data
    },
    [getCompanyOfficers.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.companyOfficers = data
    },
  },
})

export default officer.reducer
