import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const BankingIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M24 22.5a1.5 1.5 0 0 1-1.5 1.5h-21a1.5 1.5 0 0 1 0-3h21a1.5 1.5 0 0 1 1.5 1.5zM.37 9.341A3.03 3.03 0 0 1 .5 6.228a5.1 5.1 0 0 1 1.785-1.669L9.444.652a5.314 5.314 0 0 1 5.112 0l7.16 3.907A5.106 5.106 0 0 1 23.5 6.226a3.032 3.032 0 0 1 .134 3.115A3.126 3.126 0 0 1 21 10.991V16h.5a1.5 1.5 0 0 1 0 3h-19a1.5 1.5 0 0 1 0-3H3v-5.009a3.126 3.126 0 0 1-2.63-1.65zM6 16h2v-5H6zm5-5v5h2v-5zm7 5v-5h-2v5zM3 7.887A.14.14 0 0 0 3.131 8h17.738A.145.145 0 0 0 21 7.885a2.145 2.145 0 0 0-.721-.693l-7.16-3.906a2.325 2.325 0 0 0-2.236 0L3.722 7.192A2.1 2.1 0 0 0 3 7.887z" />
    </SvgIcon>
  )
}

export default BankingIcon
