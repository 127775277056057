import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { matchSorter } from 'match-sorter'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useI18n, useNav } from '../../../context'
import { activeProvidersSelector } from '../../../redux/provider'
import ConnexionHeader from '../UpdateConnexion/ConnexionHeader'
import ProviderSearchResult from './ProviderSearchResult'
import ProviderCategory from '../ProviderCategory'
import { useSearch } from '../../../hooks'
import { AddConnectionRoot } from '../AddConnection/AddConnection.style'
import DataConnexionIcon from '../../../components/icons/DataConnexionIcon'

const ProviderSearch = ({
  onClose,
  toProvider,
  toUniqueLink,
  setProvider,
  open,
}) => {
  const { t } = useI18n()
  const { currentNav } = useNav()
  const dataTypes = currentNav?.dataTypes

  const [values, setValues] = useState({ search: '', category: '', dataTypes })
  const providers = useSelector(activeProvidersSelector)

  const providerList = useMemo(
    () =>
      providers
        .filter((p) =>
          values.dataTypes?.length
            ? values.dataTypes.some((d) => p.data_types.includes(d))
            : true
        )
        .filter((p) =>
          !values.category || values.category === 'idle'
            ? true
            : p.category === values.category
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [providers?.length, values.category, values.dataTypes?.length, open]
  )

  const search = useSearch({
    q: values.search,
    data: providerList.map((p) => ({
      ...p,
      i18nName: t(`common.providers.${p.name}`),
    })),
    keys: [
      { threshold: matchSorter.rankings.CONTAINS, key: 'i18nName' },
      // (option) => option.name,
    ],
    sort: (a, b) => a.display_name.localeCompare(b.display_name),
  })

  const handleChange = (e) => {
    setValues({ ...values, search: e.target.value /* category: '' */ })
  }

  const handleSelect = (newProvider) => () => {
    setProvider(newProvider)
    toProvider()
  }

  const handleUniqueLink = () => toUniqueLink()

  const handleCategorySelect = (newVal) => {
    setValues({
      ...values,
      category: newVal,
      // dataTypes: newVal ? values.dataTypes : null,
    })
  }

  useEffect(() => {
    if (!open) setValues({ search: '', category: '', dataTypes })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (search.result?.length && values.dataTypes?.length && !values.category) {
      const searchCategories = [
        ...new Set(search.result.map((p) => p.category)),
      ]

      if (searchCategories.length === 1) {
        return setValues({ ...values, category: searchCategories[0] })
      }

      setValues({ ...values, category: 'idle' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.result?.length, open])

  return (
    <AddConnectionRoot>
      <ConnexionHeader
        title={t('common.Add connexion')}
        mb={1.5}
        onClose={onClose}
      />

      <Typography className="selectText">
        {t('common.selectProvider')}
      </Typography>

      <TextField
        value={values.search}
        onChange={handleChange}
        placeholder={t('common.search')}
        size="small"
        fullWidth
      />

      <Box
        display="flex"
        gap={2}
        mt={2}
        flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
      >
        <ProviderCategory
          value={values.category}
          dataTypes={values.dataTypes}
          onChange={handleCategorySelect}
        />

        <Box display="flex" flexGrow={1}>
          <Box height="100%" sx={{ borderLeft: '1px solid #e2e2e2;' }} />

          <ProviderSearchResult
            onSelect={handleSelect}
            onCategorySelect={handleCategorySelect}
            data={search.result}
            values={values}
            open={open}
          />
        </Box>
      </Box>
      <Box>
        <Tooltip title={t('common.unique_link')}>
          <IconButton onClick={handleUniqueLink} className="bt-unique-link">
            <DataConnexionIcon sx={{ fontSize: '12px' }} />
          </IconButton>
        </Tooltip>
      </Box>
    </AddConnectionRoot>
  )
}

export default ProviderSearch
