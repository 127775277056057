/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import webInfoService from '../../services/webInfo'

const initialState = {
  webInfos: null,
}

export const getWebInfos = createAsyncThunk(
  'webInfo/getWebInfos',
  webInfoService.getWebInfos
)

const webInfo = createSlice({
  name: 'webInfo',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getWebInfos.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data && data !== 'no content') {
        state.webInfos = data
      } else state.webInfos = null
    },
  },
})

export default webInfo.reducer
