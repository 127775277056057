/* eslint-disable max-lines */
import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { OpenInNew } from '@mui/icons-material'
import { useI18n } from '../../../context'
import { Button, CopyToClipboard } from '../../../components'
import { activeProvidersSelector } from '../../../redux/provider'
import Scrollbar from '../../../components/Scrollbar'
import {
  AddConnectionRoot,
  ProvidersRoot,
} from '../../DataConnections/AddConnection/AddConnection.style'
import UniqueLinkCategory from './UniqueLinkCategory'
import { isDev, LEGAL } from '../../../config'
import BackIcon from '../../../components/icons/BackIcon'
import ConnexionHeader from '../../DataConnections/UpdateConnexion/ConnexionHeader'

const UniqueLink = ({ user, onClose, ...other }) => {
  const { t } = useI18n()
  const url = user?.redirect_url
  const activeProviders = useSelector(activeProvidersSelector)
  const providersByCategory = []

  if (user.type === LEGAL) {
    providersByCategory.push({
      name: 'Financial',
      providers: activeProviders.filter((p) => p.name === 'impots_gouv'),
    })
  }

  providersByCategory.push(
    {
      name: 'Banking',
      providers: activeProviders.filter((p) => p.name === 'budget_insight'),
    },
    {
      name: 'Commerce',
      providers: activeProviders.filter(
        (p) => p.category === 'Commerce' && !p.name.includes('sandbox')
      ),
    }
  )

  return (
    <AddConnectionRoot>
      <ConnexionHeader
        title={t(`common.unique_link_connections`)}
        subTitle={t(`common.user_unique_link_description`)}
        mb={1.5}
        onClose={onClose}
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mt={3}
        gap={3}
      >
        <Box display="flex" width="100%" flexGrow={1}>
          <Box height="100%" sx={{ borderLeft: '1px solid #e2e2e2;' }} />
          <Scrollbar
            suppressScrollX
            visiblility="always"
            background="transparent"
            size="small"
            variant="square"
            offset={false}
            sx={{ flexGrow: 1 }}
          >
            <ProvidersRoot>
              {providersByCategory.map(
                (cat) =>
                  cat.providers.length !== 0 && (
                    <>
                      <Typography variant="subtitle1">
                        {t(`common.${cat.name}`)}
                      </Typography>

                      <Grid container spacing={1}>
                        {cat.providers.map((provider) => (
                          <Grid
                            key={provider.name}
                            item
                            xs={12}
                            md={6}
                            lg={6}
                            display="flex"
                            flexDirection="column"
                          >
                            <UniqueLinkCategory
                              key={provider.name}
                              provider={provider}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )
              )}
            </ProvidersRoot>
          </Scrollbar>
        </Box>
        <Typography
          className={`resultText ${url ? '' : 'hideSpan'}`}
          dangerouslySetInnerHTML={{
            __html: t('common.user_unique_link', {
              url: url ? t('common.user_unique_link') : '',
            }),
          }}
        />
        <Box gap="14px" className="Copy unique-link-box">
          {url && (
            <>
              {other?.onBack && (
                <Button
                  variant="text"
                  size="small"
                  onClick={other.onBack}
                  color="inherit"
                  className="bt-back"
                >
                  <BackIcon fontSize="small" />
                </Button>
              )}
              <Box
                className={
                  other?.onBack ? 'ellipsis ellipsis-unique-link' : 'ellipsis'
                }
              >
                <span className={isDev ? 'ellipsis text-url' : 'ellipsis'}>
                  {url}
                </span>
                {isDev && (
                  <Button
                    variant="contained"
                    size="medium"
                    target="_blank"
                    href={url}
                  >
                    <OpenInNew />
                  </Button>
                )}
              </Box>
              <CopyToClipboard
                tooltip={t('common.linkCopied')}
                value={url}
                timeout={2000}
              >
                <Button
                  variant="contained"
                  size="large"
                  className="btn-copy"
                  loading={false}
                >
                  {t('common.copy_link')}
                </Button>
              </CopyToClipboard>
            </>
          )}
        </Box>
      </Box>
    </AddConnectionRoot>
  )
}

export default UniqueLink
