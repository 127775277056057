import AppBar from '@mui/material/AppBar'
import styled, { css } from 'styled-components'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

export const AppbarRoot = styled(AppBar)`
  background-color: #041f3d;
  padding-right: 16px;
  color: white;

  .NavIcon {
    cursor: pointer;
    background: ${({ theme }) => theme.palette.primary.main};
    max-height: 70px;

    svg {
      color: ${({ theme }) => theme.palette.primary.main};
      font-size: 70px;
    }
  }

  .NavItem {
    color: white;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    cursor: pointer;

    &.active {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  .MuiAvatar-root {
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
    width: 32px;
    height: 32px;

    ${({ theme }) => css`
      ${theme.breakpoints.up('md')} {
        width: 40px;
        height: 40px;
      }
    `}
  }

  .right {
    h6 {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 24px;
  }

  .refreshIcon {
    font-size: 16px;
  }
`

export const UserAvatarRoot = styled(Menu)`
  > .MuiPaper-root {
    min-width: 200px;
  }
`

export const LanguageButton = styled(MenuItem)`
  .MuiListItemIcon-root {
    min-width: 42px;
  }
`

export const LanguageMenuRoot = styled(Menu)`
  .MuiMenuItem-root {
    padding-left: 16px;
    padding-right: 16px;
  }

  svg {
    font-size: 20px;
  }

  .MuiListItemIcon-root {
    min-width: 36px;
  }
`
