import { createSelector } from '@reduxjs/toolkit'

import { fromSchedule, mapBy } from '../../utils'

const providerStateSelector = (state) => state.provider

const providersSelector = (state) => state.provider.providers

const activeProvidersSelector = createSelector(
  providersSelector,
  (data) => data?.result.filter((p) => p.enabled) || []
)

const inActiveProvidersSelector = createSelector(
  providersSelector,
  (data) => data?.result.filter((p) => !p.enabled) || []
)

const providersByNameSelector = createSelector(providersSelector, (data) =>
  mapBy('name', data?.result)
)

const dataTypeProvidersSelector = createSelector(
  activeProvidersSelector,
  (data) => {
    const res = []

    data.forEach((provider) => {
      provider?.data_types?.forEach((dataType) => {
        res.push({ ...provider, dataType, data_types: undefined })
      })
    })

    return res
  }
)

const allowedDataTypesSelector = createSelector(
  activeProvidersSelector,
  (data) => data.map((p) => p.allowed_data_types).flat()
)

const syncSettingsSelector = (state) => state.provider.syncSettings

const syncSettingsDataSelector = createSelector(
  syncSettingsSelector,
  (data) => {
    const list = Object.values(data).map((d) => ({
      ...d,
      ...fromSchedule(d.schedule),
    }))

    return { data: mapBy('data_type_name', list), list }
  }
)

export {
  providerStateSelector,
  providersSelector,
  inActiveProvidersSelector,
  activeProvidersSelector,
  providersByNameSelector,
  dataTypeProvidersSelector,
  syncSettingsSelector,
  allowedDataTypesSelector,
  syncSettingsDataSelector,
}
