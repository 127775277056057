/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import companyService from '../../services/company'
import { mapBy } from '../../utils'

export const companyKey = 'company'

const initialState = {
  companies: null,
  companiesAutocomplete: null,
  profile: null,
  timelines: null,
  corporateOffices: null,
  collectiveProcedures: null,
  bankingRelationships: null,
  acts: null,
  pclByUser: {},
  dataConnectionsByUser: {},
  profileByUser: {},
}

export const getCompanyProfile = createAsyncThunk(
  'company/getCompanyProfile',
  companyService.getCompanyProfile
)

export const getTimelines = createAsyncThunk(
  'company/getTimelines',
  companyService.getTimelines
)

export const getCompanies = createAsyncThunk(
  'company/getCompanies',
  companyService.getCompanies
)

export const getCompaniesAutocomplete = createAsyncThunk(
  'company/getCompaniesAutocomplete',
  companyService.getCompaniesAutocomplete
)

export const getCompnayBySiren = createAsyncThunk(
  'company/getCompaniesAutocomplete',
  companyService.getCompanyBySiren
)

export const getCorporateOffices = createAsyncThunk(
  'company/getCorporateOffices',
  companyService.getCorporateOffices
)

export const getCollectiveProcedures = createAsyncThunk(
  'company/getCollectiveProcedures',
  companyService.getCollectiveProcedures
)

export const getPclByUsers = createAsyncThunk(
  'company/getPclByUsers',
  companyService.getPclByUsers
)

export const getDataConnectionsByUsers = createAsyncThunk(
  'company/getDataConnectionsByUsers',
  companyService.getDataConnectionsByUsers
)

export const getProfileByUsers = createAsyncThunk(
  'company/getProfileByUsers',
  companyService.getProfileByUsers
)

export const getBankingRelationships = createAsyncThunk(
  'company/getBankingRelationships',
  companyService.getBankingRelationships
)

export const getTrademarks = createAsyncThunk(
  'company/getTrademarks',
  companyService.getTrademarks
)

const company = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getCompanies.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.companies = data
    },
    [getCompaniesAutocomplete.pending]: (state) => {
      state.autocomplePending = true
      state.companiesAutocomplete = []
    },
    [getCompaniesAutocomplete.fulfilled]: (state, action) => {
      state.autocomplePending = false
      const { data } = action.payload

      state.companiesAutocomplete = data?.result
    },
    [getTimelines.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.timelines = data
    },
    [getCollectiveProcedures.fulfilled]: (state, action) => {
      const { data, userId } = action.payload

      state.collectiveProcedures = data
      state.pclByUser[data?.userId || userId] = data || { userId }
    },
    [getCorporateOffices.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.corporateOffices = data
    },
    [getPclByUsers.fulfilled]: (state, action) => {
      state.infoLoading = false
      const data = Array.isArray(action.payload) ? action.payload : []

      state.pclByUser = {
        ...state.pclByUser,
        ...mapBy('userId', data),
      }
    },
    [getDataConnectionsByUsers.fulfilled]: (state, action) => {
      state.infoLoading = false
      const data = Array.isArray(action.payload) ? action.payload : []

      state.dataConnectionsByUser = {
        ...state.dataConnectionsByUser,
        ...mapBy('userId', data),
      }
    },
    [getProfileByUsers.pending]: (state) => {
      state.infoLoading = true
    },
    [getPclByUsers.pending]: (state) => {
      state.infoLoading = true
    },
    [getDataConnectionsByUsers.pending]: (state) => {
      state.infoLoading = true
    },
    [getProfileByUsers.rejected]: (state) => {
      state.infoLoading = false
    },
    [getPclByUsers.rejected]: (state) => {
      state.infoLoading = false
    },
    [getDataConnectionsByUsers.rejected]: (state) => {
      state.infoLoading = false
    },
    [getProfileByUsers.fulfilled]: (state, action) => {
      state.infoLoading = false
      const data = Array.isArray(action.payload) ? action.payload : []

      state.profileByUser = {
        ...state.profileByUser,
        ...mapBy('userId', data),
      }
    },
    [getCompanyProfile.fulfilled]: (state, action) => {
      const { data, userId } = action.payload

      if (data && typeof data !== 'string') {
        state.profile = data
        localStorage.setItem(companyKey, JSON.stringify(data))
      } else {
        state.profile = null
        localStorage.removeItem(companyKey)
      }

      state.profileByUser[data?.userId || userId] = data || { userId }
    },
    [getBankingRelationships.pending]: (state) => {
      state.bankingRelationships = null
    },
    [getBankingRelationships.fulfilled]: (state, action) => {
      state.bankingRelationships = action.payload.data
    },
    [getTrademarks.fulfilled]: (state, action) => {
      state.trademarks = action.payload.data
    },
  },
})

export default company.reducer
