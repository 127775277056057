import React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import LensIcon from '@mui/icons-material/Lens'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'

const Breadcrumb = ({ paths }) => (
  <Breadcrumbs
    separator={<LensIcon color="text.secondary" sx={{ fontSize: '5px' }} />}
  >
    {paths?.map((route, index) =>
      index !== paths.length - 1 ? (
        <Link key={route.path} to={route.path}>
          <Typography color="text.secondary" variant="inherit">
            {route.name}
          </Typography>
        </Link>
      ) : (
        <Typography key={route.name} color="text.secondary" variant="inherit">
          {route.name}
        </Typography>
      )
    )}
  </Breadcrumbs>
)

export default Breadcrumb
