import formatDate from 'date-fns/format'
import lastDayOfWeek from 'date-fns/lastDayOfWeek'
import subDays from 'date-fns/subDays'
import fr from 'date-fns/locale/fr'
import en from 'date-fns/locale/en-US'
import capitalize from '@mui/utils/capitalize'

const dateLocales = { en, fr }

/**
 * Format date for the given local.
 * By providing a default string of 'PP' or any of its variants for `formatStr`
 * it will format dates in whichever way is appropriate to the locale
 */
const format = (date, formatStr = 'PP', options = {}) => {
  if (!date) return date

  const locale = options?.locale || localStorage.getItem('language') || 'en'

  return formatDate(new Date(date), formatStr, {
    ...options,
    locale: dateLocales[locale],
  })
}

const getWeeks = () => {
  return Array.from(Array(7).keys()).map((d) => {
    const date = subDays(lastDayOfWeek(new Date()), d)

    return {
      label: capitalize(format(date, 'EEEE')),
      value: String(date.getDay()),
    }
  })
}

export { dateLocales, format, getWeeks }
