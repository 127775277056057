import Box from '@mui/material/Box'
import styled from 'styled-components'

export const Status = styled(Box)`
  background: #fad9e2;
  border-radius: 5px;
  flex-grow: 1;
  padding: 3px 15px;
  margin-left: 8px;

  p {
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
  }
`
