import Stack from '@mui/material/Stack'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'

import {
  dataConnectionsSelector,
  getDataConnections,
} from '../../../redux/dataConnection'
import ListPagination from '../../../components/ListPagination'
import Table from '../../../components/Table'
import { useI18n } from '../../../context'
import { buildColumns } from './DataConnectionTable.util'
import { DataSyncTableRoot } from '../../DataSyncs/DataSyncTable/DataSyncTable.style'

const DataConnectionTable = () => {
  const { t } = useI18n()
  const columns = buildColumns(t)

  const data = useSelector(dataConnectionsSelector)
  const dispatch = useDispatch()

  const connections = data?.result || []

  const [page, setPage] = useState(1)

  const handleChange = (e, value) => {
    setPage(value)
  }

  useEffect(() => {
    if (page === data?.current_page) return

    dispatch(getDataConnections({ page, per_page: 20 }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <>
      <DataSyncTableRoot>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          justifyContent="flex-end"
          spacing={2}
          mb={2}
        >
          {data?.last_page > 1 && (
            <ListPagination
              count={data.count}
              page={page}
              onChange={handleChange}
            />
          )}
        </Stack>

        {!!connections?.length && (
          <Table
            columns={columns}
            rows={connections}
            className="Table"
            rowsPerPageOptions={[]}
            onSelectedRowChange={data.handleSelectChange}
            // getRowSelected={(row) => row.id}
            selectedRows={data.selectedRows}
          />
        )}

        {!connections?.length && (
          <Typography mb={2}>{t('common.no_data')}</Typography>
        )}

        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          {data?.last_page > 1 && (
            <ListPagination
              count={data.count}
              page={page}
              onChange={handleChange}
            />
          )}
        </Stack>
      </DataSyncTableRoot>
    </>
  )
}

export default DataConnectionTable
