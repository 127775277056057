import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const BankAccountIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 13 12">
      <path d="M12.025 0.629778C11.7205 0.375156 11.3637 0.190698 10.9798 0.0895006C10.596 -0.0116965 10.1946 -0.0271504 9.80417 0.0442363L7.73392 0.420153C7.25595 0.507926 6.82137 0.753834 6.5 1.11836C6.17779 0.753204 5.74197 0.507241 5.26283 0.420153L3.19583 0.0442363C2.80538 -0.0272128 2.40399 -0.0119615 2.02008 0.0889107C1.63617 0.189783 1.27913 0.37381 0.974223 0.627965C0.669319 0.882121 0.424009 1.20019 0.255657 1.55966C0.0873048 1.91913 2.64401e-05 2.31121 0 2.70815L0 8.55436C3.11219e-05 9.18854 0.222612 9.80261 0.628947 10.2895C1.03528 10.7764 1.5996 11.1053 2.22354 11.2188L5.62846 11.8379C6.20477 11.9427 6.79523 11.9427 7.37154 11.8379L10.7792 11.2188C11.4026 11.1047 11.9663 10.7756 12.3721 10.2888C12.7779 9.80191 13.0001 9.18816 13 8.55436V2.70815C13.0002 2.31135 12.913 1.91938 12.7445 1.56014C12.576 1.2009 12.3303 0.883243 12.025 0.629778V0.629778ZM5.95833 10.7942C5.91283 10.7877 5.86733 10.7801 5.82183 10.7719L2.41746 10.1534C2.04304 10.0852 1.70441 9.88787 1.4606 9.59565C1.21679 9.30344 1.08328 8.93493 1.08333 8.55436V2.70815C1.08333 2.27718 1.25454 1.86385 1.55928 1.5591C1.86403 1.25436 2.27736 1.08315 2.70833 1.08315C2.80642 1.08339 2.9043 1.09227 3.00083 1.10969L5.07 1.48886C5.31896 1.53442 5.54411 1.6657 5.70637 1.85993C5.86864 2.05415 5.95778 2.29906 5.95833 2.55215V10.7942ZM11.9167 8.55436C11.9167 8.93493 11.7832 9.30344 11.5394 9.59565C11.2956 9.88787 10.957 10.0852 10.5825 10.1534L7.17817 10.7719C7.13267 10.7801 7.08717 10.7877 7.04167 10.7942V2.55215C7.04163 2.29844 7.13064 2.05277 7.29318 1.85796C7.45571 1.66315 7.68147 1.53157 7.93108 1.48615L10.0008 1.10699C10.2352 1.06434 10.4761 1.07376 10.7064 1.13456C10.9368 1.19537 11.1509 1.30608 11.3337 1.45886C11.5165 1.61163 11.6635 1.80273 11.7642 2.01862C11.865 2.23451 11.917 2.46992 11.9167 2.70815V8.55436Z" />
    </SvgIcon>
  )
}

export default BankAccountIcon
