import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import taxReturnService from '../../services/taxReturn'

const initialState = {
  taxReturnAnalysis: null,
  taxReturns: null,
  sectorAnalysis: null,
  sectorAnalysisById: {},
  taxReturnAnalysisList: [],
  taxReturnAnalysisDate: new Date().toISOString(),
  taxClearanceCertificate: null,
}

const names = {
  ebe: 'EBE',
  working_capital_requirement: 'BFR',
  equity: 'Capitaux propres',
  total_liabilities: 'Total dette',
}

export const getTaxReturns = createAsyncThunk(
  'taxReturn/getTaxReturns',
  taxReturnService.getTaxReturns
)

export const getSectorAnalysis = createAsyncThunk(
  'taxReturn/getSectorAnalysis',
  taxReturnService.getSectorAnalysis
)

export const getSectorAnalysisById = createAsyncThunk(
  'taxReturn/getSectorAnalysisById',
  taxReturnService.getSectorAnalysisById
)

export const getTaxReturn = createAsyncThunk(
  'taxReturn/getTaxReturn',
  taxReturnService.getTaxReturn
)

export const getTaxClearanceCertificate = createAsyncThunk(
  'taxReturn/getTaxClearanceCertificate',
  taxReturnService.getTaxClearanceCertificate
)

export const getTaxReturnAnalysis = createAsyncThunk(
  'taxReturn/getTaxReturnAnalysis',
  taxReturnService.getTaxReturnAnalysis
)

const taxReturn = createSlice({
  name: 'taxReturn',
  initialState,
  reducers: {
    updateTaxReturnAnalysisDate: (state, action) => {
      state.taxReturnAnalysisDate = action.payload.toISOString()
    },
  },
  extraReducers: {
    [getTaxReturnAnalysis.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data?.length) {
        state.taxReturnAnalysis = data.sort(
          (a, b) => +a.closing_year - +b.closing_year
        )

        const list = data
          .map((d) => {
            return Object.keys(names).map((field) => ({ ...d, field }))
          })
          .flat()

        list.sort(
          (a, b) =>
            new Date(b.closing_date).getTime() -
            new Date(a.closing_date).getTime()
        )

        state.taxReturnAnalysisList = list || []
        state.taxReturnAnalysisDate =
          list[0].closing_date || new Date().toISOString()
      } else {
        state.taxReturnAnalysis = null
        state.taxReturnAnalysisList = []
      }
    },
    [getTaxReturns.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.taxReturns = data
    },
    [getTaxClearanceCertificate.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.taxClearanceCertificate = data
    },
    [getSectorAnalysis.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.sectorAnalysis = data
      state.sectorAnalysisById = {}
    },
    [getSectorAnalysisById.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.sectorAnalysisById[data.id] = data
    },
  },
})

export default taxReturn.reducer

export const { updateTaxReturnAnalysisDate } = taxReturn.actions
