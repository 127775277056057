import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const SettingsIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 23">
      <path d="M0.374342 16.671C1.14043 18.0006 2.83935 18.4574 4.16896 17.6914C4.16987 17.6908 4.17074 17.6903 4.17165 17.6898L4.58378 17.4518C5.36176 18.1174 6.25601 18.6336 7.22149 18.9744V19.4495C7.22149 20.9841 8.46548 22.2281 10 22.2281C11.5345 22.2281 12.7785 20.9841 12.7785 19.4495V18.9744C13.7441 18.6331 14.6384 18.1163 15.4162 17.4499L15.8302 17.6889C17.1601 18.4561 18.8603 18 19.6275 16.6701C20.3948 15.3402 19.9387 13.64 18.6087 12.8728L18.1975 12.6357C18.3826 11.6284 18.3826 10.5959 18.1975 9.58858L18.6087 9.3515C19.9386 8.58424 20.3948 6.88414 19.6275 5.55419C18.8603 4.22428 17.1602 3.76813 15.8302 4.53539L15.4181 4.77343C14.6393 4.10863 13.7445 3.59339 12.7785 3.25363V2.77851C12.7785 1.24399 11.5345 0 10 0C8.46548 0 7.22149 1.24399 7.22149 2.77851V3.25363C6.25588 3.595 5.36159 4.1118 4.58378 4.77812L4.16978 4.53826C2.83983 3.77095 1.13973 4.22711 0.372476 5.55701C-0.394783 6.88692 0.0613263 8.58706 1.39128 9.35432L1.80249 9.5914C1.61738 10.5987 1.61738 11.6312 1.80249 12.6385L1.39128 12.8756C0.0650166 13.6449 -0.389617 15.3417 0.374342 16.671ZM10 7.40936C12.046 7.40936 13.7047 9.068 13.7047 11.114C13.7047 13.16 12.046 14.8187 10 14.8187C7.95398 14.8187 6.29534 13.16 6.29534 11.114C6.29534 9.068 7.95398 7.40936 10 7.40936Z" />
    </SvgIcon>
  )
}

export default SettingsIcon
