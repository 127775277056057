import Button from '@mui/material/Button'
import React, { useCallback, useEffect, useState } from 'react'
import Add from '@mui/icons-material/Add'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { useI18n } from '../../../context'
import AddConnection from './AddConnection'
import {
  dataConnectionsSelector,
  getAllDataConnections,
  getDataConnections,
} from '../../../redux/dataConnection'
import { useThunk, useVisbilityState } from '../../../hooks'

const AddConnectionButton = () => {
  const { t } = useI18n()
  const data = useSelector(dataConnectionsSelector)
  const { dispatch, run } = useThunk()
  const history = useHistory()

  const { state } = useLocation()

  const [open, setOpen] = useState(state?.open || false)

  const handleOpen = () => setOpen(true)

  const connections = data?.result || []

  const canDispatch =
    !connections?.length ||
    connections.find(
      (c) =>
        c.status === 'AUTH_REQUIRED' ||
        c.status === 'CONNECTING' ||
        c.status === 'QUEUED'
    )

  const onVisible = () => {
    if (canDispatch) dispatch(() => getDataConnections({}))
    run(getAllDataConnections({ page: 1, per_page: 1000 }))
  }

  useVisbilityState({ onVisible })

  const handleClose = useCallback(() => {
    setOpen(false)

    history.replace({
      ...history.location,
      state: { ...history.location.state, open: false },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.open])

  useEffect(() => {
    setOpen(state?.open)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.open])

  return (
    <>
      <Button
        variant="contained"
        size="large"
        className="Add"
        onClick={handleOpen}
        startIcon={<Add sx={{ fontSize: '16px !important' }} />}
        color="primary"
        disableElevation
      >
        {t('common.Connexion')}
      </Button>

      <AddConnection open={open} onClose={handleClose} />
    </>
  )
}

export default React.memo(AddConnectionButton)
