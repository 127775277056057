import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'

const TRow = ({
  row,
  getRowSelected,
  setSelectedRows,
  onSelectedRowChange,
  selectedRows = [],
  columns = [],
  rowsByKey,
  RowDetails,
  width,
}) => {
  const [open, setOpen] = useState(false)

  const handleRowSelectChange = (_event) => {
    if (!getRowSelected) return

    const rowKey = getRowSelected(row)
    const selectedIndex = selectedRows.indexOf(rowKey)

    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, rowKey)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1))
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      )
    }

    setSelectedRows?.(newSelected)
    onSelectedRowChange?.(newSelected.map((r) => rowsByKey[r]))
  }

  return (
    <>
      <TableRow className={row.className} style={row.style}>
        {RowDetails && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}

        {getRowSelected && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={selectedRows.includes(getRowSelected(row))}
              onChange={handleRowSelectChange}
            />
          </TableCell>
        )}
        {columns.map(({ render, key, onClick, ...rest }) => (
          <TableCell
            {...rest}
            key={key}
            onClick={onClick ? (e) => onClick(row, e) : undefined}
            sx={{ ...rest?.sx, cursor: onClick ? 'pointer' : undefined }}
          >
            {render ? render(row) : row[key]}
          </TableCell>
        ))}
      </TableRow>

      {RowDetails && (
        <TableRow>
          <TableCell
            style={{ padding: 0 }}
            colSpan={columns.length + (getRowSelected ? 2 : 1)}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box maxWidth={width}>
                <RowDetails data={row} columns={columns} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default TRow
