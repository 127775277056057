import React, { useEffect } from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useI18n, useAuth } from '../../context'
import { format } from '../../utils'
import { CompanyUserIcon } from '../icons'
import { SidebarUserRoot, TooltipContent } from './SidebarUser.style'
import { getUserId } from '../../services/user'
import { getGroups } from '../../redux'
import EditUser from '../../pages/Users/EditUser'

const SidebarUser = () => {
  const { currentUser } = useAuth()
  const { t } = useI18n()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getGroups())
  }, [dispatch])

  const isActive = pathname === `/user/${getUserId()}/company`

  if (!currentUser) return null

  const { created_at, group, photo, siren, first_name, last_name, birth_date } =
    currentUser
  const name = currentUser.name || `${first_name} ${last_name}`

  const identifier = siren ? (
    <>{siren}</>
  ) : (
    <Tooltip title={t('common.birth_date')} arrow>
      <Typography
        className="SubTitle"
        variant="body2"
        whiteSpace="nowrap"
        width="fit-content"
      >
        {format(birth_date, 'dd MMM yyyy')}
      </Typography>
    </Tooltip>
  )

  return (
    <SidebarUserRoot
      direction="row"
      className={`SidebarUser ${isActive ? 'active' : ''}`}
      spacing="10px"
      alignContent="center"
      component={Link}
      to={`/user/${currentUser.id}/company`}
    >
      <Avatar src={photo} alt={name}>
        <CompanyUserIcon />
      </Avatar>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flexGrow={1}
        maxWidth="calc(100% - 40px)"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Tooltip
            arrow
            title={
              <TooltipContent>
                <p>{name}</p>
                <span>
                  {t('common.userAddedAt', {
                    date: format(created_at, 'PPP'),
                  })}
                </span>
              </TooltipContent>
            }
          >
            <Typography variant="h6" className="ellipsis Title">
              {name}
            </Typography>
          </Tooltip>
          <EditUser user={currentUser} />
        </Box>
        {identifier && (
          <>
            <Typography
              variant="body2"
              color="text.secondary"
              className="ellipsis SubTitle"
              fontSize={12}
            >
              {identifier}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              className="ellipsis SubTitle"
              fontSize={10}
            >
              {group}
            </Typography>
          </>
        )}
      </Box>
    </SidebarUserRoot>
  )
}

export default React.memo(SidebarUser)
