import React, { createContext, useContext, useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'

import { GlobalStyle } from '../../styles'
import lightTheme from './lightTheme'
import darkTheme from './darkTheme'

const ThemeContext = createContext({
  Themeigations: [],
  currentTheme: null,
  ThemeigationsByPath: {},
  currentThemeTitle: {},
})

const themes = [lightTheme, darkTheme]

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(lightTheme)

  const changeTheme = (name) => {
    if (name === theme.name) return

    const newTheme = themes.find((t) => t.name === name)

    if (newTheme) setTheme(newTheme)
  }

  const value = { theme, setTheme: changeTheme }

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={value.theme}>
        <ThemeContext.Provider value={value}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <GlobalStyle />
          {children}
        </ThemeContext.Provider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}

const useTheme = () => {
  return useContext(ThemeContext)
}

export { useTheme }

export default ThemeProvider
