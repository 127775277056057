import Box from '@mui/material/Box'
import styled from 'styled-components'

export const AddConnectionRoot = styled.form`
  padding: 16px 20px;

  .MuiTextField-root {
    &,
    .MuiInputBase-root {
      font-size: 13px;
    }
  }

  h6 {
    font-weight: 600;
    font-size: 16px;
  }

  b {
    font-weight: 600;
  }

  .selectText {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 11px;
  }

  .scrollbar-container {
    flex-grow: 1;
    margin-right: -4px !important;
  }

  .ConnexionActions {
    .MuiButton-text {
      min-width: 32px;

      svg {
        font-size: 15px;
      }
    }
  }

  .toggler {
    font-weight: 500;
    font-size: 10px !important;
    line-height: 14px;
    color: #7e8299;

    &:not(:hover) {
      background-color: transparent !important;
    }
  }

  .list {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }

  .resultText {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;

    b {
      font-weight: 700;
    }

    &.hideSpan span {
      display: none;
    }
  }

  .subTitle {
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: #a1a5b7;
  }

  .Copy {
    > .ellipsis {
      background: #f4f4f4;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      color: #666666;
      padding: 3px 12px;
    }
    > .ellipsis-unique-link {
      display: inline-block;
      max-width: calc(100% - 70px) !important;
    }
  }

  .text-url {
    display: inline-flex;
    max-width: calc(100% - 35px) !important;
  }

  .btn-copy {
    text-wrap: nowrap;
  }

  .unique-link-box {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .bt-back {
    font-size: 12px !important;
    font-weight: 500;
    line-height: 18px;
    min-height: 36px;
    min-width: 35px;
    background: #f4f4f4;

    &.hover {
      background: #deddde;
    }
  }

  .bt-unique-link {
    padding: 12px;
    background: rgba(220, 220, 223, 0.5);

    :hover {
      background: rgba(220, 220, 223, 0.9);
    }

    svg {
      color: #848484;
      font-size: 16px;
    }
  }
`

export const ProvidersRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 11px;
  padding-right: 8px;
  flex-grow: 1;
  height: 250px;
`
