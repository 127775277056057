/* eslint-disable eqeqeq */
/* eslint-disable react/no-danger */
import React from 'react'

import { ErrorRoot, ActionBox, IconImage } from './Error.style'

/**
 * Used to feed back the results of a series of operational tasks that lead to an error.
 */
const Error = ({
  status = '500',
  title = status,
  subTitle,
  actions,
  ...other
}) => {
  const errorProps = {
    500: {
      text: subTitle || 'Sorry, something went wrong.',
      icon: '/assets/500.svg',
    },
    503: {
      text:
        subTitle ||
        'La plateforme Qard. est actuellement en cours de maintenance. Si besoin, vous pouvez toujours <a href="https://www.qardfinance.com/a-propos/">nous contacter</a>. Le site sera de retour très bientôt',
      icon: '/assets/503.svg',
    },
    403: {
      text: subTitle || 'Sorry, you are not authorized to access this page.',
      icon: '/assets/404.svg',
    },
    404: {
      text: subTitle || 'Sorry, the page you visited does not exist.',
      icon: '/assets/404.svg',
    },
  }

  return (
    <ErrorRoot {...other}>
      <IconImage src={errorProps[status].icon} alt={`Error ${status}`} />
      <h2>{title}</h2>
      <p dangerouslySetInnerHTML={{ __html: errorProps[status].text }} />
      {actions && status != 503 && <ActionBox>{actions}</ActionBox>}
    </ErrorRoot>
  )
}

export default Error
