import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { OpenInNew } from '@mui/icons-material'
import { Button, CopyToClipboard, useSyncher } from '../../../components'
import ConnexionHeader from '../UpdateConnexion/ConnexionHeader'
import { AddConnectionRoot } from './AddConnection.style'
import { useI18n } from '../../../context'
import ConnectionSuccessIcon from '../../../components/icons/ConnectionSuccessIcon'
import ConnectionErrorIcon from '../../../components/icons/ConnectionErrorIcon'
import { isDev, SUPPORT_EMAIL } from '../../../config'
import { useThunk } from '../../../hooks'
import { addDataSync } from '../../../redux/dataSync'

const ConnectionResult = ({ provider, connection, error, onClose }) => {
  const { t } = useI18n()
  const { dispatch } = useThunk()
  const url = connection?.redirect_url || connection?.return_url

  const sync = useSyncher({
    dataTypes: [...(connection?.requested_data_types || [])],
  })

  const handleSync = () => {
    const data_types = [...(connection?.requested_data_types || [])]

    if (data_types) {
      dispatch(() => addDataSync({ data_types }))
    }
    onClose()
  }

  return (
    <AddConnectionRoot>
      <ConnexionHeader
        title={t('common.Add connexion')}
        subTitle={t(`common.providers.${provider?.name}`)}
        onClose={onClose}
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mt={3}
        gap={3}
      >
        {error ? (
          <ConnectionErrorIcon sx={{ fontSize: '65px' }} />
        ) : (
          <ConnectionSuccessIcon sx={{ fontSize: '65px' }} />
        )}

        {!error && (
          <Typography
            className={`resultText ${url ? '' : 'hideSpan'}`}
            dangerouslySetInnerHTML={{
              __html: t('common.connectionSuccess', {
                provider: t(`common.providers.${provider?.name}`),
                url: url ? t('common.connectionUrl') : '',
              }),
            }}
          />
        )}

        {error && (
          <Typography
            className="resultText"
            dangerouslySetInnerHTML={{
              __html: t('common.connectionError', {
                provider: t(`common.providers.${provider?.name}`),
                email: SUPPORT_EMAIL,
              }),
            }}
          />
        )}

        <Box
          gap="14px"
          display="flex"
          alignItems="center"
          className="Copy"
          width="100%"
        >
          {!error && url && (
            <>
              <Box className="ellipsis">
                <span className={isDev ? 'ellipsis text-url' : 'ellipsis'}>
                  {url}
                </span>
                {isDev && (
                  <Button
                    variant="contained"
                    size="large"
                    target="_blank"
                    href={url}
                  >
                    <OpenInNew />
                  </Button>
                )}
              </Box>

              <CopyToClipboard
                tooltip={t('common.linkCopied')}
                value={url}
                timeout={2000}
              >
                <Button variant="contained" className="btn-copy">
                  {t('common.copy_link')}
                </Button>
              </CopyToClipboard>
            </>
          )}

          {error && (
            <CopyToClipboard value={SUPPORT_EMAIL} timeout={2000}>
              <Button variant="contained" sx={{ margin: 'auto' }}>
                {t("common.Copier l'email")}
              </Button>
            </CopyToClipboard>
          )}

          {!error && !url && sync.connected && (
            <Button
              variant="contained"
              onClick={handleSync}
              sx={{ margin: 'auto' }}
              loading={sync.loading}
            >
              {sync.loading
                ? t('common.sync_pending')
                : t('common.Lancer la synchronisation')}
            </Button>
          )}
        </Box>

        {sync.Status}
      </Box>
    </AddConnectionRoot>
  )
}

export default ConnectionResult
