import Typography from '@mui/material/Typography'
import React from 'react'

import { Button } from '../../../components'
import { useI18n } from '../../../context'
import { UserFormRoot } from './AddUser.style'
import UserFormHeader from './UserFormHeader'

const UserSelect = ({ onSelect, onClose }) => {
  const { t } = useI18n()

  const handleSelect = (type) => () => {
    onSelect(type)
  }

  return (
    <UserFormRoot>
      <UserFormHeader onClose={onClose} />

      <Typography variant="button" className="subTitle" mb={1}>
        {t('common.Create new user')}
      </Typography>

      <Button
        className="SubmitButton"
        variant="contained"
        fullWidth
        sx={{ marginTop: 1 }}
        onClick={handleSelect('legal')}
      >
        {t('common.Legal user')}
      </Button>

      <Button
        className="SubmitButton"
        variant="contained"
        fullWidth
        color="secondary"
        sx={{ marginTop: 1 }}
        onClick={handleSelect('natural')}
      >
        {t('common.natural_user')}
      </Button>
    </UserFormRoot>
  )
}

export default UserSelect
