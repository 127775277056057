import React, { forwardRef } from 'react'
import LensIcon from '@mui/icons-material/Lens'
import Stack from '@mui/material/Stack'

const Loader = forwardRef(({ title, ...props }, ref) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      spacing="20px"
      {...props}
      ref={ref}
      title={title}
    >
      <LensIcon
        sx={{
          color: '#C4C4C4',
          animation: 'zoom-in-zoom-out 1.5s ease infinite',
          fontSize: '16px',
        }}
        title={title}
      />
      <LensIcon
        sx={{
          color: '#A3A2A2',
          animation: 'zoom-in-zoom-out 1.5s ease infinite',
          fontSize: '16px',
          animationDelay: '200ms',
        }}
        title={title}
      />
      <LensIcon
        sx={{
          color: '#757272',
          animation: 'zoom-in-zoom-out 1.5s ease infinite',
          fontSize: '16px',
          animationDelay: '400ms',
        }}
        title={title}
      />
    </Stack>
  )
})

Loader.displayName = 'Loader'

export default Loader
