import React from 'react'
import { useSelector } from 'react-redux'

import { useI18n } from '../../context'
import { useThunk } from '../../hooks'
import { addDataSync, loadingTypesSelector } from '../../redux/dataSync'
import { getUserId } from '../../services/user'
import Button from '../Button'
import DataSyncIcon from '../icons/DataSyncIcon'
import DataSyncAlert from './DataSyncAlert'
import DataType, { syncProviders } from './DataType'

const ConnectionToSyncAlert = ({
  onClose,
  closedAlert,
  dataTypesToSync,
  connectionsToSync,
  dataTypes,
}) => {
  const { t } = useI18n()
  const loadingTypes = useSelector(loadingTypesSelector)
  const { dispatch } = useThunk()

  const loadingTypesFound = loadingTypes?.filter((type) =>
    dataTypes?.includes(type)
  )

  const id = `ConnectionToSync_${syncProviders(
    connectionsToSync
  )}_${getUserId()}`

  const closed = !!closedAlert?.[id]
  const open = !!dataTypesToSync?.length && !closed

  const handleClose = () => {
    onClose?.(id)
  }

  const handleAllSync = () => {
    dispatch(() => addDataSync({ data_types: dataTypesToSync }))
  }

  return (
    <DataSyncAlert
      title={t('common.alert.connectionNotSynched.title')}
      status="CONNECTED"
      onClose={handleClose}
      open={open}
    >
      {t('common.alert.connectionNotSynched')}
      <DataType syncs={dataTypesToSync?.map((data_type) => ({ data_type }))} />

      <Button
        variant="outlined"
        sx={{
          color: '#4195DA',
          borderColor: '#4195DA',
          backgroundColor: 'fff',
          '&:hover': { borderColor: '#4195DA' },
        }}
        startIcon={
          <DataSyncIcon
            style={{ fontSize: '12px' }}
            className={loadingTypesFound?.length ? 'rotate' : ''}
          />
        }
        onClick={handleAllSync}
      >
        {t('common.Sync')}
      </Button>
    </DataSyncAlert>
  )
}

export default ConnectionToSyncAlert
