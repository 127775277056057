const documentStateSelector = (state) => state.document

const inseeRegistrationProofSelector = (state) =>
  state.document.inseeRegistrationProof

const beneficialOwnersSelector = (state) => state.document.beneficialOwners

const actsSelector = (state) => state.document.acts

const articlesOfAssociationSelector = (state) =>
  state.document.articlesOfAssociation

const registrationProofSelector = (state) => state.document.registrationProof

export {
  inseeRegistrationProofSelector,
  beneficialOwnersSelector,
  registrationProofSelector,
  articlesOfAssociationSelector,
  actsSelector,
  documentStateSelector,
}
