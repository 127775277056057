/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react'

import TableContainer from '@mui/material/TableContainer'
import MuiTable from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TablePagination from '@mui/material/TablePagination'
import TableBody from '@mui/material/TableBody'
import Checkbox from '@mui/material/Checkbox'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import Box from '@mui/material/Box'

import { useSize } from '../../hooks'
import TRow from './TRow'
import { TableRoot } from './Table.style'
import { getComparator, stableSort } from './Table.util'

const Table = ({
  columns = [],
  rows = [],
  page: pageNumber = 0,
  rowsPerPage: pageSize = 25,
  rowsPerPageOptions = [25, 50, 100],
  count = 0,
  onPageChange = () => '',
  onPageSizeChange = () => '',
  labelRowsPerPage,
  pageTop,
  showFirstButton,
  showLastButton,
  onSelectedRowChange,
  getRowSelected,
  selectedRows: selectedRowsProp = [],
  RowDetails,
  size,
  sorter,
  sort,
  sortableData = rows,
  onSortChange,
  stickyHeader = true,
  maxHeight,
  ...other
}) => {
  const [page, setPage] = useState(pageNumber)
  const [rowsPerPage, setRowsPerPage] = useState(pageSize)
  const [order, setOrder] = useState(sort?.order || 'asc')
  const [orderBy, setOrderBy] = useState(sort?.by)

  const data = sortableData
    ? stableSort(sortableData, getComparator(order, orderBy))
    : rows

  const { ref, width } = useSize()

  const selectedRowsKeys = selectedRowsProp
    .filter(Boolean)
    .map((r) => (typeof r === 'string' ? r : getRowSelected?.(r)))

  const [selectedRows, setSelectedRows] = useState(selectedRowsKeys)
  const rowsByKey = {}

  if (getRowSelected) {
    rows.forEach((r) => {
      rowsByKey[getRowSelected(r)] = r
    })
  }

  const SelectProps = {
    ...other.SelectProps,
    labelId:
      other.SelectProps?.labelId ||
      (other.SelectProps?.id ? `label-${other.SelectProps?.id}` : undefined),
  }

  const handleRequestSort = (property) => (_event) => {
    const isAsc = orderBy === property && order === 'asc'
    const sortOrder = isAsc ? 'desc' : 'asc'

    setOrder(sortOrder)
    setOrderBy(property)
    onSortChange?.({ by: property, order: sortOrder })
  }

  const handlePageChange = (_e, newPage) => {
    setPage(newPage)
    onPageChange(newPage)
  }

  const handleSelectAllClick = (event) => {
    if (!getRowSelected) return

    if (event.target.checked) {
      const newSelecteds = rows.map(getRowSelected)

      setSelectedRows(newSelecteds)

      return onSelectedRowChange?.(
        newSelecteds.map((r) => rowsByKey[r]).filter(Boolean)
      )
    }

    setSelectedRows([])
    onSelectedRowChange?.([])
  }

  const handleRowsPerPageChange = (e) => {
    const newPageSize = +e.target.value

    setRowsPerPage(newPageSize)
    onPageSizeChange(newPageSize)

    if (page !== 0) setPage(0)
  }

  useEffect(() => {
    setPage(pageNumber)

    if (selectedRows?.length) {
      setSelectedRows([])
      onSelectedRowChange?.([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber])

  useEffect(() => {
    setRowsPerPage(pageSize)

    if (page !== 0) setPage(0)

    if (selectedRows?.length) {
      setSelectedRows([])
      onSelectedRowChange?.([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  useEffect(() => {
    setSelectedRows(selectedRowsKeys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsKeys?.length])

  return (
    <TableRoot {...other} ref={ref}>
      {rowsPerPageOptions.length > 0 && pageTop && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count || rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelRowsPerPage={labelRowsPerPage}
          SelectProps={SelectProps}
          showFirstButton={showFirstButton}
          showLastButton={showLastButton}
        />
      )}
      <TableContainer className="TableContainer" sx={{ maxHeight }}>
        <MuiTable size={size} stickyHeader={stickyHeader}>
          <TableHead>
            <TableRow sx={{ pointerEvents: 'none' }}>
              {RowDetails && <TableCell />}
              {getRowSelected && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < rows.length
                    }
                    checked={
                      rows.length > 0 && selectedRows.length === rows.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all' }}
                  />
                </TableCell>
              )}
              {columns.map(
                ({ label, key, render: _, order: hasOrder, ...rest }) => (
                  <TableCell
                    {...rest}
                    key={key}
                    sortDirection={orderBy === key ? order : false}
                  >
                    {!hasOrder && (rest.children || label)}

                    {hasOrder && (
                      <TableSortLabel
                        hideSortIcon={!sorter}
                        active={sorter && hasOrder}
                        direction={orderBy === key ? order : 'asc'}
                        onClick={handleRequestSort(key)}
                      >
                        {rest.children || label}
                        {orderBy === key ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, j) => (
              <TRow
                key={row.key || j}
                row={row}
                getRowSelected={getRowSelected}
                setSelectedRows={setSelectedRows}
                onSelectedRowChange={onSelectedRowChange}
                selectedRows={selectedRows}
                columns={columns}
                rowsByKey={rowsByKey}
                RowDetails={RowDetails}
                width={width}
              />
            ))}

            {other.children && (
              <TableRow>
                <TableCell
                  colSpan="100%"
                  sx={{
                    paddingTop: '16px !important',
                    textAlign: 'center !important',
                  }}
                >
                  {other.children}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {rowsPerPageOptions.length > 0 && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count || rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelRowsPerPage={labelRowsPerPage}
          SelectProps={SelectProps}
          showFirstButton={showFirstButton}
          showLastButton={showLastButton}
        />
      )}
    </TableRoot>
  )
}

export default Table
