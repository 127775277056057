import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const SearchIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 21 20">
      <g opacity={0.5} fill="#041F3D">
        <path d="M8.599 17.306c4.75 0 8.599-3.874 8.599-8.653S13.348 0 8.599 0C3.849 0 0 3.874 0 8.653s3.85 8.653 8.599 8.653Z" />
        <path
          opacity={0.4}
          d="M18.675 19.956a1.32 1.32 0 0 1-.889-.385l-2.037-2.38a1.088 1.088 0 0 1-.08-1.522.975.975 0 0 1 1.388 0l2.561 2.049a1.348 1.348 0 0 1-.173 2.031c-.21.151-.458.239-.716.251l-.054-.044Z"
        />
      </g>
    </SvgIcon>
  )
}

export default SearchIcon
