import React, { createContext, useContext } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { supplant, dateLocales } from '../../utils'
import useI18nProvider from './useI18nProvider'

const I18nContext = createContext({
  translations: {},
})

/**
 * Hook for child components to get the i18n object and re-render when it changes.
 */
const useI18n = () => {
  const context = useContext(I18nContext)

  /**
   * Translates the given key id
   * @param key key to translate
   * @param values options
   * @return the translation or the specified key if it doesn't exist
   */
  const t = (key, values) => {
    let message
    const defaultValue = key?.split('.').slice(-1)[0]

    // Check plural form
    if (values?.count && (values?.count === 0 || values?.count > 1)) {
      message =
        context.translation?.[`${key}_plural`] ||
        context.fallbackTranslation?.[`${key}_plural`] ||
        context.translation?.[key] ||
        context.fallbackTranslation?.[key]

      return supplant(message, values) ?? defaultValue
    }

    message = context.translation?.[key] ?? context.fallbackTranslation?.[key]

    return supplant(message, values) ?? defaultValue
  }

  return { ...context, t }
}

const I18nProvider = ({ children, language }) => {
  const i18n = useI18nProvider(language)
  const translation = i18n.translation || i18n.fallbackLanguage

  if (!translation || Object.keys(translation).length === 0) {
    return null
  }

  const locale = dateLocales[i18n.currentLanguage]

  return (
    <I18nContext.Provider value={i18n}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        {children}
      </LocalizationProvider>
    </I18nContext.Provider>
  )
}

export { useI18n }
export default I18nProvider
