const dataSyncsSelector = (state) => state.dataSync.dataSyncs

const allDataSyncsSelector = (state) => state.dataSync.allSyncs

const latestDataSyncsSelector = (state) => state.dataSync.latestDataSyncs

const allSyncsLoadingSelector = (state) => state.dataSync.allSyncsLoading

const dataSyncsUserSelector = (state) => state.dataSync.allSyncs?.user_id

const syncStatusSelector = (state) => state.dataSync.status

const alertDataTypesSelector = (state) => state.dataSync.alertDataTypes

const loadingTypesSelector = (state) => state.dataSync.loadingTypes

const addDataSyncByTypeSelector = (state) => state.dataSync.addDataSyncByType

export {
  dataSyncsSelector,
  latestDataSyncsSelector,
  allDataSyncsSelector,
  allSyncsLoadingSelector,
  dataSyncsUserSelector,
  syncStatusSelector,
  alertDataTypesSelector,
  loadingTypesSelector,
  addDataSyncByTypeSelector,
}
