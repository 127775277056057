import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import corporateTaxService from '../../services/corporateTax'

const initialState = {
  corporateTaxes: null,
}

export const getCorporateTaxes = createAsyncThunk(
  'corporateTax/getCorporateTaxes',
  corporateTaxService.getCorporateTaxes
)

const corporateTax = createSlice({
  name: 'corporateTax',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getCorporateTaxes.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.corporateTaxes = data
    },
  },
})

export default corporateTax.reducer
