/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import webhookService from '../../services/webhook'

const initialState = {
  webhooks: null,
}

export const getWebhook = createAsyncThunk(
  'webhook/getWebhook',
  webhookService.getWebhook
)

export const deleteWebhook = createAsyncThunk(
  'webhook/deleteWebhook',
  webhookService.deleteWebhook
)

export const updateWebhook = createAsyncThunk(
  'webhook/updateWebhook',
  webhookService.updateWebhook
)

export const getWebhooks = createAsyncThunk(
  'webhook/getWebhooks',
  webhookService.getWebhooks
)

const webhook = createSlice({
  name: 'webhook',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getWebhooks.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.webhooks = data
    },
  },
})

export default webhook.reducer
