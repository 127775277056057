import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

import userService from '../../services/user'
import { useI18n } from '../../context'
import { SearchIcon, UserIcon } from '../icons'
import { UserSearchRoot } from './UserSearch.style'
import { useAsync } from '../../hooks'

const UserSearch = ({ value: user = null, onChange, ...other }) => {
  const {
    freeSolo: _,
    filterOptions: __,
    filterSelectedOptions: _f,
    multiple: _m,
    ...fieldProps
  } = other

  const { t } = useI18n()

  const { dispatch, data, loading } = useAsync()

  const users = data?.result || []

  const options = users.map((u) => ({
    ...u,
    name: u.name || `${u.first_name} ${u.last_name}`,
  }))

  const userInput = user?.name ?? user ?? ''

  const [value, setValue] = useState(user ?? '')
  const [inputValue, setInputValue] = useState(userInput ?? '')

  useEffect(() => {
    setValue(user)
    setInputValue(userInput)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleChange = (e, newValue) => {
    setValue(newValue)
    onChange?.(newValue)
  }

  const handleInputChange = (e, newInputValue) => {
    setInputValue(newInputValue)
  }

  const getOptionSelected = (option, val) => {
    return (option.id ?? option) === (val.id ?? option)
  }

  useEffect(() => {
    if (inputValue) {
      dispatch(() => userService.getUsers({ query: inputValue || '' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  return (
    <UserSearchRoot
      size="small"
      fullWidth
      autoHighlight
      {...other}
      loading={loading}
      loadingText={t('common.loading')}
      value={value}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isOptionEqualToValue={getOptionSelected}
      getOptionLabel={(o) => o.name || o}
      options={options}
      disablePortal
      freeSolo
      renderOption={(p, option) => (
        <Stack
          key={option.id}
          direction="row"
          spacing={1}
          alignItems="center"
          className="ellipsis"
          {...p}
        >
          <UserIcon
            fontSize="small"
            color={option.type === 'LEGAL' ? 'inherit' : 'secondary'}
          />
          <Typography className="ellipsis" title={option.name}>
            {option.name}
          </Typography>
        </Stack>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={t('common.Recherche')}
          {...fieldProps}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...fieldProps.inputProps,
            ...params.inputProps,
            autoComplete: 'chrome-off',
          }}
        />
      )}
    />
  )
}

export default UserSearch
