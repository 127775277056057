import { DEFAULT_PAGE_SIZE } from '../../config'
import ApiClient from '../apiClient'
import { getUserId } from '../user'

const client = new ApiClient()

const getReports = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
} = {}) =>
  client.get(`/users/${userId}/reports`, { params: { page, per_page } })

const getReport = ({ userId = getUserId(), reportId }) =>
  client.get(`/users/${userId}/reports/${reportId}`)

const addReport = ({ userId = getUserId(), ...input }) =>
  client.post(`/users/${userId}/reports`, input)

const addTransactionReport = () => addReport({ type: 'BANK_TRANSACTION_CSV' })

const reportService = {
  getReports,
  getReport,
  addReport,
  addTransactionReport,
}

export default reportService
