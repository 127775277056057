/* eslint-disable max-lines */
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { matchSorter } from 'match-sorter'
import { Button, Snackbar } from '../../../components'
import { useI18n, useNav } from '../../../context'
import { useSearch, useThunk } from '../../../hooks'
import { addDataSync } from '../../../redux/dataSync'
import { dataTypeProvidersSelector } from '../../../redux/provider'
import { groupBy } from '../../../utils'
import ConnexionActions from '../../DataConnections/UpdateConnexion/ConnexionActions'
import ConnexionHeader from '../../DataConnections/UpdateConnexion/ConnexionHeader'
import DataTypeSearchResult from './DataTypeSearchResult'
import { UpdateConnexionRoot } from '../../DataConnections/UpdateConnexion/UpdateConnexion.style'
import { useDataConnections } from '../../DataConnections/DataConnectionTable'
import {
  categoryDataTypes,
  categoryProviders,
  providerCategories,
} from '../../../config'

const SyncDataTypes = ({ open, onClose }) => {
  const { t } = useI18n()
  const { dispatch, data, errors, loading } = useThunk()

  const errorType = errors?.[0]?.key

  const { currentNav } = useNav()
  const currentDataTypes = currentNav?.dataTypes || []

  const [values, setValues] = useState({ requested_data_types: [], search: '' })

  const providers = useSelector(dataTypeProvidersSelector)

  const providerList = useMemo(
    () =>
      providers.filter((p) =>
        currentDataTypes?.length ? currentDataTypes.includes(p.dataType) : true
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [providers.length, currentDataTypes.length, open]
  )

  const allDataTypes = [...new Set(providerList.map((p) => p.dataType))]

  const { matchedConnectedDataTypes } = useDataConnections(allDataTypes)

  const search = useSearch({
    q: values.search,
    data: providerList
      .filter((p) => matchedConnectedDataTypes?.includes(p.dataType))
      .map((p) => ({ ...p, i18nDataType: t(`dataType.${p.dataType}`) })),
    keys: [{ threshold: matchSorter.rankings.CONTAINS, key: 'i18nDataType' }],
    sort: (a, b) => (a.dataType < b.dataType ? 1 : -1),
  })
  const dataTypesSelected = [
    ...new Set(search.result?.map((sr) => sr.dataType)),
  ]

  const selectAll =
    dataTypesSelected?.length === values.requested_data_types?.length
  const selectOne = values.requested_data_types?.length > 0

  const categories = [...new Set(providerList.map((c) => c?.category))]
  const category = categories?.length === 1 ? categories[0] : undefined

  const searchResult = groupBy('category', search.result)

  const resultCategories = Object.keys(searchResult)

  if (!values.search?.trim()?.length) {
    resultCategories.sort(
      (a, b) => providerCategories[a].index - providerCategories[b].index
    )
  }

  const categoriesList = [
    ...new Set(categoryProviders?.map((r) => r?.category)),
  ]
  const catResult = categoriesList
    .map((c) => ({
      category: c,
      dataTypes: [
        ...new Set(
          (search.result || [])
            .filter((sr) => categoryDataTypes[sr.dataType]?.category === c)
            .map((sr) => sr.dataType)
        ),
      ],
    }))
    .filter((r) => r.dataTypes.length > 0)
    .sort(
      (a, b) =>
        providerCategories[a.category].index -
        providerCategories[b.category].index
    )

  const handleCheckAllToggle = () => {
    setValues({
      ...values,
      requested_data_types: selectAll ? [] : dataTypesSelected,
    })
  }

  const handleCheck = (e) => {
    const { name } = e.target
    const { requested_data_types } = values

    if (requested_data_types.includes(name)) {
      setValues({
        ...values,
        requested_data_types: requested_data_types.filter((i) => i !== name),
      })
    } else {
      setValues({
        ...values,
        requested_data_types: [...values.requested_data_types, name],
      })
    }
  }

  const handleCheckCategory = (e) => {
    const { name, checked } = e.target
    const dataTypeCategory = catResult.filter((c) => c?.category === name)
    const dataTypes = dataTypeCategory[0]?.dataTypes

    if (checked) {
      const requestedDataTypes = dataTypes.filter(
        (dataType) => !values.requested_data_types.includes(dataType)
      )

      setValues({
        ...values,
        requested_data_types: [
          ...values.requested_data_types,
          ...requestedDataTypes,
        ],
      })
    } else {
      const removedDataTypes = values.requested_data_types.filter(
        (i) => !dataTypes.includes(i)
      )

      setValues({ ...values, requested_data_types: removedDataTypes })
    }
  }

  const handleUpdate = () => {
    if (values.requested_data_types.length > 0) {
      dispatch(() => addDataSync({ data_types: values.requested_data_types }))
      onClose()
    }
  }

  const handleChange = (e) => {
    setValues({ ...values, search: e.target.value })
  }

  useEffect(() => {
    setValues((v) => ({ ...v, requested_data_types: [] }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchedConnectedDataTypes?.length, open])

  return (
    <>
      <Dialog onClose={onClose} open={open} maxWidth="xs">
        <UpdateConnexionRoot>
          <ConnexionHeader
            title={t('common.Sync data')}
            subTitle={category ? t(`common.${category}`) : undefined}
            onClose={onClose}
          />

          <Box display="flex" gap={1} alignItems="center" mb={1}>
            <Typography className="list">
              {t('common.selectSyncData')}
            </Typography>

            <Button
              id="select-datasync"
              variant="text"
              color="inherit"
              className="toggler"
              onClick={handleCheckAllToggle}
            >
              {selectAll ? t('common.Uncheck all') : t('common.Check all')}
            </Button>
          </Box>

          <TextField
            value={values.search}
            onChange={handleChange}
            placeholder={t('common.search')}
            size="small"
            fullWidth
            sx={{ mb: 2 }}
          />
          <DataTypeSearchResult
            data={searchResult}
            onCheck={handleCheck}
            onCheckCategory={handleCheckCategory}
            values={values}
            catResult={catResult}
            resultCategories={resultCategories}
            open={open}
            search={search}
          />

          <ConnexionActions
            disabled={!selectOne}
            onOk={handleUpdate}
            okText={t('common.Sync')}
            onClose={onClose}
          />
        </UpdateConnexionRoot>
      </Dialog>

      <Snackbar open={!loading && data} severity="success">
        {t(`common.SYNCHRONIZATION_SUCCESS`)}
      </Snackbar>

      <Snackbar open={!loading && !!errors} error={!!errors}>
        {errorType && t(`error.${errorType}`)}
      </Snackbar>
    </>
  )
}

export default SyncDataTypes
