import React, { useCallback, useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import { useLocation, useHistory } from 'react-router-dom'

import Appbar from '../Appbar'
import LayoutBase from '../LayoutBase'
import Sidebar from '../Sidebar'
import { fromSS } from '../../utils'
import { useAuth } from '../../context'
import { ContentWrapper } from './PageLayout.style'

const rootUrls = ['/', '/users', '/settings']

const PageLayout = ({ children }) => {
  const { pathname } = useLocation()

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  const { userId } = useAuth()

  const hide =
    (!userId && isDesktop) || (isDesktop && rootUrls.includes(pathname))

  const history = useHistory()

  const [compact, setCompact] = useState(() => {
    const menuState = fromSS('menu', true)

    return menuState?.compact ?? !isDesktop
  })

  const [openSidebar, setOpenSidebar] = useState(false)

  // eslint-disable-next-line no-nested-ternary
  const shouldOpenSidebar = hide ? false : isDesktop ? true : openSidebar

  const compactClass = compact ? 'compact' : ''
  const menuClosedClass = !shouldOpenSidebar ? 'menuClosed' : ''

  const handleCompactChange = useCallback(() => {
    setCompact((v) => !v)
  }, [])

  const handleSidebarOpen = () => {
    if (isDesktop) return history.push('/')

    setOpenSidebar(true)
  }

  const handleSidebarClose = useCallback(() => {
    if (isDesktop) return

    setOpenSidebar(false)
  }, [isDesktop])

  useEffect(() => {
    if (openSidebar) {
      setOpenSidebar(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    if (!isDesktop && compact) setCompact(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop])

  useEffect(() => {
    const menuState = fromSS('menu', true)

    sessionStorage.setItem('menu', JSON.stringify({ ...menuState, compact }))
  }, [compact])

  return (
    <LayoutBase>
      <Appbar onMenuClick={handleSidebarOpen} />

      <ContentWrapper
        className={`${compactClass} ${menuClosedClass}`}
        p={{ xs: 2, md: 3 }}
      >
        <Sidebar
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          variant={isDesktop && !hide ? 'persistent' : 'temporary'}
          compact={compact}
          onCompactChange={isDesktop ? handleCompactChange : handleSidebarClose}
        />
        <Box
          position="relative"
          // height="calc(100% - 70px)"
          display="flex"
          flexDirection="column"
          flexGrow={1}
        >
          {children}
        </Box>
      </ContentWrapper>
    </LayoutBase>
  )
}

export default PageLayout
