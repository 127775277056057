import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import styled from 'styled-components'
import { SIDEMENU_WIDTH } from '../../config'

const MenuContent = styled(Box)`
  display: flex;
  flex-direction: column;
  transition: padding 0.3s ease-out;
  background: white;
  border-radius: 5px;

  > .navigation {
    flex-grow: 1;

    .DarkNav {
      padding-bottom: 8px;
    }
  }

  > .footerBox {
    padding: 20px 18px;
    padding-top: 10px;
  }
`

const SidebarRoot = styled(Drawer)`
  /*
  &.temporary {
    .SidebarUser {
      margin-top: 20px;
    }
  }
  */

  .MuiDrawer-paper {
    overflow: hidden;
  }

  .Header {
    margin-bottom: 8px;
    padding-left: 16px;
    transition: all 0.3s ease-out;
    color: white;

    > svg {
      cursor: pointer;
    }
  }

  &.compact {
    .MuiPaper-root {
      width: ${SIDEMENU_WIDTH.COMPACT}px;
    }

    .Header {
      padding-left: 20px;
    }

    .footerBox {
      padding-left: 20px;
    }

    .MenuContent {
      padding-left: 0;
      padding-right: 0;
    }

    .SidebarUser {
      display: none;
    }
  }

  .MuiPaper-root {
    transition: width 0.3s ease-out;
    width: ${SIDEMENU_WIDTH.DEFAULT}px;
    color: rgb(255, 255, 255);
    background-size: cover;
    border-right: none;
    /* box-shadow: none; */
  }
`

export { SidebarRoot, MenuContent }
