import React, { useEffect, useState } from 'react'

import { useI18n } from '../../../context'
import { Alert } from '../../../components'
import { AlertCollapse } from './ErrorAlert.style'

export default function ErrorAlert() {
  const { t } = useI18n()

  const error = sessionStorage.getItem('error')
  const [open, setOpen] = useState(error)

  const handleClose = () => {
    setOpen(false)
    sessionStorage.removeItem('error')
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleClose)
    setTimeout(handleClose, 6000)

    return () => window.removeEventListener('beforeunload', handleClose)
  }, [error])

  return (
    <AlertCollapse in={error}>
      <Alert open={open} error closable onClosed={handleClose}>
        {t('common.error.500')}
      </Alert>
    </AlertCollapse>
  )
}
