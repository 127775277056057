import ApiClient from '../apiClient'
import { getUserId } from '../user'
import { DEFAULT_PAGE_SIZE } from '../../config'

const client = new ApiClient()

const getPayments = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/bdf/payments`, {
    params: { page, per_page },
  })
}

const getFinances = ({ userId = getUserId() }) => {
  return client.get(`/users/${userId}/bdf/finances`)
}

const getRating = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/bdf/rating`, {
    params: { page, per_page },
  })
}

const banqueDeFranceService = {
  getPayments,
  getFinances,
  getRating,
}

export default banqueDeFranceService
