import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const EditUserIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M13 8c0-2.21-1.79-4-4-4S5 5.79 5 8s1.79 4 4 4 4-1.79 4-4z" />
      <path d="M1 18v2h16v-2c0-2.66-5.33-4-8-4s-8 1.34-8 4z" />
    </SvgIcon>
  )
}

export default EditUserIcon
