/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import dataConnectionService from '../../services/dataConnection'
import { getUserId } from '../../services/user'
import { mapBy } from '../../utils'

const initialState = {
  dataConnections: null,
  allDataConnections: null,
}

export const getDataConnections = createAsyncThunk(
  'dataConnection/getDataConnections',
  dataConnectionService.getDataConnections
)

export const getAllDataConnections = createAsyncThunk(
  'dataConnection/getAllDataConnections',
  dataConnectionService.getDataConnections
)

export const addDataConnection = createAsyncThunk(
  'dataConnection/addDataConnection',
  dataConnectionService.addDataConnection
)

export const updateDataConnection = createAsyncThunk(
  'dataConnection/updateDataConnection',
  dataConnectionService.updateDataConnection
)

export const deleteDataConnection = createAsyncThunk(
  'dataConnection/deleteDataConnection',
  dataConnectionService.deleteDataConnection
)

const dataConnection = createSlice({
  name: 'dataConnection',
  initialState,
  reducers: {
    resetConnections: () => initialState,
  },
  extraReducers: {
    [getDataConnections.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        data.user_id = getUserId()

        state.dataConnections = data
        const connectionById = mapBy('id', data.result)
        const allConnectionById = mapBy('id', state.allDataConnections?.result)

        if (state.allDataConnections.result) {
          const res = data.result.filter((c) => !allConnectionById[c.id])

          state.allDataConnections.result.forEach((connection) => {
            const newCounnection = connectionById[connection.id]

            if (newCounnection) {
              Object.keys(newCounnection).forEach((k) => {
                connection[k] = newCounnection[k]
              })
            }
          })

          state.allDataConnections.result = [
            ...res,
            ...state.allDataConnections.result,
          ]
        } else state.allDataConnections = data
      } else state.dataConnections = null
    },
    [getAllDataConnections.fulfilled]: (state, action) => {
      const { data, status } = action.payload

      state.status = status

      if (data) {
        data.user_id = getUserId()
        state.allDataConnections = data
      } else state.allDataConnections = null
    },
    [addDataConnection.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        if (state.dataConnections?.result) {
          state.dataConnections.result = [data, ...state.dataConnections.result]
        }

        if (state.allDataConnections?.result) {
          state.allDataConnections.result = [
            data,
            ...state.allDataConnections.result,
          ]
        }
      }
    },
    [updateDataConnection.fulfilled]: (state, action) => {
      const { dataConnectionId: id } = action.meta.arg
      const { data } = action.payload

      if (data) {
        if (state.dataConnections?.result) {
          state.dataConnections.result.forEach((u) => {
            if (u.id === id) {
              Object.keys(data).forEach((k) => {
                u[k] = data[k]
              })
            }
          })
        }

        if (state.dataConnections?.result) {
          state.allDataConnections.result.forEach((u) => {
            if (u.id === id) {
              Object.keys(data).forEach((k) => {
                u[k] = data[k]
              })
            }
          })
        }
      }
    },
    [deleteDataConnection.fulfilled]: (state, action) => {
      const { dataConnectionId: id } = action.meta.arg
      const { data } = action.payload

      if (data === null) {
        if (state.dataConnections?.result) {
          state.dataConnections.result = state.dataConnections.result.filter(
            (u) => u.id !== id
          )
        }

        if (state.dataConnections?.result) {
          state.allDataConnections.result =
            state.allDataConnections.result.filter((u) => u.id !== id)
        }
      }
    },
  },
})

export default dataConnection.reducer

export const { resetConnections } = dataConnection.actions
