import React, { useEffect, useState, forwardRef } from 'react'

import { CircularProgress, LinearProgress } from './ProgressIndicator.style'

/**
 * Indicates the progress of an operation
 */
const ProgressIndicator = forwardRef((props, ref) => {
  const {
    circular,
    position = circular ? 'center' : 'top',
    timeout = 0,
    anchor = 'parent',
    ...other
  } = props

  const [showLoading, setShowLoading] = useState(false)
  const progressProps = { ...other, position, anchor, ref }

  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), timeout)

    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {circular && showLoading && <CircularProgress {...progressProps} />}
      {showLoading && !circular && <LinearProgress {...progressProps} />}
    </>
  )
})

export default ProgressIndicator
