import React from 'react'

import {
  Cancel,
  Check,
  RotateLeft,
  RunningWithErrors,
} from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import { Label } from '../../../components'
import { format } from '../../../utils'
import ConnectionTableActions from './ConnectionTableActions'

const colors = {
  CONNECTING: 'INFO',
  AUTH_REQUIRED: 'WARNING',
  AUTH_ERROR: 'DANGER',
  CONNECTED: 'SUCCESS',
  DISCONNECTED: 'DEFAULT',
}

const icons = {
  CONNECTING: <RotateLeft />,
  AUTH_REQUIRED: <RunningWithErrors />,
  AUTH_ERROR: <Cancel />,
  CONNECTED: <Check />,
  DISCONNECTED: <Cancel />,
}

const buildColumns = (t) => {
  const tableColumn = [
    {
      label: 'Name',
      key: 'provider_name',
      width: '25%',
      render: ({ provider_name }) => t(`common.providers.${provider_name}`),
    },
    {
      label: 'Status',
      key: 'status',
      render: ({ status }) => (
        <Label color={colors[status]}>
          {' '}
          <Tooltip title={status}>{icons[status]}</Tooltip>
        </Label>
      ),
    },
    {
      label: 'Created',
      key: 'created_at',
      render: ({ created_at }) => format(created_at, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      label: 'Connected',
      key: 'connected_at',
      render: ({ connected_at }) => format(connected_at, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      label: 'Disconnected',
      key: 'disconnected_at',
      render: ({ disconnected_at }) =>
        format(disconnected_at, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      label: 'Actions',
      key: 'delete',
      align: 'center',
      render: (data) => <ConnectionTableActions connection={data} />,
    },
  ].map((c) => ({ ...c, label: t(`common.${c.label}`) }))

  return tableColumn
}

export { buildColumns }
