import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ConnectionSuccessIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 65 65" fill="none">
      <circle cx={32.5} cy={32.5} r={32.5} fill="#181C32" />
      <path
        d="M26 48.708a4.238 4.238 0 0 1-3-1.243l-9.269-9.263 3.537-3.535L26 43.398 48.73 20.667l3.537 3.535L29 47.465a4.238 4.238 0 0 1-3 1.243Z"
        fill="#fff"
      />
    </SvgIcon>
  )
}

export default ConnectionSuccessIcon
