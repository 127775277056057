import React, { Fragment, useEffect, useState } from 'react'
import Box from '@mui/material/Box'

import NavBreadcrumb from '../NavBreadcrumb'
import SyncAlerts from '../SyncAlerts'
import AddUserFab from '../../pages/Users/AddUserFab'
import { useNav } from '../../context'
import ProgressIndicator from '../ProgressIndicator'

const loaded = {}

const PageContent = ({ children, paths, loading, ...other }) => {
  const [fakeLoading, setFakeLoading] = useState(loading)
  const { currentNav } = useNav()
  const primary = currentNav?.requires?.includes('DASHBOARD') || !currentNav

  const internalLoading =
    typeof window !== 'undefined' && loaded[window.location.href]
      ? false
      : loading || fakeLoading

  useEffect(() => {
    if (!loading) setFakeLoading(true)

    const timeoutId = setTimeout(() => {
      setFakeLoading(false)
      loaded[window.location.href] = true
    }, 1000)

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [loading])

  return (
    <Box
      position="relative"
      flexGrow={1}
      {...other}
      maxWidth={primary ? undefined : { xs: '100%', lg: 'calc(100vw - 348px)' }}
      minHeight={internalLoading ? 'calc(100vh - 160px)' : undefined}
    >
      <NavBreadcrumb mb={2} paths={paths} />

      {!internalLoading && <SyncAlerts />}

      {internalLoading && (
        <ProgressIndicator
          circular
          anchor="window"
          sx={
            primary
              ? undefined
              : { left: { xs: 0, lg: 'calc(50vw + 120px) !important' } }
          }
        />
      )}

      <Box
        {...(internalLoading && { sx: { opacity: internalLoading ? 0 : 1 } })}
        component={internalLoading ? undefined : Fragment}
      >
        {children}
      </Box>

      <AddUserFab />
    </Box>
  )
}

export default PageContent
