import { DEFAULT_PAGE_SIZE } from '../../config'
import ApiClient from '../apiClient'
import { getUserId } from '../user'

const client = new ApiClient()

const getCommerceSubscriptions = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/commerce-subscriptions`, {
    params: { page, per_page },
  })
}

const getCommerceTransactions = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
  type,
}) => {
  return client.get(`/users/${userId}/commerce-transactions`, {
    params: { page, per_page, ...(type && { type }) },
  })
}

const getCommerceTransactionInsights = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/commerce-transaction-insight`, {
    params: { page, per_page },
  })
}

const getCommerceBalances = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/commerce-balances`, {
    params: { page, per_page },
  })
}

const getCommerceDisputes = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/commerce-disputes`, {
    params: { page, per_page },
  })
}

const getCommerceSubscriptionsInsights = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/commerce-subscription-insight`, {
    params: { page, per_page },
  })
}

const getCommerceDisputesInsights = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/commerce-dispute-insight`, {
    params: { page, per_page },
  })
}

const getCommerceCustomers = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/commerce-customers`, {
    params: { page, per_page },
  })
}

const getCommerceProducts = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/commerce-products`, {
    params: { page, per_page },
  })
}

const getCommerceOrders = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/commerce-orders`, {
    params: { page, per_page },
  })
}

const commerceService = {
  getCommerceBalances,
  getCommerceSubscriptions,
  getCommerceTransactions,
  getCommerceDisputes,
  getCommerceTransactionInsights,
  getCommerceSubscriptionsInsights,
  getCommerceDisputesInsights,
  getCommerceCustomers,
  getCommerceProducts,
  getCommerceOrders,
}

export default commerceService
