import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const GridViewIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 30 30">
      <rect width={13.235} height={13.235} rx={2} fill="#848484" />
      <rect y={16.766} width={13.235} height={13.235} rx={2} fill="#5B5B5B" />
      <rect x={16.766} width={13.235} height={13.235} rx={2} fill="#5B5B5B" />
      <rect
        x={16.766}
        y={16.766}
        width={13.235}
        height={13.235}
        rx={2}
        fill="#5B5B5B"
      />
    </SvgIcon>
  )
}

export default GridViewIcon
