import Box from '@mui/material/Box'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useI18n } from '../../context'
import { getUserId } from '../../services/user'
import DataSyncAlert from './DataSyncAlert'
import DataType, { syncProviders } from './DataType'

const SyncErrorAlert = ({ onClose, closedAlert, errorSyncs }) => {
  const history = useHistory()
  const { t } = useI18n()

  const id = `SyncError_${syncProviders(errorSyncs)}_${getUserId()}`

  const closed = !!closedAlert?.[id]
  const open = !!errorSyncs?.length && !closed

  const handleClose = () => {
    onClose?.(id)
  }

  const handleGoToSyncPage = () => {
    history.push(`/user/${getUserId()}/data-sync`)
  }

  return (
    <DataSyncAlert
      title={t('common.alert.syncError.title')}
      status={errorSyncs?.[0]?.status}
      onClose={handleClose}
      open={open}
    >
      {t('common.alert.syncError')}

      <DataType syncs={errorSyncs} />

      <Box
        className="htmlContent"
        dangerouslySetInnerHTML={{ __html: t('common.alert.syncError2') }}
        onClick={handleGoToSyncPage}
      />
    </DataSyncAlert>
  )
}

export default SyncErrorAlert
