import React from 'react'
import ArrowBackIcon from '@mui/icons-material/KeyboardArrowLeft'
import ArrowForwardIcon from '@mui/icons-material/KeyboardArrowRight'
import PaginationItem from '@mui/material/PaginationItem'
import { useMediaQuery } from '@mui/material'

import { ListPaginationRoot } from './ListPagination.style'
import { useI18n } from '../../context'

const Previous = () => {
  const { t } = useI18n()

  return (
    <>
      <ArrowBackIcon sx={{ marginRight: '8px' }} />
      {t('common.Prev')}
    </>
  )
}

const Next = () => {
  const { t } = useI18n()

  return (
    <>
      {t('common.Next')}
      <ArrowForwardIcon sx={{ marginLeft: '8px' }} />
    </>
  )
}

const ListPagination = ({ shape = 'rounded', ...other }) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <ListPaginationRoot
      shape={shape}
      size={isDesktop ? undefined : 'small'}
      {...other}
      renderItem={(item) => (
        <PaginationItem
          components={{ previous: Previous, next: Next }}
          {...item}
        />
      )}
    />
  )
}

export default ListPagination
