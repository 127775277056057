import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ArrowDownIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 8 5">
      <path
        d="M5.06009 3.69999L7.35509 1.40499C7.44822 1.31131 7.50049 1.18459 7.50049 1.05249C7.50049 0.9204 7.44822 0.793674 7.35509 0.699993C7.30861 0.653128 7.25331 0.615931 7.19238 0.590547C7.13145 0.565163 7.0661 0.552093 7.00009 0.552093C6.93409 0.552093 6.86873 0.565163 6.8078 0.590547C6.74687 0.615931 6.69157 0.653128 6.64509 0.699993L4.35509 2.99999C4.30861 3.04686 4.25331 3.08405 4.19238 3.10944C4.13145 3.13482 4.0661 3.14789 4.00009 3.14789C3.93409 3.14789 3.86873 3.13482 3.8078 3.10944C3.74687 3.08405 3.69157 3.04686 3.64509 2.99999L1.35509 0.699992C1.2616 0.605841 1.13454 0.552683 1.00186 0.552214C0.869179 0.551745 0.741744 0.604004 0.647592 0.697493C0.553441 0.790982 0.500283 0.918043 0.499814 1.05072C0.499345 1.18341 0.551604 1.31084 0.645093 1.40499L2.94009 3.69999C3.22134 3.98089 3.60259 4.13867 4.00009 4.13867C4.39759 4.13867 4.77884 3.98089 5.06009 3.69999V3.69999Z"
        fill="black"
      />
    </SvgIcon>
  )
}

export default ArrowDownIcon
