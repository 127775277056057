import Box from '@mui/material/Box'
import styled from 'styled-components'

export const CategoryRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 110px;

  > p {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    cursor: pointer;
    transition: all 0.3s;

    &:first-child {
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 4px;
      cursor: default;
      pointer-events: none;
    }

    &:hover,
    &.active {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`
