import React from 'react'
import { Link } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'

import { getUserId } from '../../../services/user'
import { ConnectionCountRoot } from './ProviderItem.style'

const ConnectionCount = ({ title, data, icon, ...other }) => {
  if (!data?.length) return null

  return (
    <Tooltip title={title} arrow>
      <ConnectionCountRoot
        {...other}
        component={Link}
        to={`/user/${getUserId()}/data-connections`}
      >
        {data.length}
        {icon}
      </ConnectionCountRoot>
    </Tooltip>
  )
}

export default ConnectionCount
