import React, { useEffect, useRef, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import Dialog from '@mui/material/Dialog'
import Zoom from '@mui/material/Zoom'

import ProviderSearch from '../ProviderSearch'
import { addDataConnection } from '../../../redux/dataConnection'
import { useThunk } from '../../../hooks'
import ConnectionForm from '../ConnectionForm'
import ConnectionResult from './ConnectionResult'
import { Snackbar } from '../../../components'
import { getAllDataSyncs } from '../../../redux/dataSync'
import { useAuth } from '../../../context'
import UniqueLink from '../../Users/UniqueLink/UniqueLink'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />
})

const AddConnection = ({ open, onClose }) => {
  const { currentUser } = useAuth()
  const [provider, setProvider] = useState()
  const [index, setIndex] = useState(0)
  const { dispatch, loading, errorMsg, errors, data, ...tk } = useThunk()

  const swiperRef = useRef()

  const handleChangeIndex = (newIndex) => {
    setIndex(newIndex)
  }

  const handleIndexChange = (val) => () => {
    setIndex(val)
  }

  const swiperAction = (actions) => {
    swiperRef.current = actions
  }

  const handleCreate = (input) => {
    dispatch(() => addDataConnection(input))
    tk.run(getAllDataSyncs({ page: 1, per_page: 1000 }))
  }

  const updateHeight = () => {
    setTimeout(() => swiperRef.current?.updateHeight())
  }

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setIndex(0)
        setProvider(null)
      }, 150)
    }
  }, [open])

  useEffect(() => {
    if (data) setIndex(2)
  }, [data])

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ maxWidth: '800px', margin: 'auto' }}
      TransitionComponent={Transition}
      keepMounted
      transitionDuration={{ enter: 400, exit: 200 }}
    >
      <SwipeableViews
        index={index}
        onChangeIndex={handleChangeIndex}
        animateHeight
        animateTransitions={false}
        ref={swiperRef}
        action={swiperAction}
      >
        <ProviderSearch
          onClose={onClose}
          toProvider={handleIndexChange(1)}
          toUniqueLink={handleIndexChange(3)}
          provider={provider}
          setProvider={setProvider}
          updateHeight={updateHeight}
          open={open}
        />
        <ConnectionForm
          onBack={handleIndexChange(0)}
          onClose={onClose}
          provider={provider}
          loading={loading}
          errors={errors}
          onAdd={handleCreate}
          updateHeight={updateHeight}
        />
        <ConnectionResult
          onBack={handleIndexChange(0)}
          onClose={onClose}
          provider={provider}
          error={!!errors}
          connection={data}
          updateHeight={updateHeight}
        />
        <UniqueLink
          onBack={handleIndexChange(0)}
          user={currentUser}
          open={false}
          onClose={onClose}
          updateHeight={updateHeight}
        />
      </SwipeableViews>

      <Snackbar open={!loading && !!errors} error={!!errors}>
        {errorMsg}
      </Snackbar>
    </Dialog>
  )
}

export default React.memo(AddConnection)
