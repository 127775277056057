import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const CheckIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 15 15">
      <rect width={15} height={15} rx={3} fill="#3DFFC0" />
      <path
        d="M5.925 10.699a1.121 1.121 0 0 1-.795-.33L3.032 8.274l.53-.53L5.66 9.84a.375.375 0 0 0 .53 0l5.247-5.248.53.53L6.72 10.37a1.12 1.12 0 0 1-.795.329Z"
        fill="#000"
      />
    </SvgIcon>
  )
}

export default CheckIcon
