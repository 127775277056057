/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import franceBankService from '../../services/franceBank'

const initialState = {
  bgCheck: {},
  bgCheckV2: {},
}

export const getBackgroundCheck = createAsyncThunk(
  'franceBank/getBackgroundCheck',
  franceBankService.getBackgroundCheck
)

export const getBackgroundCheckV2 = createAsyncThunk(
  'franceBank/getBackgroundCheckV2',
  franceBankService.getBackgroundCheckV2
)

const franceBank = createSlice({
  name: 'franceBank',
  initialState,
  reducers: {},
  extraReducers: {
    [getBackgroundCheck.fulfilled]: (state, action) => {
      const { errors, data } = action.payload

      state.bgCheck.backgroundCheck = data
      state.bgCheck.lastcheck = new Date().toISOString()
      state.bgCheck.status = 'default'

      if (data?.acceptable === 'OK') state.bgCheck.status = 'noAntecedant'

      if (data?.acceptable === 'NOK') state.bgCheck.status = 'antecedant'

      if (errors) state.bgCheck.status = 'error'
    },
    [getBackgroundCheckV2.fulfilled]: (state, action) => {
      const { errors, data } = action.payload

      state.bgCheckV2.backgroundCheck = data
      state.bgCheckV2.lastcheck = new Date().toISOString()
      state.bgCheckV2.status = 'default'

      if (data?.acceptable === 'OK') state.bgCheckV2.status = 'noAntecedant'

      if (data?.acceptable === 'NOK') state.bgCheckV2.status = 'antecedant'

      if (errors) state.bgCheckV2.status = 'error'
    },
  },
})

export default franceBank.reducer
