import { DEFAULT_PAGE_SIZE } from '../../config'
import ApiClient from '../apiClient'
import { getUserId } from '../user'

const client = new ApiClient()

const getVatDeclarations = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/vat-declarations`, {
    params: { page, per_page },
  })
}

const getVatDeclaration = ({ userId = getUserId(), vatDeclarationId }) => {
  return client.get(`/users/${userId}/vat-declarations/${vatDeclarationId}`)
}

const vatDeclarationService = {
  getVatDeclarations,
  getVatDeclaration,
}

export default vatDeclarationService
