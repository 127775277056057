import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import { CircularProgress } from '@mui/material'
import Company from './Company'

import { useI18n } from '../../context'
import { AutocompleteRoot } from './Autocomplete.style'
import companyService from '../../services/company'
import Scrollbar from '../Scrollbar'

const AutocompleteCompanies = ({ fromSearch }) => {
  const { t } = useI18n()
  const [value, setValue] = useState('')

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isCancelled = false

    const fetchSuggestions = async () => {
      if (value.length >= 2) {
        setLoading(true)

        const response = await companyService.getCompaniesAutocomplete({
          query: value,
        })

        if (!isCancelled) {
          setCompanies(response?.result ?? [])
          setLoading(false)
        }
      } else {
        setCompanies([])
      }
    }

    const timer = setTimeout(fetchSuggestions, 150)

    return () => {
      isCancelled = true
      clearTimeout(timer)
      setLoading(false)
    }
  }, [value])

  return (
    <AutocompleteRoot>
      <TextField
        value={value}
        type="search"
        variant="filled"
        onChange={handleChange}
        label={t('common.search_company')}
        size="medium"
        fullWidth
      />

      <Box sx={{ width: 'lg', height: 'lg' }}>
        <Box display="flex" flexGrow={1}>
          <Box height="100%" sx={{ borderLeft: '1px solid #e2e2e2;' }} />
          <Scrollbar
            suppressScrollX
            visiblility="always"
            background="transparent"
            size="medium"
            variant="square"
            offset={false}
            sx={{ flexGrow: 1, height: '650px' }}
          >
            {loading && (
              <Card
                variant="rounded outlined"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                <CircularProgress size={75} />
              </Card>
            )}
            {!loading &&
              !!companies.length &&
              companies.map((company) => (
                <Company
                  key={company.registration_number}
                  company={company}
                  fromSearch={fromSearch}
                />
              ))}
            {!loading && !companies?.length && (
              <Card
                variant="rounded outlined"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                <Typography alignContent="center" variant="h6">
                  {t('common.no_data')}
                </Typography>
              </Card>
            )}
          </Scrollbar>
        </Box>
      </Box>
    </AutocompleteRoot>
  )
}

export default AutocompleteCompanies
