import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import taxAccountService from '../../services/taxAccount'

const initialState = {
  taxAccounts: null,
}

export const getTaxAccounts = createAsyncThunk(
  'taxAccount/getTaxAccounts',
  taxAccountService.getTaxAccounts
)

const taxAccount = createSlice({
  name: 'taxAccount',
  initialState,
  extraReducers: {
    [getTaxAccounts.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.taxAccounts = data
    },
  },
})

export default taxAccount.reducer
