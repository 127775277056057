import { DEFAULT_PAGE_SIZE } from '../../config'
import ApiClient from '../apiClient'
import { getUserId } from '../user'

const client = new ApiClient()

const getTaxReturnAnalysis = ({ userId = getUserId() }) => {
  return client.get(`/users/${userId}/tax-return-analysis`)
}

const getTaxReturns = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/tax-returns`, {
    params: { page, per_page },
  })
}

const getSectorAnalysis = ({ userId = getUserId() }) => {
  return client.get(`/users/${userId}/sector-analysis`)
}

const getSectorAnalysisById = ({ sectorAnalysisId, userId = getUserId() }) => {
  return client.get(`/users/${userId}/sector-analysis/${sectorAnalysisId}`, {})
}

const getTaxReturn = ({ userId = getUserId(), taxReturnId }) => {
  return client.get(`/users/${userId}/tax-returns/${taxReturnId}`)
}

const getTaxClearanceCertificate = ({ userId = getUserId() }) => {
  return client.get(`/users/${userId}/tax-clearance-certificate`)
}

const taxReturnService = {
  getTaxReturns,
  getTaxReturn,
  getTaxReturnAnalysis,
  getSectorAnalysis,
  getSectorAnalysisById,
  getTaxClearanceCertificate,
}

export default taxReturnService
