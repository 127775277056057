import React, { useEffect, useState } from 'react'

import MuiAlert from '@mui/lab/Alert'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import MuiSnackbar from '@mui/material/Snackbar'

const Snackbar = ({
  autoHideDuration = 6000,
  open = false,
  error,
  action,
  ...other
}) => {
  const [show, setShow] = useState(open)

  const handleClose = (_e, reason) => {
    if (reason === 'clickaway') return

    setShow(false)
  }

  useEffect(() => {
    setShow(open)
  }, [open])

  const alertAction = (
    <>
      {action}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  )

  return (
    <MuiSnackbar
      {...(!error && { ...other, message: other.children })}
      open={show}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      onClick={handleClose}
      action={alertAction}
    >
      {error || other.severity ? (
        <MuiAlert
          {...other}
          severity={error ? 'error' : other.severity}
          elevation={6}
          onClose={handleClose}
          variant="filled"
          onClick={handleClose}
          action={alertAction}
        />
      ) : undefined}
    </MuiSnackbar>
  )
}

export default Snackbar
