import React, { Fragment } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import PageLayout from './components/PageLayout'
import { isProd } from './config'
import {
  AuthProvider,
  I18nProvider,
  NavProvider,
  Routes,
  ThemeProvider,
} from './context'
import ErrorBoundary from './ErrorBoundary'
import store from './redux/store'
import ErrorAlert from './pages/error/ErrorAlert'

const App = () => {
  const Boundary = isProd ? ErrorBoundary : Fragment

  return (
    <Provider store={store}>
      <ThemeProvider>
        <Router
          getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
        >
          <Boundary>
            <I18nProvider>
              <AuthProvider>
                <NavProvider>
                  <SnackbarProvider maxSnack={10}>
                    <PageLayout>
                      <ErrorAlert />
                      <Routes />
                    </PageLayout>
                  </SnackbarProvider>
                </NavProvider>
              </AuthProvider>
            </I18nProvider>
          </Boundary>
        </Router>
      </ThemeProvider>
    </Provider>
  )
}

export default App
