import React from 'react'

import Box from '@mui/material/Box'
import { OpenInNew } from '@mui/icons-material'
import { useI18n, useNav } from '../../../context'
import DataSyncAlert from '../../../components/SyncAlerts/DataSyncAlert'
import { Button, CopyToClipboard } from '../../../components'
import { useDataConnections } from '../../DataConnections/DataConnectionTable'
import { isDev } from '../../../config'

const UniqueLinkAlert = ({ onClose, closedAlert, user }) => {
  const { t } = useI18n()
  const { currentNav } = useNav()
  const dataTypes = currentNav?.dataTypes
  const { connectedData } = useDataConnections(dataTypes)
  const categories = [
    t(`common.Financial`),
    t(`common.Banking`),
    t(`common.Commerce`),
  ]

  const nbConnected =
    currentNav?.parent?.name === t(`common.Financial`)
      ? connectedData?.filter((c) => c.provider_name === 'impots_gouv')?.length
      : connectedData?.length

  const closed = !!closedAlert?.[user.id]
  const open =
    categories.includes(currentNav?.parent?.name) &&
    nbConnected === 0 &&
    !closed
  const url = user.redirect_url

  const handleClose = () => {
    onClose?.(user.id)
  }

  return (
    <DataSyncAlert
      title={t('common.user_unique_link')}
      onClose={handleClose}
      open={open}
      status="UNIQUE_LINK"
    >
      <Box
        gap="14px"
        display="flex"
        alignItems="center"
        className="Copy"
        width="100%"
      >
        {url && (
          <>
            <Box className="ellipsis unique-link-alert">
              <span className={isDev ? 'ellipsis text-url' : 'ellipsis'}>
                {url}
              </span>
              {isDev && (
                <Button
                  variant="contained"
                  size="medium"
                  target="_blank"
                  href={url}
                >
                  <OpenInNew />
                </Button>
              )}
            </Box>
            <CopyToClipboard
              tooltip={t('common.linkCopied')}
              value={url}
              timeout={2000}
            >
              <Button
                variant="outlined"
                className="unique-link-button"
                size="large"
                loading={false}
              >
                {t('common.copy_link')}
              </Button>
            </CopyToClipboard>
          </>
        )}
      </Box>
    </DataSyncAlert>
  )
}

export default UniqueLinkAlert
