/* eslint-disable max-lines */
import Typography from '@mui/material/Typography'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import React, { useEffect, useState } from 'react'

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useSelector } from 'react-redux'
import { Button } from '../../../components'
import { useI18n } from '../../../context'
import { validateLegalUser } from './AddUser.validation'
import UserFormHeader from './UserFormHeader'
import { UserFormRoot } from './AddUser.style'
import { groupsSelector } from '../../../redux'

const LegalUserForm = ({
  onSubmit,
  onClose,
  open,
  user,
  onBack,
  onUpdate,
  loading,
  serverErrors,
}) => {
  const defaultVal = { siren: '', name: '', group: '' }
  const [values, setValues] = useState(defaultVal)
  const [errors, setErrors] = useState(defaultVal)
  const { t } = useI18n()
  const groups = useSelector(groupsSelector)
  const filter = createFilterOptions()

  const listGroups = groups?.map((item) => ({ title: item, key: item })) ?? []
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
    setErrors({ ...errors, [e.target.name]: null })
  }

  const handleChangeGroup = (event, newValue) => {
    if (typeof newValue === 'string') {
      setValues({
        ...values,
        group: newValue,
      })
    } else if (newValue && newValue.inputValue) {
      setValues({
        ...values,
        group: newValue.inputValue,
      })
    } else {
      setValues({ ...values, group: newValue.title })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const err = validateLegalUser(values, t)

    if (Object.keys(err).length) {
      setErrors({ ...errors, ...err })

      return onUpdate?.()
    }

    onSubmit(values)
  }

  useEffect(() => {
    if (!open) setValues(defaultVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (user) {
      const filteredUser = Object.keys(user).reduce((acc, key) => {
        if (user[key] !== null) {
          acc[key] = user[key]
        }

        return acc
      }, {})

      setValues({ ...defaultVal, ...filteredUser })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    setErrors({ ...serverErrors?.error_details })
    onUpdate?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverErrors?.error_details, open])

  return (
    <UserFormRoot onSubmit={handleSubmit}>
      <UserFormHeader onBack={onBack} onClose={onClose} />
      <Typography variant="button" className="subTitle" mb={1}>
        {t('common.Create legal user')}
      </Typography>

      <div>
        <FormLabel>{t('common.Siren')}</FormLabel>
        <TextField
          fullWidth
          id="siren"
          margin="dense"
          name="siren"
          value={values.siren}
          onChange={handleChange}
          error={!!errors.siren}
          helperText={errors.siren}
          size="small"
          autoComplete="off"
        />
      </div>

      <div>
        <FormLabel>{t('common.name')}</FormLabel>
        <TextField
          fullWidth
          id="name"
          margin="dense"
          name="name"
          value={values.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
          size="small"
          autoComplete="off"
        />
      </div>

      <div>
        <FormLabel>{t('common.Group')}</FormLabel>
        <Autocomplete
          id="group"
          value={values.group}
          error={!!errors.group}
          helperText={errors.group}
          size="small"
          onChange={handleChangeGroup}
          filterOptions={(options, params) => {
            const filtered = filter(options, params)

            const { inputValue } = params
            const isExisting = options.some(
              (option) => inputValue === option.key
            )

            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                title: `${t('common.Add')} "${inputValue}"`,
              })
            }

            return filtered
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={listGroups}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option
            }
            if (option.inputValue) {
              return option.inputValue
            }

            return option.title
          }}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} placeholder={t('common.select_or_edit')} />
          )}
        />
      </div>

      <Button
        className="SubmitButton"
        variant="contained"
        type="submit"
        loading={loading}
        fullWidth
        sx={{ marginTop: 1 }}
      >
        {t('common.Add')}
      </Button>
    </UserFormRoot>
  )
}

export default LegalUserForm
