import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const MakerIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <g clipPath="url(#clip0_1036_98)">
        <path
          d="M14.3036 2.202C13.6092 1.49511 12.7817 0.932813 11.8687 0.54761C10.9558 0.162408 9.97553 -0.0380666 8.98465 -0.0422311C7.99377 -0.0463956 7.01188 0.145833 6.09573 0.523348C5.17957 0.900863 4.3473 1.45618 3.64701 2.15722C2.94672 2.85825 2.39229 3.69111 2.01574 4.60766C1.6392 5.52422 1.44801 6.5063 1.45323 7.49718C1.45844 8.48805 1.65996 9.46807 2.04613 10.3806C2.4323 11.2932 2.99547 12.1201 3.7031 12.8138L9.00035 17.9955L14.3036 12.8078C15.7099 11.4013 16.4999 9.49379 16.4999 7.50488C16.4999 5.51596 15.7099 3.60849 14.3036 2.202ZM12.7504 10.5H5.25035V7.05675C5.25122 6.76864 5.31813 6.48457 5.44594 6.22635C5.57374 5.96814 5.75904 5.74266 5.9876 5.56725L7.8626 4.13475C8.18984 3.88591 8.58962 3.75117 9.00073 3.75117C9.41184 3.75117 9.81161 3.88591 10.1389 4.13475L12.0139 5.56725C12.2422 5.74282 12.4273 5.96834 12.555 6.22653C12.6826 6.48473 12.7495 6.76872 12.7504 7.05675V10.5ZM11.1004 6.75975C11.1469 6.79468 11.1847 6.83998 11.2108 6.89205C11.2368 6.94412 11.2504 7.00154 11.2504 7.05975V9H9.75035V7.5H8.25035V9H6.75035V7.05675C6.75035 6.99854 6.76391 6.94112 6.78994 6.88905C6.81598 6.83698 6.85378 6.79168 6.90035 6.75675L8.77535 5.325C8.8408 5.27489 8.92093 5.24773 9.00335 5.24773C9.08578 5.24773 9.16591 5.27489 9.23135 5.325L11.1004 6.75975Z"
          fill="#BCBCC8"
        />
      </g>
      <defs>
        <clipPath id="clip0_1036_98">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default MakerIcon
