import { DEFAULT_PAGE_SIZE } from '../../config'
import ApiClient from '../apiClient'
import { getUserId } from '../user'

const client = new ApiClient()

const getCourtDecisions = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/court-decisions`, {
    params: { page, per_page },
  })
}

const getCourtDecision = ({ userId = getUserId(), decisionId }) => {
  return client.get(`/users/${userId}/court-decisions/${decisionId}`)
}

const courtDecisionService = {
  getCourtDecisions,
  getCourtDecision,
}

export default courtDecisionService
