import Box from '@mui/material/Box'
import React from 'react'

const UserLink = ({ children, user, pathname, ...other }) => {
  const url = pathname || (user?.id ? `/user/${user.id}/company` : '/users')

  const handleSetCurrentUser = () => {
    window.history.pushState({}, '', url)

    if (user?.id) {
      sessionStorage.setItem('selecteduser', user?.id)
    } else {
      sessionStorage.removeItem('selecteduser')
    }
  }

  return (
    <Box
      {...other}
      sx={{ cursor: 'pointer', userSelect: 'none' }}
      onClick={handleSetCurrentUser}
    >
      {children}
    </Box>
  )
}

export default UserLink
