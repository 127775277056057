import React from 'react'
import Dialog from '@mui/material/Dialog'
import UniqueLink from './UniqueLink'

const UniqueLinkDialog = ({ user, onClose, open }) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="md"
      sx={{ maxWidth: '800px', margin: 'auto' }}
      keepMounted
      transitionDuration={{ enter: 400, exit: 200 }}
    >
      <UniqueLink user={user} onClose={onClose} />
    </Dialog>
  )
}

export default UniqueLinkDialog
