import { Box, Button, Dialog, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Snackbar } from '../../../components'
import { useI18n } from '../../../context'
import { useThunk } from '../../../hooks'
import { updateDataConnection } from '../../../redux/dataConnection'
import {
  allowedDataTypesSelector,
  providersByNameSelector,
} from '../../../redux/provider'
import ConnexionActions from './ConnexionActions'
import ConnexionHeader from './ConnexionHeader'
import DataTypesCheckGroup from './DataTypesCheckGroup'
import { UpdateConnexionRoot } from './UpdateConnexion.style'

const UpdateConnexion = ({ connection, open, onClose }) => {
  const { t } = useI18n()
  const { dispatch, loading, errors, errorMsg, ...tk } = useThunk()

  const providersByName = useSelector(providersByNameSelector)

  const provider = providersByName[connection.provider_name]

  const types = provider?.data_types || []

  const allowedDataTypes = useSelector(allowedDataTypesSelector)
  const filterTypes = types.filter((type) => allowedDataTypes?.includes(type))

  const [values, setValues] = useState({ ...connection })

  const selectAll = filterTypes?.length === values.requested_data_types?.length
  const selectOne = values.requested_data_types?.length > 0

  const handleCheckAllToggle = () => {
    setValues({ ...values, requested_data_types: selectAll ? [] : filterTypes })
  }

  const handleCheck = (e) => {
    const { name } = e.target
    const dataTypes = values.requested_data_types

    if (dataTypes.includes(name)) {
      setValues({
        ...values,
        requested_data_types: dataTypes.filter((i) => i !== name),
      })
    } else {
      setValues({
        ...values,
        requested_data_types: [...values.requested_data_types, name],
      })
    }
  }

  const handleUpdate = () => {
    dispatch(() =>
      updateDataConnection({
        dataConnectionId: connection.id,
        status: 'AUTH_REQUIRED',
        requested_data_types: values.requested_data_types,
      })
    )
    onClose()
  }

  useEffect(() => {
    setValues({
      ...values,
      ...connection,
      requested_data_types: connection?.requested_data_types.filter((d) =>
        allowedDataTypes?.includes(d)
      ),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection.id])

  return (
    <>
      <Dialog onClose={onClose} open={open} maxWidth="xs">
        <UpdateConnexionRoot>
          <ConnexionHeader
            title={t('common.Update connection')}
            subTitle={t(`common.providers.${connection.provider_name}`)}
            onClose={onClose}
          />

          <Box display="flex" gap={1} alignItems="center">
            <Typography className="list">
              {t('common.authorizedDataTypes')}
            </Typography>

            <Button
              variant="text"
              color="inherit"
              className="toggler"
              onClick={handleCheckAllToggle}
            >
              {selectAll ? t('common.Uncheck all') : t('common.Check all')}
            </Button>
          </Box>

          <DataTypesCheckGroup
            values={values}
            onChange={handleCheck}
            types={types}
            allowedTypes={allowedDataTypes}
          />

          <ConnexionActions
            disabled={!selectOne}
            onOk={handleUpdate}
            okText={t('common.Update')}
            onClose={onClose}
            loading={loading}
          />
        </UpdateConnexionRoot>
      </Dialog>

      <Snackbar
        open={!loading && (tk.data || tk.data === null)}
        severity="success"
      >
        {t('common.connectionUpdated')}
      </Snackbar>

      <Snackbar open={!loading && !!errors} error={!!errors}>
        {errorMsg}
      </Snackbar>
    </>
  )
}

export default UpdateConnexion
