import addDays from 'date-fns/addDays'

import { format } from './date'

const fromSchedule = (schedule) => {
  const date = !schedule ? new Date() : new Date(schedule.split?.(' ')[0])
  const weekDay = String(date.getDay())
  const monthDay = String(Math.min(date.getDate(), 28))

  const time =
    schedule?.split?.(' ')[1].substring(0, 5) || format(date, 'HH:mm')

  return { weekDay, monthDay, time }
}

const toSchedule = ({ frequency, weekDay, monthDay, time }) => {
  let date = new Date()

  if (date.getDate() > 28) {
    date.setDate(28)
  }

  if (!frequency || frequency === 'NONE') {
    return date.toISOString?.().split('T').join(' ').slice(0, 19)
  }

  if (frequency === 'MONTHLY') {
    date.setDate(Math.min(+monthDay >= 0 ? +monthDay : date.getDate(), 28))
  }

  if (frequency === 'WEEKLY') {
    const weekD = +weekDay >= 0 ? +weekDay : date.getDay()

    date = addDays(date, weekD - date.getDay())
  }

  return `${date.toISOString().substring(0, 10)} ${time}:00`
}

export { fromSchedule, toSchedule }
