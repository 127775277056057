import styled from 'styled-components'

export const UserFormRoot = styled.form`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 16px;

  .subTitle {
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .MuiInputBase-root {
    border-radius: 0;
  }

  .Header {
    > svg {
      fill: ${({ theme }) => theme.palette.text.secondary};
      font-size: 35px;
    }
  }

  .MuiFormLabel-root {
    font-weight: 500;
    font-size: 14px;
  }

  .resultText {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;

    b {
      font-weight: 700;
    }

    &.hideSpan span {
      display: none;
    }
  }

  .Copy {
    > .ellipsis {
      background: #f4f4f4;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      color: #666666;
      max-width: calc(100% - 150px);
      padding: 6px 12px;
    }
  }
`
