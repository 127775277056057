import { DEFAULT_PAGE_SIZE } from '../../config'
import ApiClient from '../apiClient'
import { getUserId } from '../user'

const client = new ApiClient()

const getGoogleNews = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/news`, {
    params: { page, per_page },
  })
}

const googleNewsService = {
  getGoogleNews,
}

export default googleNewsService
