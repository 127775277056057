import { createTheme } from '@mui/material/styles'
import baseTheme from './baseTheme'

const theme = createTheme({
  name: 'dark',
  palette: {
    mode: 'dark',
    primary: { main: '#3DFFC0' },
    secondary: { main: '#041F3D' },
    error: { main: '#f1416c' },
  },
  ...baseTheme,
})

export default theme
