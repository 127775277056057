import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import reportService from '../../services/report/reportService'
import { getUserId } from '../../services/user'

const initialState = {
  reports: null,
  user_id: getUserId(),
}

export const getReports = createAsyncThunk(
  'report/getReports',
  reportService.getReports
)

export const getReport = createAsyncThunk(
  'report/getReport',
  reportService.getReport
)

export const addReport = createAsyncThunk(
  'report/addReport',
  reportService.addReport
)

export const addTransactionReport = createAsyncThunk(
  'report/addTransactionReport',
  reportService.addTransactionReport
)

const report = createSlice({
  name: 'report',
  initialState,
  reducers: {
    //
  },
  extraReducers: {
    [getReports.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.reports = data
    },
    [addTransactionReport.pending]: (state) => {
      state.loading = true
    },
    [addTransactionReport.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.loading = false

      if (data) {
        state.reports.result = [data, ...state.reports.result]
      }
    },
  },
})

export default report.reducer
