import React, { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import MoreVert from '@mui/icons-material/MoreVert'

import { useI18n } from '../../../context'
import DeleteConnexion from '../DeleteConnexion'
import { ConnectionMenuIcon, ConnectionMenuRoot } from './ConnectionMenu.style'
import UpdateConnexion from '../UpdateConnexion'
import ActiveConnection from '../ActiveConnection'

const ConnectionMenu = ({ connection }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(null)
  const active = connection.status === 'CONNECTED'
  const disconnect = connection.status === 'DISCONNECTED'

  const handleClose = () => setAnchorEl(null)

  const handleOpen = (e) => setAnchorEl(e.currentTarget)

  const { t } = useI18n()

  const handleModalOpen = (o) => () => setOpen(o)

  const handleClick = (val) => () => {
    setOpen(val)
    handleClose()
  }

  return (
    <>
      <ConnectionMenuIcon
        onClick={handleOpen}
        className={`ConnectionMenuIcon ${anchorEl ? 'open' : ''}`}
      >
        <MoreVert />
      </ConnectionMenuIcon>

      <ConnectionMenuRoot
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={handleClick('u')}>{t('common.Update')}</MenuItem>

        {(disconnect || active) && (
          <MenuItem onClick={handleClick('a')}>
            {active ? t('common.Disconnect') : t('common.Activate')}
          </MenuItem>
        )}

        <MenuItem onClick={handleClick('d')}>{t('common.Delete')}</MenuItem>
      </ConnectionMenuRoot>

      <DeleteConnexion
        connection={connection}
        open={open === 'd'}
        onClose={handleModalOpen(false)}
      />

      <UpdateConnexion
        connection={connection}
        open={open === 'u'}
        onClose={handleModalOpen(false)}
      />

      <ActiveConnection
        connection={connection}
        open={active ? open === 'a' : false}
        onClose={handleModalOpen(false)}
        skipConfirm={!active && open === 'a'}
      />
    </>
  )
}

export default ConnectionMenu
