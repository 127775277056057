import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '../../../components'
import { isProd } from '../../../config'
import Error from '../Error'

const ErrorPage = ({ status = '404', title, error, embedded }) => {
  const history = useHistory()
  const errorCode = error?.code || status
  const jwt = typeof localStorage !== 'undefined' && localStorage.getItem('jwt')

  let link = '/'

  if (!jwt) {
    link = '/login'
  }

  if (embedded) {
    link = window.location.pathname
  }

  const actionText = embedded
    ? 'Rafraîchir la page'
    : "Aller à la page d'accueil"

  if (!(String(status) === '404' || String(status) === '503') || error) {
    sessionStorage.setItem('error', '1')

    if (isProd) {
      history.replace('/')
      window.location.reload()

      return null
    }
  }

  return (
    <Error
      status={errorCode}
      title={title}
      actions={
        <Button
          variant="contained"
          color="secondary"
          {...{ compponent: 'a' }}
          href={link}
        >
          {actionText}
        </Button>
      }
    />
  )
}

export default ErrorPage
