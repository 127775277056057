import React from 'react'
import { useHistory } from 'react-router-dom'
import Add from '@mui/icons-material/Add'

import { useI18n } from '../../context'
import Button from '../Button'
import DataSyncAlert from './DataSyncAlert'
import DataType, { syncProviders } from './DataType'
import { getUserId } from '../../services/user'

const NoConnectionAlert = ({
  hasDatatypeAlert,
  onClose,
  closedAlert,
  dataTypesToCreate,
  createdConnections,
  dataTypes,
}) => {
  const history = useHistory()
  const { t } = useI18n()

  const showDataTypesToCreate =
    !!dataTypesToCreate?.length &&
    dataTypesToCreate?.length !== dataTypes?.length

  const showNoConnection = !createdConnections?.length // || showDataTypesToCreate

  const id = `NoConnection_${syncProviders(dataTypesToCreate)}_${getUserId()}`

  const closed = !!closedAlert?.[id]
  const open = !hasDatatypeAlert && showNoConnection && !closed

  const handleClose = () => {
    onClose?.(id)
  }

  const handleAddConnection = () => {
    history.replace({
      ...history.location,
      state: { ...history.location.state, open: true },
    })
  }

  return (
    <DataSyncAlert
      title={t('common.alert.noConnection.title')}
      onClose={handleClose}
      open={open}
    >
      {t('common.alert.noConnection')}

      {showDataTypesToCreate && (
        <DataType
        // syncs={dataTypesToCreate?.map((data_type) => ({ data_type }))}
        />
      )}

      <Button
        variant="outlined"
        sx={{
          color: '#4195DA',
          borderColor: '#4195DA',
          backgroundColor: 'fff',
          '&:hover': { borderColor: '#4195DA' },
        }}
        startIcon={<Add sx={{ fontSize: '16px !important' }} />}
        onClick={handleAddConnection}
      >
        {t('common.Connexion')}
      </Button>
    </DataSyncAlert>
  )
}

export default NoConnectionAlert
