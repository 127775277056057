import React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'

const ConnexionHeader = ({ title, subTitle, onClose, ...other }) => {
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="space-between"
      gap={2}
      mb="6px"
      {...other}
    >
      <Box>
        <Typography variant="h6" className="title">
          {title}
        </Typography>

        {subTitle && (
          <Typography variant="body2" className="subTitle">
            {subTitle}
          </Typography>
        )}
      </Box>

      <IconButton
        aria-label="close"
        edge="end"
        onClick={onClose}
        sx={{ mt: '-4px', mr: '-8px' }}
      >
        <CloseIcon sx={{ fontSize: '16px' }} />
      </IconButton>
    </Box>
  )
}

export default ConnexionHeader
