import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const SubscriptionIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 13 13">
      <path d="M3.79183 13C4.39014 13 4.87516 12.515 4.87516 11.9167C4.87516 11.3184 4.39014 10.8333 3.79183 10.8333C3.19352 10.8333 2.7085 11.3184 2.7085 11.9167C2.7085 12.515 3.19352 13 3.79183 13Z" />
      <path d="M9.20833 13C9.80664 13 10.2917 12.515 10.2917 11.9167C10.2917 11.3184 9.80664 10.8333 9.20833 10.8333C8.61002 10.8333 8.125 11.3184 8.125 11.9167C8.125 12.515 8.61002 13 9.20833 13Z" />
      <path d="M12.4582 1.625H11.3748V0.541667C11.3748 0.398008 11.3178 0.260233 11.2162 0.158651C11.1146 0.0570683 10.9768 0 10.8332 0C10.6895 0 10.5517 0.0570683 10.4502 0.158651C10.3486 0.260233 10.2915 0.398008 10.2915 0.541667V1.625H9.20817C9.06451 1.625 8.92674 1.68207 8.82515 1.78365C8.72357 1.88523 8.6665 2.02301 8.6665 2.16667C8.6665 2.31033 8.72357 2.4481 8.82515 2.54968C8.92674 2.65127 9.06451 2.70833 9.20817 2.70833H10.2915V3.79167C10.2915 3.93533 10.3486 4.0731 10.4502 4.17468C10.5517 4.27627 10.6895 4.33333 10.8332 4.33333C10.9768 4.33333 11.1146 4.27627 11.2162 4.17468C11.3178 4.0731 11.3748 3.93533 11.3748 3.79167V2.70833H12.4582C12.6018 2.70833 12.7396 2.65127 12.8412 2.54968C12.9428 2.4481 12.9998 2.31033 12.9998 2.16667C12.9998 2.02301 12.9428 1.88523 12.8412 1.78365C12.7396 1.68207 12.6018 1.625 12.4582 1.625Z" />
      <path d="M11.7926 5.26825C11.7226 5.25514 11.6506 5.25607 11.581 5.27098C11.5113 5.28588 11.4453 5.31446 11.3867 5.35507C11.3282 5.39568 11.2783 5.44751 11.2399 5.50755C11.2015 5.56759 11.1755 5.63464 11.1632 5.70483C11.0957 6.07975 10.8985 6.41902 10.6063 6.66336C10.314 6.90771 9.94516 7.04161 9.56421 7.04167H2.93475L2.42558 2.70833H7.04167C7.18533 2.70833 7.3231 2.65127 7.42468 2.54968C7.52627 2.4481 7.58333 2.31033 7.58333 2.16667C7.58333 2.02301 7.52627 1.88523 7.42468 1.78365C7.3231 1.68207 7.18533 1.625 7.04167 1.625H2.29775L2.275 1.43433C2.22832 1.03924 2.03832 0.675 1.74102 0.41064C1.44371 0.146279 1.05976 0.000169453 0.661917 0L0.541667 0C0.398008 0 0.260233 0.0570683 0.158651 0.158651C0.0570683 0.260233 0 0.398008 0 0.541667C0 0.685326 0.0570683 0.823101 0.158651 0.924683C0.260233 1.02627 0.398008 1.08333 0.541667 1.08333H0.661917C0.794589 1.08335 0.922641 1.13206 1.02178 1.22022C1.12093 1.30838 1.18427 1.42986 1.19979 1.56163L1.94513 7.89913C2.0225 8.55822 2.33918 9.16597 2.83503 9.60701C3.33089 10.048 3.97142 10.2917 4.63504 10.2917H10.2917C10.4353 10.2917 10.5731 10.2346 10.6747 10.133C10.7763 10.0314 10.8333 9.89366 10.8333 9.75C10.8333 9.60634 10.7763 9.46857 10.6747 9.36698C10.5731 9.2654 10.4353 9.20833 10.2917 9.20833H4.63504C4.29895 9.2084 3.9711 9.10425 3.69666 8.91023C3.42223 8.71621 3.2147 8.44187 3.10267 8.125H9.56421C10.1991 8.12506 10.8139 7.90206 11.3011 7.49495C11.7883 7.08783 12.117 6.52249 12.2297 5.89767C12.2424 5.82763 12.2411 5.75579 12.226 5.68625C12.2109 5.61671 12.1822 5.55083 12.1416 5.49238C12.101 5.43393 12.0493 5.38405 11.9894 5.34559C11.9295 5.30713 11.8627 5.28085 11.7926 5.26825Z" />
    </SvgIcon>
  )
}

export default SubscriptionIcon
