import { DEFAULT_PAGE_SIZE } from '../../config'
import ApiClient from '../apiClient'
import { getUserId } from '../user'

const client = new ApiClient()

const getInseeRegistrationProof = ({ userId = getUserId() }) => {
  return client.get(`/users/${userId}/insee-registration-proof`)
}

const getBeneficialOwners = ({ userId = getUserId() }) => {
  return client.get(`/users/${userId}/beneficial-owners`)
}

const getRegistrationProof = ({ userId = getUserId() }) => {
  return client.get(`/users/${userId}/registration-proof`)
}

const getActs = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/acts`, { params: { page, per_page } })
}

const getArticlesOfAssociation = ({ userId = getUserId() }) => {
  return client.get(`/users/${userId}/articles-of-association`)
}

const documentService = {
  getActs,
  getInseeRegistrationProof,
  getArticlesOfAssociation,
  getBeneficialOwners,
  getRegistrationProof,
}

export default documentService
