import merge from 'deepmerge'

export const chartBaseOptions = (palette, options) => {
  return merge(
    {
      responsive: true,
      maintainAspectRatio: false,

      scales: !options?.scales
        ? undefined
        : {
            x: {
              ticks: {
                color: palette.text.secondary,
              },
              grid: {
                color: palette.action.selected,
                tickColor: palette.action.selected,
                borderColor: palette.divider,
              },
            },
            y: {
              ticks: {
                precision: 0,
                color: palette.text.secondary,
              },
              grid: {
                color: palette.action.selected,
                tickColor: palette.action.selected,
                borderColor: palette.divider,
              },
            },
          },
      plugins: {
        legend: {
          labels: {
            font: { size: 14 },
            color: palette.text.secondary,
          },
        },
        tooltips: {
          enabled: true,
          mode: 'index',
          intersect: false,
          borderWidth: 1,
          borderColor: palette.divider,
          backgroundColor: palette.background.default,
          titleColor: palette.text.primary,
          bodyColor: palette.text.secondary,
          footerColor: palette.text.secondary,
        },
      },
    },
    options
  )
}
