import { Dialog, Typography } from '@mui/material'
import React from 'react'

import { Alert, Snackbar } from '../../../components'
import { useI18n } from '../../../context'
import { useThunk } from '../../../hooks'
import { deleteDataConnection } from '../../../redux/dataConnection'
import { getAllDataSyncs } from '../../../redux/dataSync'
import ConnexionActions from '../UpdateConnexion/ConnexionActions'
import ConnexionHeader from '../UpdateConnexion/ConnexionHeader'
import { DeleteConnexionRoot } from './DeleteConnexion.style'

const DeleteConnexion = ({ connection, open, onClose }) => {
  const { t } = useI18n()
  const { dispatch, loading, errors, errorMsg, ...tk } = useThunk()

  const handleDelete = () => {
    dispatch(() => deleteDataConnection({ dataConnectionId: connection.id }))
    tk.run(getAllDataSyncs({ page: 1, per_page: 1000 }))
    onClose()
  }

  return (
    <>
      <Dialog onClose={onClose} open={open} maxWidth="xs">
        <DeleteConnexionRoot>
          <ConnexionHeader
            title={t('common.Delete connection')}
            mb={2}
            onClose={onClose}
          />

          <Typography
            variant="body2"
            mb={1.5}
            dangerouslySetInnerHTML={{
              __html: t('common.confirmConnectionDelete', {
                provider: t(`common.providers.${connection.provider_name}`),
              }),
            }}
          />

          <Alert open error>
            <Typography fontWeight={500}>
              {t('common.connectionDeleteWarn')}
            </Typography>
          </Alert>

          <ConnexionActions
            onOk={handleDelete}
            okText={t('common.Delete')}
            onClose={onClose}
            loading={loading}
            color="error"
          />
        </DeleteConnexionRoot>
      </Dialog>

      <Snackbar open={!loading && tk.data === null} severity="success">
        {t('common.connectionDeleted')}
      </Snackbar>

      <Snackbar open={!loading && !!errors} error={!!errors}>
        {errorMsg}
      </Snackbar>
    </>
  )
}

export default DeleteConnexion
