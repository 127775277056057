import React from 'react'

import { CopyToClipboard } from '../../../components'
import ConnectionMenu from '../ConnectionMenu'
import { ConnectionTableActionsRoot } from './ConnexionTable.style'

const ConnectionTableActions = ({ connection }) => (
  <ConnectionTableActionsRoot>
    {(connection?.redirect_url || connection?.return_url) && (
      <CopyToClipboard
        value={connection?.redirect_url || connection?.return_url}
      />
    )}
    <ConnectionMenu connection={connection} />
  </ConnectionTableActionsRoot>
)

export default ConnectionTableActions
