import { useEffect, useState } from 'react'
import { matchSorter } from 'match-sorter'

const search = ({ q, data = [], ...options }) => {
  const list = data.slice()
  const sort = options?.sort || options?.baseSort

  if (!q?.trim()) return sort ? list.sort(sort) : list

  return matchSorter(list, q, options)
}

const useSearch = ({ q, data, page = 1, pageSize = 0, ...options }) => {
  const [result, setResult] = useState(data)

  const pagedResult = pageSize
    ? result.slice((page - 1) * pageSize, page * pageSize)
    : result

  const count = pageSize ? Math.ceil(result.length / pageSize) : result.length

  const handleSearch = () => {
    setResult(search({ q, data, ...options }))
  }

  useEffect(() => {
    const timer = setTimeout(handleSearch)

    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, data?.length])

  return {
    page,
    pageSize,
    result,
    count,
    pagedResult,
    search: handleSearch,
    showPagination: !!(pageSize && result.length > pageSize),
  }
}

export { useSearch }
