import React from 'react'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'

import { profileSelector } from '../../redux/company'
import { useI18n } from '../../context/I18nProvider'
import { Status } from './CompanyStatus.style'

const CompanyStatus = () => {
  const profile = useSelector(profileSelector)
  const { t } = useI18n()

  const date = profile?.rcs_closing_date || profile?.insee_closing_date

  if (!date) return null

  return (
    <Status>
      <Typography color="error">{t('common.companyClosed')}</Typography>
    </Status>
  )
}

export default CompanyStatus
