import { Dialog, Typography } from '@mui/material'
import React, { useEffect } from 'react'

import { Alert, Snackbar } from '../../../components'
import { useI18n } from '../../../context'
import { useThunk } from '../../../hooks'
import { updateDataConnection } from '../../../redux/dataConnection'
import ConnexionActions from '../UpdateConnexion/ConnexionActions'
import ConnexionHeader from '../UpdateConnexion/ConnexionHeader'
import { ActiveConnectionRoot } from './ActiveConnection.style'

const ActiveConnection = ({ connection, open, skipConfirm, onClose }) => {
  const { t } = useI18n()
  const active = connection.status === 'CONNECTED'
  const authRequired = connection.status === 'AUTH_REQUIRED'
  let severity
  let message

  if (active) {
    severity = 'success'
    message = t('common.connectionActivated')
  } else if (authRequired) {
    severity = 'warning'
    message = t('common.connectionAuthRequired')
  } else {
    severity = 'error'
    message = t('common.connectionDisconnected')
  }

  const { dispatch, loading, errors, errorMsg, ...tk } = useThunk()

  const handleActiveToggle = () => {
    dispatch(() =>
      updateDataConnection({
        dataConnectionId: connection.id,
        status: active ? 'DISCONNECTED' : 'AUTH_REQUIRED',
      })
    )

    onClose?.()
  }

  useEffect(() => {
    if (skipConfirm) handleActiveToggle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipConfirm])

  return (
    <>
      <Dialog onClose={onClose} open={open} maxWidth="xs">
        <ActiveConnectionRoot>
          <ConnexionHeader
            title={
              active
                ? t('common.Disconnect connection')
                : t('common.Activate connection')
            }
            mb={2}
            onClose={onClose}
          />

          <Typography
            variant="body2"
            mb={1.5}
            dangerouslySetInnerHTML={{
              __html: t(
                active
                  ? 'common.confirmConnectionDesactive'
                  : 'common.confirmConnectionActive',
                { provider: t(`common.providers.${connection.provider_name}`) }
              ),
            }}
          />

          <Alert open={active} error>
            <Typography fontWeight={500}>
              {t('common.connectionDisconnectWarn')}
            </Typography>
          </Alert>

          <ConnexionActions
            onOk={handleActiveToggle}
            okText={active ? t('common.Disconnect') : t('common.Activate')}
            onClose={onClose}
            loading={loading}
          />
        </ActiveConnectionRoot>
      </Dialog>

      <Snackbar
        open={!loading && (tk.data || tk.data === null)}
        severity={severity}
      >
        {message}
      </Snackbar>

      <Snackbar open={!loading && !!errors} error={!!errors}>
        {errorMsg}
      </Snackbar>
    </>
  )
}

export default ActiveConnection
