import React from 'react'
import Lens from '@mui/icons-material/Lens'

import { unique } from '../../../utils'
import { DataTypeText } from './DataType.style'
import CopyToClipboard from '../../CopyToClipboard'
import { useI18n } from '../../../context'

export const syncProviders = (syncs) =>
  syncs?.map((s) => s.provider_name || s).join('') || ''

const DataType = ({ syncs, provider, copy }) => {
  const { t } = useI18n()
  const types = [
    ...new Set(
      syncs?.map((s) => s.data_type || s.requested_data_types).flat() || []
    ),
  ]

  const providers = unique('provider_name', syncs)

  return (
    <>
      {!provider &&
        types.map((s) => (
          <DataTypeText className="DataType" variant="inherit" key={s.id}>
            <Lens />
            {t(`dataType.${s}`)}
          </DataTypeText>
        ))}

      {provider &&
        providers.map((p) => (
          <DataTypeText className="DataType" variant="inherit" key={p.id}>
            <Lens />
            {t(`common.providers.${p.provider_name}`)}
            {copy && <CopyToClipboard value={p.redirect_url || p.return_url} />}
          </DataTypeText>
        ))}
    </>
  )
}

export default DataType
