import { createSelector } from '@reduxjs/toolkit'

const bankingAccountsSelector = (state) => state.banking.bankingAccounts

const creditInsightsSelector = (state) => state.banking.creditInsights

const transactionsSelector = (state) => state.banking.transactions

const cashFlowSelector = (state) => state.banking.cashFlow

const registrationProofSelector = (state) => state.banking.registrationProof

const bankAccountSeizureSelector = createSelector(
  creditInsightsSelector,
  (data) => data?.risks.heavy_failures?.bank_account_seizure
)

const overdraftSelector = createSelector(creditInsightsSelector, (data) => {
  return data?.risks.overdraft
})

const incidentsSelector = createSelector(creditInsightsSelector, (data) => {
  return data?.risks.incidents
})

export {
  bankingAccountsSelector,
  creditInsightsSelector,
  registrationProofSelector,
  cashFlowSelector,
  transactionsSelector,
  bankAccountSeizureSelector,
  overdraftSelector,
  incidentsSelector,
}
