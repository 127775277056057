import React, { useState } from 'react'

import { useAuth, useNav } from '../../context'
import { useDataSyncs } from '../../pages/DataSyncs'
import { fromSS } from '../../utils'
import ConnectionToSyncAlert from './ConnectionToSyncAlert'
import NoConnectionAlert from './NoConnectionAlert'
import PendingConnectionAlert from './PendingConnectionAlert'
import PendingSyncAlert from './PendingSyncAlert'
import SyncErrorAlert from './SyncErrorAlert'
import { SyncAlertsRoot } from './SyncAlerts.style'
import { useDataConnections } from '../../pages/DataConnections/DataConnectionTable'
import UniqueLinkAlert from '../../pages/Users/UniqueLink/UniqueLinkAlert'

const SyncAlerts = () => {
  const { currentUser } = useAuth()
  const { currentNav } = useNav()
  const dataTypes = currentNav?.dataTypes

  const { connectedDataTypes, createdConnections, connectionToFinish } =
    useDataConnections(dataTypes)

  const [closedAlert, setClosedAlert] = useState(fromSS('closedAlert', true))

  const createdConnectionTypes = createdConnections
    ?.map((d) => d?.requested_data_types)
    .flat()

  const dataTypesToCreate = dataTypes?.filter(
    (d) => !createdConnectionTypes?.includes(d)
  )

  const sync = useDataSyncs({ dataTypes })
  const matchedSyncTypes = sync.matchedSyncs?.map((s) => s.data_type)

  const connectionsToSync = sync.fetched
    ? connectedDataTypes?.filter((i) => !matchedSyncTypes?.includes(i)) || []
    : []

  const dataTypesToSync = [...new Set(connectionsToSync)].filter((d) =>
    dataTypes?.includes(d)
  )

  const primary = currentNav?.requires?.includes('DASHBOARD')

  const handleClose = (id) => {
    const newVal = { ...closedAlert, [id]: true }

    setClosedAlert(newVal)
    sessionStorage.setItem('closedAlert', JSON.stringify(newVal))
  }

  if (primary || !dataTypes?.length) return null

  const hasDatatypeAlert =
    !!connectionToFinish?.length ||
    !!dataTypesToSync?.length ||
    !!dataTypesToSync?.length ||
    !!sync.pendingSyncs?.length ||
    !!sync.errorSyncs?.length

  const alertProps = { onClose: handleClose, closedAlert }

  return (
    <SyncAlertsRoot>
      <NoConnectionAlert
        {...alertProps}
        dataTypes={dataTypes}
        hasDatatypeAlert={hasDatatypeAlert}
        dataTypesToCreate={dataTypesToCreate}
        createdConnections={createdConnections}
      />
      <UniqueLinkAlert {...alertProps} user={currentUser} />
      <PendingConnectionAlert
        {...alertProps}
        connectionToFinish={connectionToFinish}
      />
      <ConnectionToSyncAlert
        {...alertProps}
        dataTypesToSync={dataTypesToSync}
        connectionsToSync={connectionsToSync}
        dataTypes={dataTypes}
      />
      <PendingSyncAlert {...alertProps} pendingSyncs={sync.pendingSyncs} />
      <SyncErrorAlert {...alertProps} errorSyncs={sync.errorSyncs} />
    </SyncAlertsRoot>
  )
}

export default React.memo(SyncAlerts)
