import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import { Typography } from '@mui/material'
import { format } from '../../../utils'
import { Label } from '../../../components'

const colors = {
  FETCH_ERROR: 'DANGER',
  SUCCESS: 'SUCCESS',
}

const buildColumns = (t) => {
  const tableColumn = [
    {
      label: 'Type',
      key: 'data_type',
      width: '28%',
      render: ({ data_type }) => t(`dataType.${data_type}`),
    },
    {
      label: 'Provider',
      key: 'provider_name',
      width: '25%',
      render: ({ provider_name }) =>
        provider_name ? (
          t(`common.providers.${provider_name}`)
        ) : (
          <Typography className="Insight">
            {t('sync.provider.Insight')}
          </Typography>
        ),
    },
    {
      label: 'Status',
      key: 'status',
      width: '20%',
      render: ({ status, error_message }) => (
        <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Label color={colors[status]}>{status}</Label>
          {error_message && (
            <Label>
              <Tooltip title={error_message}>
                <HelpOutlineIcon
                  sx={{
                    fontSize: '16px',
                    verticalAlign: 'middle',
                    margin: '-4px',
                  }}
                />
              </Tooltip>
            </Label>
          )}
        </span>
      ),
    },
    {
      label: 'Requested at',
      key: 'requested_at',
      render: ({ requested_at }) => format(requested_at, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      label: 'Completed at',
      key: 'completed_at',
      render: ({ completed_at }) => format(completed_at, 'yyyy-MM-dd HH:mm:ss'),
    },
  ].map((c) => ({ ...c, label: t(`common.${c.label}`) }))

  return tableColumn
}

export { buildColumns }
