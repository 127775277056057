import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const TransactionIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 13 10">
      <path d="M2.979 7.58334C3.42774 7.58334 3.7915 7.21957 3.7915 6.77084C3.7915 6.32211 3.42774 5.95834 2.979 5.95834C2.53027 5.95834 2.1665 6.32211 2.1665 6.77084C2.1665 7.21957 2.53027 7.58334 2.979 7.58334Z" />
      <path d="M10.2917 0H2.70833C1.9903 0.00086009 1.30193 0.286478 0.794202 0.794202C0.286478 1.30193 0.00086009 1.9903 0 2.70833L0 7.04167C0.00086009 7.7597 0.286478 8.44807 0.794202 8.9558C1.30193 9.46352 1.9903 9.74914 2.70833 9.75H10.2917C11.0097 9.74914 11.6981 9.46352 12.2058 8.9558C12.7135 8.44807 12.9991 7.7597 13 7.04167V2.70833C12.9991 1.9903 12.7135 1.30193 12.2058 0.794202C11.6981 0.286478 11.0097 0.00086009 10.2917 0V0ZM2.70833 1.08333H10.2917C10.7226 1.08333 11.136 1.25454 11.4407 1.55928C11.7455 1.86403 11.9167 2.27736 11.9167 2.70833H1.08333C1.08333 2.27736 1.25454 1.86403 1.55928 1.55928C1.86403 1.25454 2.27736 1.08333 2.70833 1.08333V1.08333ZM10.2917 8.66667H2.70833C2.27736 8.66667 1.86403 8.49546 1.55928 8.19072C1.25454 7.88597 1.08333 7.47264 1.08333 7.04167V3.79167H11.9167V7.04167C11.9167 7.47264 11.7455 7.88597 11.4407 8.19072C11.136 8.49546 10.7226 8.66667 10.2917 8.66667Z" />
    </SvgIcon>
  )
}

export default TransactionIcon
