import React, { useEffect, useRef, useState } from 'react'

import { ClickAwayListener, IconButton, Tooltip } from '@mui/material'

import CopyIcon from '../icons/CopyIcon'
import { copyToClipboard } from '../../utils'
import { useI18n } from '../../context'

const CopyToClipboard = ({
  value,
  tooltip,
  timeout = 4000,
  children,
  Icon = CopyIcon,
  ...other
}) => {
  const [open, setOpen] = useState(false)
  const { t } = useI18n()

  const child = children && React.Children.only(children)

  const timer = useRef(null)

  const handleCopy = (e) => {
    e.stopPropagation()
    copyToClipboard(value)
    setOpen(true)

    child?.props?.onClick?.(e)

    timer.current = setTimeout(() => setOpen(false), timeout)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Tooltip
        title={tooltip || t('common.Copied to clipboard')}
        open={open}
        color="primary"
        arrow
      >
        {!child ? (
          <IconButton
            className="CopyToClipboard"
            size="small"
            {...other}
            onClick={handleCopy}
          >
            <Icon sx={{ fontSize: '12px !important' }} />
          </IconButton>
        ) : (
          React.cloneElement(child, { onClick: handleCopy })
        )}
      </Tooltip>
    </ClickAwayListener>
  )
}

export default CopyToClipboard
