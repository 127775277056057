import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const WarnOutlinedIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <g clipPath="url(#clip0_635_331)">
        <path
          d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34872 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99076 1.82679 8.37425C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27289 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7118 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276 17.4522 12.2094 18.3309 10 18.3333Z"
          fill="#FFC700"
        />
        <path
          d="M9.99984 4.16666C9.77882 4.16666 9.56686 4.25445 9.41058 4.41073C9.2543 4.56701 9.1665 4.77898 9.1665 4.99999V11.6667C9.1665 11.8877 9.2543 12.0996 9.41058 12.2559C9.56686 12.4122 9.77882 12.5 9.99984 12.5C10.2209 12.5 10.4328 12.4122 10.5891 12.2559C10.7454 12.0996 10.8332 11.8877 10.8332 11.6667V4.99999C10.8332 4.77898 10.7454 4.56701 10.5891 4.41073C10.4328 4.25445 10.2209 4.16666 9.99984 4.16666Z"
          fill="#FFC700"
        />
        <path
          d="M10.8332 15C10.8332 14.5398 10.4601 14.1667 9.99984 14.1667C9.5396 14.1667 9.1665 14.5398 9.1665 15C9.1665 15.4602 9.5396 15.8333 9.99984 15.8333C10.4601 15.8333 10.8332 15.4602 10.8332 15Z"
          fill="#FFC700"
        />
      </g>
      <defs>
        <clipPath id="clip0_635_331">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default WarnOutlinedIcon
