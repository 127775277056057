import React, { useContext, createContext, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getCompanyProfile } from '../../redux/company'
import { useAuth } from '../AuthProvider'
import { useI18n } from '../I18nProvider'
import BuildNavigations from './navigations'
import { NATURAL, excludeCategoriesForNatural } from '../../config'

export const getNavTitle = (nav) => {
  const title = nav?.title || nav?.name

  if (!title) return null

  return typeof nav?.title === 'function' ? nav.title() : title
}

export const getNavUrl = (nav) => {
  return typeof nav.url === 'string' ? nav.url : nav.url?.() || nav.path
}

const NavContext = createContext({
  navigations: [],
  currentNav: null,
  navsByPath: {},
  currentNavTitle: {},
  getNavUrl,
  getNavTitle,
})

const getFilteredNavigations = (user, navList = [], roles = []) => {
  return navList.reduce((array, nav) => {
    if (
      !(
        user?.type === NATURAL &&
        excludeCategoriesForNatural.includes(nav.category)
      )
    ) {
      if (nav.requires) {
        if (roles.every((role) => nav.requires.includes(role))) {
          array.push(nav)
        }
      } else if (nav.children) {
        // eslint-disable-next-line no-param-reassign
        nav.children = getFilteredNavigations(nav.children, roles)
        array.push(nav)
      } else {
        array.push(nav)
      }
    }

    return array
  }, [])
}

const NavProvider = ({ children }) => {
  const { pathname } = useLocation()
  const { t, currentLanguage } = useI18n()

  const dispatch = useDispatch()
  const { userId, currentUser } = useAuth()

  const userRoles = userId ? ['COMPANY'] : ['DASHBOARD']

  const navigations = useMemo(() => {
    const navsByPath = {}
    const navs = BuildNavigations({ t, currentLanguage })

    navs.forEach((n) => {
      navsByPath[n.path] = n

      n.children?.forEach((c) => {
        c.parent = n
        navsByPath[c.path] = c
      })
    })

    return { navs, navsByPath }
  }, [t, currentLanguage])

  const value = {
    navigations: getFilteredNavigations(
      currentUser,
      navigations.navs,
      userRoles
    ),
    getNavUrl,
    getNavTitle,
    navsByPath: navigations.navsByPath,
  }

  value.currentNav =
    value.navsByPath[pathname] ||
    Object.values(value.navsByPath).find(
      (n) => (n.url?.() || n.url) === pathname
    )

  useEffect(() => {
    if (userId) {
      dispatch(getCompanyProfile({}))
    }
  }, [dispatch, userId])

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>
}

const useNav = () => {
  return useContext(NavContext)
}

export { useNav }
export default NavProvider
