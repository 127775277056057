import Box from '@mui/material/Box'
import styled, { css } from 'styled-components'

const ContentWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  transition: all 0.3s ease-out;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 24px;
  height: calc(100vh - 70px);
  padding-top: 38px;

  .MuiDrawer-docked {
    position: sticky;
    top: 0;
  }

  .MuiDrawer-paperAnchorDockedLeft {
    position: static;
    box-shadow: 0px 4px 12px rgba(76, 87, 125, 0.02);
    border-radius: 5px;
    background: transparent;
  }

  &.menuClosed {
    margin-left: 0;
    display: block;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      margin-left: 0 !important;
    }
  `}
`

export { ContentWrapper }
