import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import styled from 'styled-components'

export const SidebarUserRoot = styled(Stack)`
  background: #ffffff;
  box-shadow: 0px 5px 25px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 22px;
  margin-bottom: 13px;
  transition: all 0.3s;
  border: 2px solid #fff;

  &:hover,
  &.active {
    border: 2px solid #3dffc0;

    .Title {
      color: #000000;
    }
  }

  .Title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #7e8299;
    transition: all 0.3s;
  }

  .SubTitle {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #5b5b5b;
  }

  .MuiAvatar-root {
    width: 37px;
    height: 37px;
    background: #7e8299;

    svg {
      font-size: 16px;
    }
  }
`

export const TooltipContent = styled(Box)`
  > p {
    font-size: 10px;
    font-weight: 700;
    line-height: 15px;
    color: #ffffff;
  }

  > span {
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    color: #ffffff;
  }
`
