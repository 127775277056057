import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ChartIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="m14.414 13 6.745 6.745A11.945 11.945 0 0 0 23.95 13Z" />
      <path d="M11.293 12.707 11 12.414V.05a12 12 0 1 0 8.745 21.109Z" />
      <path d="M13 11h10.95A11.99 11.99 0 0 0 13 .05Z" />
    </SvgIcon>
  )
}

export default ChartIcon
