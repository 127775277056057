import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const FinancialAnalysisIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 13 13">
      <path d="M12.4583 11.9167H2.70833C2.27736 11.9167 1.86403 11.7455 1.55928 11.4407C1.25454 11.136 1.08333 10.7226 1.08333 10.2917V0.541667C1.08333 0.398008 1.02627 0.260233 0.924683 0.158651C0.823101 0.0570683 0.685326 0 0.541667 0C0.398008 0 0.260233 0.0570683 0.158651 0.158651C0.0570683 0.260233 0 0.398008 0 0.541667L0 10.2917C0.00086009 11.0097 0.286478 11.6981 0.794202 12.2058C1.30193 12.7135 1.9903 12.9991 2.70833 13H12.4583C12.602 13 12.7398 12.9429 12.8414 12.8414C12.9429 12.7398 13 12.602 13 12.4583C13 12.3147 12.9429 12.1769 12.8414 12.0753C12.7398 11.9737 12.602 11.9167 12.4583 11.9167Z" />
      <path d="M3.25016 10.8333C3.39382 10.8333 3.5316 10.7763 3.63318 10.6747C3.73476 10.5731 3.79183 10.4353 3.79183 10.2917V6.50001C3.79183 6.35635 3.73476 6.21858 3.63318 6.11699C3.5316 6.01541 3.39382 5.95834 3.25016 5.95834C3.1065 5.95834 2.96873 6.01541 2.86715 6.11699C2.76556 6.21858 2.7085 6.35635 2.7085 6.50001V10.2917C2.7085 10.4353 2.76556 10.5731 2.86715 10.6747C2.96873 10.7763 3.1065 10.8333 3.25016 10.8333Z" />
      <path d="M5.4165 5.41667V10.2917C5.4165 10.4353 5.47357 10.5731 5.57515 10.6747C5.67674 10.7763 5.81451 10.8333 5.95817 10.8333C6.10183 10.8333 6.2396 10.7763 6.34119 10.6747C6.44277 10.5731 6.49984 10.4353 6.49984 10.2917V5.41667C6.49984 5.27301 6.44277 5.13523 6.34119 5.03365C6.2396 4.93207 6.10183 4.875 5.95817 4.875C5.81451 4.875 5.67674 4.93207 5.57515 5.03365C5.47357 5.13523 5.4165 5.27301 5.4165 5.41667Z" />
      <path d="M8.125 7.04167V10.2917C8.125 10.4353 8.18207 10.5731 8.28365 10.6747C8.38523 10.7763 8.52301 10.8333 8.66667 10.8333C8.81033 10.8333 8.9481 10.7763 9.04968 10.6747C9.15127 10.5731 9.20833 10.4353 9.20833 10.2917V7.04167C9.20833 6.89801 9.15127 6.76023 9.04968 6.65865C8.9481 6.55707 8.81033 6.5 8.66667 6.5C8.52301 6.5 8.38523 6.55707 8.28365 6.65865C8.18207 6.76023 8.125 6.89801 8.125 7.04167Z" />
      <path d="M10.8335 4.87501V10.2917C10.8335 10.4353 10.8906 10.5731 10.9921 10.6747C11.0937 10.7763 11.2315 10.8333 11.3752 10.8333C11.5188 10.8333 11.6566 10.7763 11.7582 10.6747C11.8598 10.5731 11.9168 10.4353 11.9168 10.2917V4.87501C11.9168 4.73135 11.8598 4.59358 11.7582 4.49199C11.6566 4.39041 11.5188 4.33334 11.3752 4.33334C11.2315 4.33334 11.0937 4.39041 10.9921 4.49199C10.8906 4.59358 10.8335 4.73135 10.8335 4.87501Z" />
      <path d="M3.25005 4.875C3.39369 4.87497 3.53145 4.81788 3.63301 4.71629L5.57542 2.77387C5.67866 2.67552 5.81579 2.62066 5.95838 2.62066C6.10097 2.62066 6.2381 2.67552 6.34134 2.77387L7.51784 3.95037C7.82257 4.25501 8.23582 4.42615 8.66671 4.42615C9.09761 4.42615 9.51086 4.25501 9.81559 3.95037L12.8413 0.924622C12.94 0.822462 12.9946 0.685636 12.9934 0.543613C12.9921 0.401589 12.9352 0.265733 12.8347 0.165303C12.7343 0.0648739 12.5985 0.00790745 12.4564 0.00667331C12.3144 0.00543916 12.1776 0.0600361 12.0754 0.158705L9.04967 3.18391C8.94809 3.28546 8.81034 3.34251 8.66671 3.34251C8.52308 3.34251 8.38533 3.28546 8.28376 3.18391L7.10726 2.00796C6.80252 1.70331 6.38927 1.53218 5.95838 1.53218C5.52749 1.53218 5.11424 1.70331 4.80951 2.00796L2.86709 3.95037C2.79136 4.02612 2.73979 4.12263 2.7189 4.22769C2.69801 4.33275 2.70873 4.44164 2.74972 4.5406C2.79071 4.63956 2.86012 4.72415 2.94917 4.78367C3.03823 4.84319 3.14293 4.87497 3.25005 4.875Z" />
    </SvgIcon>
  )
}

export default FinancialAnalysisIcon
