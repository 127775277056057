import React from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'

import { splitInTwo } from '../../../utils'
import { useI18n } from '../../../context'

const CheckGroup = ({ data, allowedTypes, onChange, values }) => {
  const { t } = useI18n()

  const disabled = (type) =>
    allowedTypes ? !allowedTypes.includes(type) : undefined

  return (
    <Box display="flex" flexDirection="column">
      {data.map((type) => (
        <Tooltip
          title={disabled(type) ? t('settings.dataTypeDisabled') : ''}
          arrow
          key={type}
        >
          <FormControlLabel
            disabled={disabled(type)}
            control={
              <Checkbox
                value={type}
                name={type}
                checked={values.requested_data_types?.includes(type)}
                onChange={onChange}
              />
            }
            label={t(`dataType.${type}`)}
          />
        </Tooltip>
      ))}
    </Box>
  )
}

const DataTypesCheckGroup = ({
  values,
  minSplit = 3,
  onChange,
  types,
  allowedTypes,
  ...other
}) => {
  const [arr1, arr2] = splitInTwo(types, minSplit)

  return (
    <FormGroup {...other}>
      <Grid container>
        <Grid item xs={arr2.length ? 6 : 12}>
          <CheckGroup
            data={arr1}
            allowedTypes={allowedTypes}
            values={values}
            onChange={onChange}
          />
        </Grid>

        {!!arr2.length && (
          <Grid item xs={6}>
            <CheckGroup
              data={arr2}
              allowedTypes={allowedTypes}
              values={values}
              onChange={onChange}
            />
          </Grid>
        )}
      </Grid>
    </FormGroup>
  )
}

export default DataTypesCheckGroup
