const getCurrency = (currency) =>
  currencies[currency?.toLowerCase()] || currency

const roundToTwo = (num, unit) => {
  const price = formatPrice(+`${Math.round(`${num}e+2`)}e-2`)

  return price === 'NaN' ? 'N/A' : `${price}${unit}`
}

const formatPrice = (
  price,
  currency,
  maximumFractionDigits = 2,
  minimumFractionDigits = 0
) => {
  if (price == null) return 'N/A'

  const curr = currency ? ` ${getCurrency(currency)}` : ''

  return `${new Intl.NumberFormat('fr-FR', {
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(price)}${curr}`.replaceAll('’', ' ')
}

/**
 * Returns the human-readable format of the specified amount
 * @param {number} amount amount to format
 */
const formatAmount = (amount, currency = '') => {
  const curr = currency ? ` ${getCurrency(currency)}` : ''

  if (amount == null) return 'N/A'

  const res = Number(amount) / 1000

  if (parseInt(res / 1000, 10) !== 0)
    return `${roundToTwo(res / 1000, `M${curr}`)}`

  if (parseInt(res, 10) !== 0) return `${roundToTwo(res, `K${curr}`)}`

  return `${roundToTwo(amount, `${curr}`)}`
}

const currencies = {
  eur: '€',
  euro: '€',
  usd: '$',
  gbp: '£',
}

const sortInsights = (data) => {
  const getTime = (r) =>
    new Date(
      r.source_created_at || r.source_updated_at || `${r?.month}-01`
    ).getTime()

  data?.sort((a, b) => getTime(a) - getTime(b))
}

const round = (num, maxDigit = 2) => {
  if (!num) return num

  return +`${Math.round(`${num}e+${maxDigit}`)}e-${maxDigit}`
}

export { formatAmount, getCurrency, sortInsights, formatPrice, round }
