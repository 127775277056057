/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import courtDecisionService from '../../services/courtDecision'

const initialState = {
  courtDecisions: null,
}

export const getCourtDecisions = createAsyncThunk(
  'courtDecision/getCourtDecisions',
  courtDecisionService.getCourtDecisions
)

const courtDecision = createSlice({
  name: 'courtDecision',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getCourtDecisions.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.courtDecisions = data
    },
    [getCourtDecisions.rejected]: (state) => {
      state.courtDecisions = null
    },
    [getCourtDecisions.pending]: (state) => {
      state.courtDecisions = null
    },
  },
})

export default courtDecision.reducer
