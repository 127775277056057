import styled from 'styled-components'
import BaseCard from '../BaseCard'

export const CompanyRoot = styled(BaseCard)`
  height: auto;
  background-color: #ffffff8f;

  .card-company {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px,
      rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
    background-color: #fff;
    border: groove 1px;
  }

  .heading {
    font-weight: 520;
    font-size: 1rem;
  }

  .label,
  .value {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000;
  }

  .label {
    font-weight: 600;
    color: #bcbcc8;
  }

  .stack-header {
    flex-direction: row;
  }

  .stack-infos {
    display: flex;
    flex-flow: row wrap;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 8px;
  }

  .stack-info {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    font-size: x-small;
  }

  .stack-icon {
    color: #bcbcc8;
    margin-right: 4px;
    font-size: 1.4rem;
  }

  .paper-footer {
    background-color: rgba(220, 220, 223, 0.5);
    color: rgb(17, 25, 39);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
  }

  .paper-footer-content {
    display: flex;
    flex-flow: row wrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px !important;
  }

  .paper-footer-subcontent {
    display: flex;
    flex-direction: row;

    button {
      border-radius: 50%;
      padding: 10px;
      min-width: auto;
      color: #fff;
      background-color: #041f3d;
    }
  }

  .stack-siren {
    font-size: 12px;
    font-weight: normal;
  }
`
