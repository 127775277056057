import Box from '@mui/material/Box'
import styled from 'styled-components'

export const ProvidersRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 11px;
  padding-right: 8px;
  flex-grow: 1;
  height: 250px;
`
