import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const UserGroupIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M7.5 13A4.5 4.5 0 1 1 12 8.5 4.505 4.505 0 0 1 7.5 13zm0-6A1.5 1.5 0 1 0 9 8.5 1.5 1.5 0 0 0 7.5 7zM15 22.5a7.5 7.5 0 0 0-15 0 1.5 1.5 0 0 0 3 0 4.5 4.5 0 0 1 9 0 1.5 1.5 0 0 0 3 0zm9-4.5a7 7 0 0 0-11.455-5.4 1.5 1.5 0 1 0 1.91 2.314A3.951 3.951 0 0 1 17 14a4 4 0 0 1 4 4 1.5 1.5 0 0 0 3 0zm-6.5-9A4.5 4.5 0 1 1 22 4.5 4.505 4.505 0 0 1 17.5 9zm0-6A1.5 1.5 0 1 0 19 4.5 1.5 1.5 0 0 0 17.5 3z" />
    </SvgIcon>
  )
}

export default UserGroupIcon
