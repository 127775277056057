import Box from '@mui/material/Box'
import styled from 'styled-components'

export const ConnectionTableActionsRoot = styled(Box)`
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  .CopyToClipboard {
    border-radius: 5px;
    width: 30px;
    height: 30px;
    background: #f4f4f4;
    border: 1px solid #edeced;
    border-radius: 5px;

    &:hover,
    &.open {
      background: #deddde;
      border: 1px solid #c9c9ca;
      border-radius: 5px;
    }

    svg {
      font-size: 18px;
    }
  }
`
