import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const CloseIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 7 8">
      <path
        d="M3.91243 3.5558L6.9146 0.504205C7.02651 0.38644 7.02331 0.198745 6.90745 0.0849967C6.79443 -0.0259722 6.61521 -0.0259722 6.50218 0.0849967L3.49999 3.13659L0.497815 0.0849967C0.381944 -0.0287517 0.197302 -0.0254858 0.085396 0.0922788C-0.0237758 0.207167 -0.0237758 0.389317 0.085396 0.504205L3.08757 3.5558L0.085396 6.6074C-0.0284653 6.72318 -0.0284653 6.91084 0.085396 7.02661C0.199298 7.14234 0.383927 7.14234 0.497815 7.02661L3.49999 3.97501L6.50217 7.02661C6.61607 7.14234 6.8007 7.14234 6.91459 7.02661C7.02845 6.91083 7.02845 6.72316 6.91459 6.6074L3.91243 3.5558Z"
        fill="black"
      />
    </SvgIcon>
  )
}

export default CloseIcon
