/* eslint-disable max-lines */
import { createSelector } from '@reduxjs/toolkit'
import lastDayOfMonth from 'date-fns/lastDayOfMonth'

import { format, fromLS, sum } from '../../utils'

const balancesSelector = (state) => state.commerce.balances

const subscriptionsSelector = (state) => state.commerce.subscriptions

const transactionsSelector = (state) => state.commerce.transactions

const payoutsSelector = (state) => state.commerce.payouts

const disputesSelector = (state) => state.commerce.disputes

const customersSelector = (state) => state.commerce.customers

const productsSelector = (state) => state.commerce.products

const ordersSelector = (state) => state.commerce.orders

const registrationProofSelector = (state) => state.commerce.registrationProof

const commerceDateSelector = (state) => state.commerce.date

const transactionInsightsSelector = (state) =>
  state.commerce.transactionInsights

const subscriptionInsightsSelector = (state) =>
  state.commerce.subscriptionInsights

const disputeInsightsSelector = (state) => state.commerce.disputesInsights

const commerceMinDateSelector = (state) => new Date(state.commerce.minDate)

const commerceMaxDateSelector = (state) => new Date(state.commerce.maxDate)

const commerceLatestRangeDateSelector = (state) =>
  state.commerce.latestRange && {
    minDate: new Date(state.commerce.latestRange.minDate),
    maxDate: new Date(state.commerce.latestRange.maxDate),
  }

const commerceDatesSelector = (state) =>
  state.commerce.dates?.map((d) => new Date(d))

const commerceDateRangeSelector = createSelector(
  commerceMinDateSelector,
  commerceMaxDateSelector,
  commerceLatestRangeDateSelector,
  commerceDatesSelector,
  (min, max, latestRange, dates) => {
    return {
      min,
      max,
      latestRange,
      range: `${format(min, 'MMM yyyy')} - ${format(max, 'MMM yyyy')}`,
      dates,
    }
  }
)

export const rangeFilter = (r, range) => {
  const max = lastDayOfMonth(range.max)
  const min = new Date(range.min)

  min.setDate(1)

  const date = new Date(
    r.source_created_at || r.source_updated_at || `${r?.month}-01`
  )

  return date.getTime() <= max.getTime() && date.getTime() >= min.getTime()
}

const commerceTransactionsSelector = createSelector(
  transactionsSelector,
  commerceDateRangeSelector,
  (transactions, date) => {
    return transactions?.result.filter((r) => rangeFilter(r, date))
  }
)

const commercePayoutsSelector = createSelector(
  payoutsSelector,
  commerceDateRangeSelector,
  (payouts, date) => {
    return payouts?.result.filter((r) => rangeFilter(r, date))
  }
)

const commerceDisputessSelector = createSelector(
  disputesSelector,
  commerceDateRangeSelector,
  (disputes, date) => {
    return disputes?.result.filter((r) => rangeFilter(r, date))
  }
)

const commerceCustomersSelector = createSelector(
  customersSelector,
  commerceDateRangeSelector,
  (disputes, date) => {
    return disputes?.result.filter((r) => rangeFilter(r, date))
  }
)

const commerceSubscriptionsSelector = createSelector(
  subscriptionsSelector,
  commerceDateRangeSelector,
  (subscriptions, date) => {
    return subscriptions?.result.filter((r) => rangeFilter(r, date))
  }
)

const commerceTransactionInsightsSelector = createSelector(
  transactionInsightsSelector,
  commerceDateRangeSelector,
  (transactions, date) => {
    return transactions?.filter((r) => rangeFilter(r, date))
  }
)

const commerceDisputeInsightsSelector = createSelector(
  disputeInsightsSelector,
  commerceDateRangeSelector,
  (disputes, date) => {
    return disputes?.filter((r) => rangeFilter(r, date))
  }
)

const commerceSubscriptionInsightsSelector = createSelector(
  subscriptionInsightsSelector,
  commerceDateRangeSelector,
  (subscriptions, date) => {
    return subscriptions?.filter((r) => rangeFilter(r, date))
  }
)

const getProgress = (arr, getter = (d) => d.amount?.value) => {
  const range = fromLS('commerceDate', true)

  if (!arr || arr?.length < 2 || !range) return null

  const minData = arr.filter(
    (r) =>
      new Date(
        r.source_created_at || r.source_updated_at || `${r?.month}-01`
      ).getMonth() === new Date(range.minDate).getMonth()
  )

  const maxData = arr.filter(
    (r) =>
      new Date(
        r.source_created_at || r.source_updated_at || `${r?.month}-01`
      ).getMonth() === new Date(range.maxDate).getMonth()
  )

  if (!minData?.length || !maxData?.length) return null

  const minAmount = sum(minData, getter)
  const maxAmount = sum(maxData, getter)

  return parseInt(((maxAmount - minAmount) / minAmount) * 100, 10) || null
}

export {
  balancesSelector,
  subscriptionsSelector,
  registrationProofSelector,
  disputesSelector,
  transactionsSelector,
  commerceDateSelector,
  transactionInsightsSelector,
  subscriptionInsightsSelector,
  disputeInsightsSelector,
  commerceMinDateSelector,
  commerceMaxDateSelector,
  commerceDateRangeSelector,
  commerceTransactionsSelector,
  commerceDisputessSelector,
  commerceSubscriptionsSelector,
  commerceTransactionInsightsSelector,
  commerceSubscriptionInsightsSelector,
  commerceDisputeInsightsSelector,
  getProgress,
  commercePayoutsSelector,
  payoutsSelector,
  commerceCustomersSelector,
  customersSelector,
  productsSelector,
  ordersSelector,
}
