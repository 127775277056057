import React from 'react'
import { useSelector } from 'react-redux'
import HelpIcon from '@mui/icons-material/Help'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import ConnectionCheckIcon from '../../../components/icons/ConnectionCheckIcon'
import { useI18n } from '../../../context'
import { connectionsByProviderSelector } from '../../../redux/dataConnection'
import ConnectionCount from './ConnectionCount'
import ClockIcon from '../../../components/icons/ClockOutlinedIcon'
import { ProviderItemRoot } from './ProviderItem.style'

const ProviderItem = ({ onSelect, provider }) => {
  const { t } = useI18n()

  const connectionByProvider = useSelector(connectionsByProviderSelector)
  const providerConnections = connectionByProvider[provider.name]

  const connectedConnections = connectionByProvider[provider.name]?.filter(
    (p) => p.status === 'CONNECTED'
  )

  const pendingConnections = connectionByProvider[provider.name]?.filter(
    (p) => p.status === 'CONNECTING' || p.status === 'AUTH_REQUIRED'
  )

  const errorConnections = connectionByProvider[provider.name]?.filter(
    (p) => p.status === 'AUTH_ERROR'
  )

  const disabledProvider = provider.unique && providerConnections?.length

  return (
    <ProviderItemRoot
      key={provider.name}
      onClick={onSelect(provider)}
      disabled={!!disabledProvider}
    >
      <Avatar
        variant="rounded"
        alt={t(`common.providers.${provider?.name}`)}
        src={provider.image}
        className={disabledProvider ? 'disabled-logo' : ''}
      />

      <Box flexGrow={1}>
        <Typography className={disabledProvider ? 'disabled-text' : ''}>
          {t(`common.providers.${provider?.name}`)}
        </Typography>
        <Typography
          variant="body2"
          className={disabledProvider ? 'disabled-text' : ''}
        >
          {provider.category}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" gap="5px">
        {disabledProvider && (
          <Tooltip title={t('common.providerDisabledText')} arrow>
            <HelpIcon fontSize="18" />
          </Tooltip>
        )}

        {!disabledProvider && (
          <>
            <ConnectionCount
              color="#50CD89"
              bgcolor="#E8FFF3"
              data={connectedConnections}
              title={t('connexion.connected')}
              icon={<ConnectionCheckIcon />}
            />

            <ConnectionCount
              color="#181C32"
              bgcolor="#EFF2F5"
              data={pendingConnections}
              title={t('connexion.pending')}
              icon={<ClockIcon />}
            />

            <ConnectionCount
              color="#F1416C"
              bgcolor="#FAD9E2"
              data={errorConnections}
              title={t('connexion.error')}
              icon={<ClockIcon />}
            />
          </>
        )}
      </Box>
    </ProviderItemRoot>
  )
}

export default ProviderItem
