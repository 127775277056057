const getHashParams = (hash = window.location.hash) => {
  return hash
    ?.substring(1)
    .split('&')
    .reduce((initial, item) => {
      if (item) {
        const parts = item.split('=')

        initial[parts[0]] = decodeURIComponent(parts[1])
      }

      return initial
    }, {})
}

const removeHashParamsFromUrl = () => {
  window.history.pushState(
    '',
    document.title,
    window.location.pathname + window.location.search
  )
}

export { getHashParams, removeHashParamsFromUrl }
