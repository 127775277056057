import { isDev, isProd, PAGES } from '../../config'

/** App default locale */
const APP_DEFAULT_LOCALE = process.env.APP_DEFAULT_LOCALE || 'en-us'

const supportedLanguages = {
  en: { id: 'en', name: 'English' },
  fr: { id: 'fr', name: 'Français' },
}

const isSupportedLanguage = (language) => {
  return (
    !!supportedLanguages[language] ||
    !!supportedLanguages[language?.split(/[-_]/)[0]]
  )
}

/**
 * Return the language part from the specified language (en-US) if it is not supported
 */
const parseLanguage = (language) => {
  if (!language) return language

  const locale = supportedLanguages[language]?.id

  if (locale) return locale

  return supportedLanguages[language?.split(/[-_]/)[0]]?.id
}

const fallbackLanguage = parseLanguage(APP_DEFAULT_LOCALE)

/**
 * Dynamically loads only the translations that the user needs on the specified page
 * @param {string} language language to load.
 * @param {string} page the page which translations will be loaded
 */
const fetchTranslations = async (language, page = PAGES[0].key) => {
  try {
    const translations = [
      import(`../../i18n/${language}/common.json`).catch(() =>
        import(`../../i18n/${fallbackLanguage}/common.json`).catch(() => null)
      ),
      import(`../../i18n/${language}/errors.json`).catch(() =>
        import(`../../i18n/${fallbackLanguage}/errors.json`).catch(() => null)
      ),
      import(`../../i18n/${language}/${page}.json`).catch(() => {
        if (isDev) {
          if (!isProd) console.error('load fallback translations:', { page })
        }

        return import(`../../i18n/${fallbackLanguage}/${page}.json`).catch(
          () => null
        )
      }),
    ]

    const modules = await Promise.all(translations)

    return { ...modules[0]?.default, ...modules[1]?.default }
  } catch (error) {
    if (!isProd) console.error(error)

    return {}
  }
}

/**
 * Get the Navigator language
 */
const getBrowserLanguage = () => {
  try {
    if (typeof window !== 'undefined') {
      const navLanguage =
        navigator.language ||
        navigator.userLanguage ||
        (navigator.languages && navigator.languages[0])

      return navLanguage || APP_DEFAULT_LOCALE
    }

    return APP_DEFAULT_LOCALE
  } catch (e) {
    if (!isProd) console.error(e)

    return APP_DEFAULT_LOCALE
  }
}

export {
  getBrowserLanguage,
  isSupportedLanguage,
  fetchTranslations,
  APP_DEFAULT_LOCALE,
  parseLanguage,
  supportedLanguages,
}
