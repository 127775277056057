import { DEFAULT_PAGE_SIZE } from '../../config'
import ApiClient from '../apiClient'
import { getUserId } from '../user'

const client = new ApiClient()

const getCompanyOfficers = ({
  userId = getUserId(),
  exclude_auditors = 0,
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/company-officers`, {
    params: { page, per_page, exclude_auditors },
  })
}

const getCompanyOfficer = ({ userId = getUserId(), officerId }) => {
  return client.get(`/users/${userId}/company-officers/${officerId}`)
}

const getOfficers = ({ birth_date, first_name, last_name }) => {
  return client.get(`/officers`, {
    params: { birth_date, first_name, last_name },
  })
}

const getOfficer = ({ userId = getUserId(), officerId }) => {
  return client.get(`/users/${userId}/company-officers/${officerId}`)
}

const officerService = {
  getCompanyOfficers,
  getOfficer,
  getOfficers,
  getCompanyOfficer,
}

export default officerService
