import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import { Logout } from '@mui/icons-material'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { clientInfoSelector, getClientInfo } from '../../redux'
import { useAuth, useI18n } from '../../context'
import UserIcon from '../icons/UserIcon'
import { UserAvatarRoot } from './Appbar.style'
import LanguageMenu from './LanguageMenu'

const UserAvatarMenu = () => {
  const user = useSelector(clientInfoSelector)
  const { logout } = useAuth()
  const { t } = useI18n()

  const dispatch = useDispatch()

  const userName = user?.name
  const userPhoto = user?.photo

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout()
    handleClose()
  }

  useEffect(() => {
    dispatch(getClientInfo({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box display="flex" alignItems="center">
        <Avatar
          src={userPhoto}
          alt={userName}
          id="user-avatar"
          aria-controls={open ? 'user-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <UserIcon />
        </Avatar>
        <LanguageMenu />
      </Box>

      <UserAvatarRoot
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ minWidth: '250px' }}
        keepMounted
        MenuListProps={{
          'aria-labelledby': 'user-avatar',
        }}
      >
        <Box display="flex" flexDirection="column" p="0px 16px" pt={0.5}>
          <Typography fontWeight="500" variant="body2">
            {userName}
          </Typography>

          <Typography color="text.secondary" variant="inherit">
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ mt: 1, mb: 0.5 }} />

        <MenuItem onClick={handleLogout} className="MuiTypography-body1">
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText>{t('common.logout')}</ListItemText>
        </MenuItem>
      </UserAvatarRoot>
    </>
  )
}

export default UserAvatarMenu
