import styled from 'styled-components'
import { BaseCard } from '../../../components'

export const DataSyncTableRoot = styled(BaseCard)`
  margin-bottom: 24px;
  height: fit-content;

  .right {
    > svg {
      font-size: 17px;
    }

    button.active {
      background: rgba(196, 196, 196, 0.3);
    }

    button {
      min-width: 0;
    }
  }

  .Insight {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    width: fit-content;
    padding: 2px 16px;
  }
`
