import React from 'react'
import { Button, Stack } from '@mui/material'

import { useI18n } from '../../../context'

const ConnexionActions = ({
  onOk,
  color = 'primary',
  okText,
  loading,
  onClose,
  cancelText,
  type,
  disabled,
  ...other
}) => {
  const { t } = useI18n()

  return (
    <Stack
      className="ConnexionActions"
      mt={2.5}
      direction="row"
      justifyContent="flex-end"
      gap={1}
      {...other}
    >
      <Button
        variant="text"
        onClick={onClose}
        color="inherit"
        sx={{
          fontSize: '12px !important',
          fontWeight: 500,
          lineHeight: '18px',
          minHeight: '36px',
          background: '#f4f4f4',
          '&:hover': { background: ' #deddde' },
        }}
      >
        {cancelText || t('common.Cancel')}
      </Button>

      <Button
        variant="contained"
        onClick={onOk}
        loading={loading}
        color={color}
        disableElevation
        disabled={disabled}
        type={type}
        sx={{
          fontWeight: 500,
          lineHeight: '18px',
          minHeight: '36px',
          fontSize: '12px !important',
        }}
      >
        {okText}
      </Button>
    </Stack>
  )
}

export default ConnexionActions
