import Stack from '@mui/material/Stack'
import React from 'react'

import { ChevronRightIcon } from '../icons'
import { NavItemRoot } from './NavIem.style'

const NavItem = ({ item, className = '', compact, expand, ...other }) => {
  const { icon: Icon, name, left, right, variant } = item

  const compactOnly = variant === 'compact'
  const defaultOnly = !Icon || variant === 'default'

  const expandable = typeof expand === 'boolean'

  const hide = (compactOnly && !compact) || (defaultOnly && compact)

  const compactClass = compact ? 'compact' : ''
  const hideClass = hide ? 'hide' : ''
  const childClass = item.parent ? 'SubNavItem' : ''

  return (
    <NavItemRoot
      {...other}
      className={`NavItem ${childClass} ${className} ${compactClass} ${hideClass}`}
      title={compact ? name : undefined}
    >
      <div className={`left${Icon ? ' has-icon' : ''}`}>
        {Icon && <Icon />}
        <span className="itemText">
          {name}
          {left}
        </span>
      </div>

      <Stack direction="row" spacing={1}>
        {right && <div className="right itemIcon">{right}</div>}

        {expandable && (
          <ChevronRightIcon
            fontSize="small"
            className={`itemIcon chevron ${
              !expand ? 'collapseIcon' : 'expandIcon'
            }`}
          />
        )}
      </Stack>
    </NavItemRoot>
  )
}

export default NavItem
