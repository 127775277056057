import { useEffect, useRef } from 'react'

const noop = () => {}

const stopCb = () => false

const useInterval = ({
  callback,
  interval,
  immediate,
  stop,
  start = true,
  reset,
}) => {
  const savedCallback = useRef(noop)
  const stopCallback = useRef(stopCb)

  const count = useRef(0)

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback

    stopCallback.current = () =>
      stop === true || stop === false ? stop : stop?.() || false
  })

  // Execute callback if immediate is set.
  useEffect(() => {
    if (!immediate) return
    if (interval == null || interval === false) return
    savedCallback.current(count.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [immediate])

  // Set up the interval.
  useEffect(() => {
    let id

    if (interval == null || interval === false || !start) {
      clearInterval(id)
      count.current = 0

      return undefined
    }

    const tick = () => {
      if (!stopCallback.current()) {
        savedCallback.current(count.current)
        count.current += 1
      } else {
        clearInterval(id)
        count.current = 0
      }
    }

    id = setInterval(tick, interval)

    return () => clearInterval(id)
  }, [interval, start, reset])
}

export { useInterval }
