import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import banqueDeFranceService from '../../services/banqueDeFrance'

const initialState = {
  payments: null,
  finances: null,
  rating: null,
}

export const getPayments = createAsyncThunk(
  'banqueDeFrance/getPayments',
  banqueDeFranceService.getPayments
)

export const getFinances = createAsyncThunk(
  'banqueDeFrance/getFinances',
  banqueDeFranceService.getFinances
)

export const getRating = createAsyncThunk(
  'banqueDeFrance/getRating',
  banqueDeFranceService.getRating
)

const banqueDeFrance = createSlice({
  name: 'banqueDeFrance',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getPayments.pending]: (state) => {
      state.payments = null
    },
    [getPayments.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.payments = data
    },
    [getFinances.pending]: (state) => {
      state.finances = null
    },
    [getFinances.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.finances = data
    },
    [getRating.pending]: (state) => {
      state.rating = null
    },
    [getRating.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.rating = data
    },
  },
})

export default banqueDeFrance.reducer
