import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import React from 'react'

import Scrollbar from '../../../components/Scrollbar'
import { useAuth, useI18n } from '../../../context'
import ProviderItem from '../ProviderItem'
import { ProvidersRoot } from './ProviderSearch.style'
import {
  NATURAL,
  excludeCategoriesForNatural,
  excludeProvidersForNatural,
} from '../../../config'

const ProviderSearchResult = ({
  onSelect,
  values,
  onCategorySelect,
  data,
  open,
}) => {
  const { t } = useI18n()
  const { currentUser } = useAuth()

  return (
    <Scrollbar
      suppressScrollX
      update={data.length}
      updateAfter={open && data.length > 2 ? 400 : undefined}
      visiblility="always"
      background="transparent"
      size="small"
      variant="square"
      offset={false}
      sx={{ flexGrow: 1 }}
    >
      <ProvidersRoot>
        {!data.length && (
          <Box display="flex" gap={0.5}>
            <Typography>{t('common.No result found')}</Typography>

            {values.category && values.search && (
              <Typography
                color="primary"
                sx={{ cursor: 'pointer' }}
                onClick={onCategorySelect('')}
              >
                {t('common.searchInAllCategories')}
              </Typography>
            )}
          </Box>
        )}

        {data
          .filter(
            (provider) =>
              !(
                currentUser?.type === NATURAL &&
                (excludeCategoriesForNatural.includes(provider.category) ||
                  excludeProvidersForNatural.includes(provider.name))
              )
          )
          .map((provider) => (
            <ProviderItem
              key={provider.name}
              onSelect={onSelect}
              provider={provider}
            />
          ))}
      </ProvidersRoot>
    </Scrollbar>
  )
}

export default ProviderSearchResult
