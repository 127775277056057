import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const UserIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 25 31">
      <path d="M12.4 20.421c-6.69 0-12.4 1.054-12.4 5.27C0 29.907 5.675 31 12.4 31c6.685 0 12.4-1.052 12.4-5.27s-5.682-5.309-12.4-5.309Z" />
      <path
        opacity={0.4}
        d="M12.396 16.405a8.203 8.203 0 1 0-8.203-8.202 8.173 8.173 0 0 0 8.203 8.202"
      />
    </SvgIcon>
  )
}

export default UserIcon
