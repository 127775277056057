/* eslint-disable global-require */
import { configureStore } from '@reduxjs/toolkit'

import { isProd } from '../config'
import rootReducer from './reducers'

const middleware = [
  !isProd &&
    process.env.REDUX_LOG !== 'false' &&
    require('redux-logger').default,
].filter(Boolean)

const store = configureStore({
  reducer: rootReducer,
  devTools: !isProd,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({}).concat(middleware)
  },
})

export default store
