import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import complyCubeService from '../../services/complyCube'

const initialState = {
  conformityCheck: null,
}

export const getConformityCheck = createAsyncThunk(
  'conformityCheck/getConformityCheck',
  complyCubeService.getConformityCheck
)

const complyCube = createSlice({
  name: 'complyCube',
  initialState,
  reducers: {},
  extraReducers: {
    [getConformityCheck.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.conformityCheck = data
    },
  },
})

export default complyCube.reducer
