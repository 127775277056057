import AddIcon from '@mui/icons-material/Add'
import Fab from '@mui/material/Fab'
import React from 'react'

import AddUser from '../AddUser/AddUser'
import SearchIcon from '../../../components/icons/SearchIcon'
import AutocompleteDialog from '../../../components/Autocomplete/AutocompleteDialog'

const AddUserFab = () => {
  const [open, setOpen] = React.useState(false)
  const [openSearch, setOpenSearch] = React.useState(false)

  const handleOpen = (v) => () => {
    setOpen(v)
  }

  const handleOpenSearch = (v) => () => {
    setOpenSearch(v)
  }

  return (
    <>
      <Fab
        color="secondary"
        aria-label="add"
        sx={{ position: 'fixed', bottom: '16px', right: '16px', zIndex: 1000 }}
        onClick={handleOpen(true)}
      >
        <AddIcon />
      </Fab>
      <Fab
        color="primary"
        aria-label="search"
        sx={{ position: 'fixed', bottom: '16px', right: '96px', zIndex: 1000 }}
        onClick={handleOpenSearch(true)}
      >
        <SearchIcon />
      </Fab>

      <AutocompleteDialog open={openSearch} onClose={handleOpenSearch(false)} />
      <AddUser open={open} onClose={handleOpen(false)} />
    </>
  )
}

export default AddUserFab
