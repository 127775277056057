import Box from '@mui/material/Box'
import styled from 'styled-components'

export const TableRoot = styled(Box)`
  width: 100%;

  .MuiTable-root {
    min-width: 800px;
  }

  table {
    th {
      background-color: #f4f4f4;
    }

    thead tr {
      pointer-events: bounding-box;
    }

    td div {
      user-select: text !important;
    }

    .MuiTableCell-root {
      font-size: inherit;
      padding: 10px;
    }
  }
`
