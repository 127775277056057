import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CloseIcon from '@mui/icons-material/Close'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import { IconButton, Stack } from '@mui/material'
import React from 'react'

const UserFormHeader = ({ onClose, onBack, icon }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      marginRight="-12px"
      className="Header"
    >
      <IconButton aria-label="back" onClick={onBack}>
        {onBack && <ArrowBackIosIcon />}
      </IconButton>

      {icon || <PersonAddAlt1Icon />}

      <IconButton aria-label="close" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Stack>
  )
}

export default UserFormHeader
