import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const DeleteIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 21">
      <g opacity={0.5}>
        <path d="M1.193 7.734A2 2 0 0 1 3.179 5.5h10.524a2 2 0 0 1 1.987 2.234l-1.296 11a2 2 0 0 1-1.987 1.766H4.475a2 2 0 0 1-1.986-1.766l-1.296-11Z" />
        <rect x={3.82} y={0.305} width={9.245} height={3.462} rx={1.731} />
      </g>
    </SvgIcon>
  )
}

export default DeleteIcon
