import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ClockIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 13 13">
      <g clipPath="url(#clip0_317_12)">
        <path
          d="M6.5 0C5.21442 0 3.95772 0.381218 2.8888 1.09545C1.81988 1.80968 0.986756 2.82484 0.494786 4.01256C0.00281636 5.20028 -0.125905 6.50721 0.124899 7.76809C0.375703 9.02896 0.994768 10.1872 1.90381 11.0962C2.81285 12.0052 3.97104 12.6243 5.23192 12.8751C6.49279 13.1259 7.79973 12.9972 8.98745 12.5052C10.1752 12.0132 11.1903 11.1801 11.9046 10.1112C12.6188 9.04229 13 7.78558 13 6.5C12.9981 4.77667 12.3127 3.12445 11.0941 1.90586C9.87556 0.687282 8.22334 0.00186392 6.5 0V0ZM7.04167 6.43446C7.04173 6.52628 7.01844 6.6166 6.97401 6.69695C6.92957 6.7773 6.86543 6.84504 6.78763 6.89379L4.70763 8.19379C4.64717 8.23156 4.57986 8.25706 4.50954 8.26881C4.43923 8.28057 4.36728 8.27837 4.29782 8.26232C4.22836 8.24628 4.16274 8.21671 4.1047 8.1753C4.04667 8.13389 3.99736 8.08146 3.95959 8.021C3.92181 7.96054 3.89632 7.89323 3.88456 7.82291C3.87281 7.7526 3.87501 7.68066 3.89106 7.61119C3.9071 7.54173 3.93667 7.47611 3.97808 7.41807C4.01948 7.36004 4.07192 7.31073 4.13238 7.27296L5.95834 6.13438V3.79167C5.95834 3.64801 6.01541 3.51023 6.11699 3.40865C6.21857 3.30707 6.35634 3.25 6.5 3.25C6.64366 3.25 6.78144 3.30707 6.88302 3.40865C6.9846 3.51023 7.04167 3.64801 7.04167 3.79167V6.43446Z"
          fill="#7E8299"
        />
      </g>
      <defs>
        <clipPath id="clip0_317_12">
          <rect width="13" height="13" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default ClockIcon
