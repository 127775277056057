import ApiClient from '../apiClient'
import { getUserId } from '../user'

const client = new ApiClient()

const getWebInfos = ({ userId = getUserId() }) => {
  return client.get(`/users/${userId}/web-infos`)
}

const webInfoService = {
  getWebInfos,
}

export default webInfoService
