import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { PAGES } from '../../config'
import {
  APP_DEFAULT_LOCALE,
  fetchTranslations,
  getBrowserLanguage,
  parseLanguage,
} from './i18nHelpers'

function useI18nProvider(initialLocale) {
  const fallbackLanguage = parseLanguage(APP_DEFAULT_LOCALE)

  const [currentLanguage, setCurrentLanguage] = useState(() => {
    let lang = initialLocale && parseLanguage(initialLocale)

    if (lang) return lang

    if (typeof localStorage !== 'undefined') {
      lang = parseLanguage(localStorage.getItem('language'))

      if (lang) return lang
    }

    lang = parseLanguage(getBrowserLanguage())

    if (lang) return lang

    return fallbackLanguage
  })

  const { pathname } = useLocation()
  const loadedPages = useRef()

  const [translations, setTranslations] = useState({})

  const changeLanguage = async (language) => {
    const lang = parseLanguage(language)

    if (!lang) return

    const translationsLoaded =
      !!translations[lang] && Object.keys(translations[lang]).length

    const page = PAGES.find((p) => p.pathname === pathname) || PAGES[0]
    const pageId = `${page.key}.${lang}`
    const pageAlreadyLoaded = !!loadedPages.current?.[pageId]

    if (translationsLoaded && lang === currentLanguage && pageAlreadyLoaded) {
      return
    }

    // Sure it is the first time we load the translations of this page in this language
    // So we Mark page as alreadry loaded
    loadedPages.current = { ...loadedPages.current, [pageId]: true }

    const newTranslations = await fetchTranslations(lang, page.key)

    setTranslations((previousTranslations) => ({
      ...previousTranslations,
      [lang]: { ...previousTranslations[lang], ...newTranslations },
    }))

    localStorage.setItem('language', lang)
    setCurrentLanguage(lang)
  }

  useEffect(() => {
    document.documentElement.lang = currentLanguage
  }, [currentLanguage])

  useEffect(() => {
    changeLanguage(currentLanguage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const fallbackTranslation = translations[fallbackLanguage]
  const translation = translations[currentLanguage] || fallbackTranslation

  return {
    currentLanguage,
    setCurrentLanguage: changeLanguage,
    translations,
    fallbackLanguage,
    translation,
    fallbackTranslation,
  }
}

export default useI18nProvider
