import React, { useState } from 'react'

function createInputComponent({ multiple, accept }) {
  const el = document.createElement('input')
  // set input config

  el.type = 'file'
  el.accept = accept
  el.multiple = multiple

  // return file input element
  return el
}

export const useLocalFileUpload = () => {
  const [files, setFiles] = useState(null)

  let userCallback = () => ''

  // Handle onChange event
  const onChange = async (e) => {
    const { target } = e
    const parsedFiles = target.files || []

    // remove event listener after operation
    target.removeEventListener('change', onChange)

    // remove input element after operation
    target.remove()

    // update files state hook

    if (target.multiple) {
      setFiles(parsedFiles)

      return userCallback(parsedFiles)
    }

    setFiles(parsedFiles[0])

    return userCallback(parsedFiles[0])
  }

  // Handle upload
  const uploadFile = (
    { accept, multiple } = { accept: '', multiple: false },
    cb = () => ''
  ) => {
    if (typeof cb === 'function') {
      userCallback = cb
    }
    // create virtual input element
    const inputEL = createInputComponent({ multiple, accept })

    // add event listener
    inputEL.addEventListener('change', onChange)
    inputEL.click()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => [files, uploadFile], [files])
}
