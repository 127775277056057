import Box from '@mui/material/Box'
import styled from 'styled-components'

export const SyncDataTypesResult = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  height: 250px;

  .Title {
    font-weight: 500 !important;
    color: #7e8299 !important;

    .MuiFormControlLabel-label {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .MuiFormControlLabel-labelPlacementEnd {
    color: black;
  }
`
