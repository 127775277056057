import Box from '@mui/material/Box'
import styled from 'styled-components'

export const DeleteConnexionRoot = styled(Box)`
  padding: 16px 20px;
  max-width: 365px;

  h6 {
    font-weight: 600;
    font-size: 16px;
  }

  > p {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }

  b {
    font-weight: 600;
  }

  .MuiAlert-root {
    color: #912741;
    background: #fad9e2;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    align-items: center;
    padding-left: 13px;
    padding-right: 13px;

    p {
      font-size: 11px;
    }
  }

  .MuiButton-contained {
    :not(:hover) {
      background: #f14e6d;
    }
  }
`
