import styled from 'styled-components'
import Collapse from '@mui/material/Collapse'

export const AlertCollapse = styled(Collapse)`
  .MuiAlert-root {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100000;
  }
`
