import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const UncheckIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 15 15">
      <rect width={15} height={15} rx={3} fill="#D9D9D9" />
    </SvgIcon>
  )
}

export default UncheckIcon
