import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const TimelineIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 31 31">
      <g clipPath="url(#a)">
        <path d="M15.5 0A15.464 15.464 0 0 0 5.167 3.97V1.291a1.292 1.292 0 0 0-2.584 0v3.875a3.875 3.875 0 0 0 3.875 3.875h3.875a1.292 1.292 0 0 0 0-2.584H6.458a1.102 1.102 0 0 1-.129-.027A12.9 12.9 0 1 1 2.583 15.5 1.291 1.291 0 1 0 0 15.5 15.5 15.5 0 1 0 15.5 0Z" />
        <path d="M15.503 7.75a1.291 1.291 0 0 0-1.292 1.292V15.5c0 .342.136.671.378.913l3.875 3.875a1.291 1.291 0 0 0 1.827-1.826l-3.497-3.497V9.042a1.292 1.292 0 0 0-1.291-1.292Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h31v31H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default TimelineIcon
