/* eslint-disable max-lines */
import React from 'react'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import AccountTreeIcon from '@mui/icons-material/AccountTree'

import { Archive, FileOpenTwoTone, Gavel, People } from '@mui/icons-material'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import DataConnexionIcon from '../../components/icons/DataConnexionIcon'
import DashBoardIcon from '../../components/icons/DashBoardIcon'
import UserGroupIcon from '../../components/icons/UserGroupIcon'
import BankingIcon from '../../components/icons/BankingIcon'
import CommerceIcon from '../../components/icons/CommerceIcon'
import DataSyncIcon from '../../components/icons/DataSyncIcon'
import SettingsIcon from '../../components/icons/SettingsIcon'
import InstitutionalIcon from '../../components/icons/InstitutionalIcon'
import TimelineIcon from '../../components/icons/TimelineIcon'
import CompanyIcon from '../../components/icons/CompanyIcon'
import ChartIcon from '../../components/icons/ChartIcon'
import { getUserId } from '../../services/user'
import { fromLS } from '../../utils'
import CompanyStatus from '../../components/CompanyStatus'
import SidebarBottom from '../../components/SidebarBottom'
import BankAccountIcon from '../../components/icons/BankAccountIcon'
import CreditCardIcon from '../../components/icons/CreditCardIcon'
import TransactionIcon from '../../components/icons/TransactionIcon'
import SubscriptionIcon from '../../components/icons/SubscriptionIcon'
import DisputeIcon from '../../components/icons/DisputeIcon'
import PayoutIcon from '../../components/icons/PayoutIcon'
import FinancialAnalysisIcon from '../../components/icons/FinancialAnalysisIcon'
import CustomersIcon from '../../components/icons/CustomersIcon'
import ProductIcon from '../../components/icons/ProductIcon'
import OrderIcon from '../../components/icons/OrderIcon'
import { LEGAL, providerCategories } from '../../config'
import { useAuth } from '../AuthProvider'
import BuildingIcon from '../../components/icons/BuildingIcon'
import TrademarkIcon from '../../components/icons/TrademarkIcon'

const BuildNavigations = ({ t }) => {
  const { currentUser } = useAuth()

  const institutional =
    currentUser?.type === LEGAL
      ? [
          {
            name: t('common.Timeline'),
            path: '/user/:userId/institutional/timeline',
            requires: ['COMPANY'],
            url: () => `/user/${getUserId()}/institutional/timeline`,
            icon: TimelineIcon,
            dataTypes: ['TIMELINE'],
          },
          {
            name: t('common.company_officers'),
            path: '/user/:userId/institutional/company-officers',
            requires: ['COMPANY'],
            url: () => `/user/${getUserId()}/institutional/company-officers`,
            icon: People,
            dataTypes: ['COMPANY_OFFICER', 'CONFORMITY_CHECK'],
          },
          {
            name: t('common.company_offices'),
            path: '/user/:userId/institutional/company-offices',
            requires: ['COMPANY'],
            url: () => `/user/${getUserId()}/institutional/company-offices`,
            icon: BuildingIcon,
            dataTypes: ['CORPORATE_OFFICE'],
          },
          {
            name: t('common.acts'),
            path: '/user/:userId/institutional/acts',
            requires: ['COMPANY'],
            url: () => `/user/${getUserId()}/institutional/acts`,
            icon: FileOpenTwoTone,
            dataTypes: ['ACT'],
          },
          {
            name: t('common.court_decisions'),
            path: '/user/:userId/institutional/decisions',
            requires: ['COMPANY'],
            url: () => `/user/${getUserId()}/institutional/decisions`,
            icon: Gavel,
            dataTypes: ['COURT_DECISION'],
          },
          {
            name: t('common.collective_procedures'),
            path: '/user/:userId/institutional/collective-procedures',
            requires: ['COMPANY'],
            url: () =>
              `/user/${getUserId()}/institutional/collective-procedures`,
            icon: Archive,
            dataTypes: ['COLLECTIVE_PROCEDURE'],
          },
          {
            name: t('common.trademarks'),
            path: '/user/:userId/institutional/trademarks',
            requires: ['COMPANY'],
            url: () => `/user/${getUserId()}/institutional/trademarks`,
            icon: TrademarkIcon,
            dataTypes: ['TRADEMARK'],
          },
        ]
      : []

  const backgroundCheck = {
    name: t('common.Check background'),
    path: '/user/:userId/institutional/bg-check',
    requires: ['COMPANY'],
    url: () => `/user/${getUserId()}/institutional/bg-check`,
    icon: AccountTreeIcon,
    dataTypes: ['BACKGROUND_CHECK', 'BACKGROUND_CHECK_V2', 'CONFORMITY_CHECK'],
  }

  const overview = {
    name: t('common.Overview'),
    path: '/user/:userId/institutional',
    requires: ['COMPANY'],
    url: () => `/user/${getUserId()}/institutional`,
    dataTypes: [
      'COLLECTIVE_PROCEDURE',
      'COURT_DECISION',
      'BENEFICIAL_OWNER',
      'ACT',
      'COMPANY_PROFILE',
      'COMPANY_OFFICER',
      'CORPORATE_OFFICE',
      'REGISTRATION_PROOF',
      'ARTICLES_OF_ASSOCIATION',
      'AVIS_SIREN',
      'BANKING_RELATIONSHIP',
      'TRADEMARK',
      'BDF_PAYMENT_INCIDENT',
      'CONFORMITY_CHECK',
    ],
  }

  institutional.unshift(backgroundCheck)

  if (currentUser?.type === LEGAL) {
    institutional.unshift(overview)
  }

  return [
    {
      name: t('common.Home'),
      path: '/',
      icon: DashBoardIcon,
      requires: ['DASHBOARD', 'COMPANY'],
    },
    {
      name: t('common.Users'),
      path: '/users',
      icon: UserGroupIcon,
      requires: ['DASHBOARD', 'COMPANY'],
    },
    {
      name: t('common.Settings'),
      path: '/settings',
      icon: SettingsIcon,
      requires: ['DASHBOARD', 'COMPANY'],
    },
    {
      name:
        currentUser?.type === LEGAL
          ? t('common.the_company')
          : t('common.natural_user'),
      path: '/user/:userId/company',
      url: () => `/user/${getUserId()}/company`,
      icon: CompanyIcon,
      left: <CompanyStatus />,
      display: false,
    },
    {
      name: t('common.Institutional'),
      category: providerCategories.Institutional.category,
      icon: InstitutionalIcon,
      requires: ['COMPANY'],
      path: '/user/:userId/institutional',
      url: () => `/user/${getUserId()}/institutional`,
      children: institutional,
    },
    {
      name: t('common.Financial'),
      category: providerCategories.Financial.category,
      path: '/user/:userId/financial',
      icon: ChartIcon,
      requires: ['COMPANY'],
      url: () => `/user/${getUserId()}/financial`,
      children: [
        {
          name: t('common.Overview'),
          path: '/user/:userId/financial',
          requires: ['COMPANY'],
          url: () => `/user/${getUserId()}/financial`,
          providers: ['company_legal_fr', 'impots_gouv'],
          dataTypes: [
            'FINANCIAL_STATEMENT',
            'TAX_RETURN',
            'VAT_DECLARATION',
            'CORPORATE_TAX',
            'TAX_RETURN_ANALYSIS',
            'TAX_CLEARANCE_CERTIFICATE',
          ],
        },
        {
          name: t('common.Analyse financière'),
          path: '/user/:userId/financial/analysis',
          requires: ['COMPANY'],
          icon: FinancialAnalysisIcon,
          url: () => `/user/${getUserId()}/financial/analysis`,
          dataTypes: ['TAX_RETURN', 'TAX_RETURN_ANALYSIS', 'SECTOR_ANALYSIS'],
        },
      ],
    },
    {
      name: t('common.Banking'),
      category: providerCategories.Banking.category,
      path: '/user/:userId/banking',
      icon: BankingIcon,
      requires: ['COMPANY'],
      url: () => `/user/${getUserId()}/banking`,
      children: [
        {
          name: t('common.Overview'),
          path: '/user/:userId/banking',
          requires: ['COMPANY'],
          url: () => `/user/${getUserId()}/banking`,
          providers: ['budget_insight'],
          dataTypes: [
            'BANK_ACCOUNT',
            'BANK_TRANSACTION',
            'CASHFLOW',
            'CREDIT_INSIGHTS',
          ],
        },
        {
          name: t('common.Bank account'),
          path: '/user/:userId/banking/account',
          requires: ['COMPANY'],
          icon: BankAccountIcon,
          url: () => `/user/${getUserId()}/banking/account`,
          dataTypes: ['BANK_ACCOUNT', 'BANK_TRANSACTION'],
        },
        {
          name: t('common.Credit insight'),
          path: '/user/:userId/banking/credit-insight',
          requires: ['COMPANY'],
          icon: CreditCardIcon,
          url: () => `/user/${getUserId()}/banking/credit-insight`,
          dataTypes: ['CREDIT_INSIGHTS'],
        },
      ],
    },
    {
      name: t('common.Commerce'),
      category: providerCategories.Commerce.category,
      path: '/user/:userId/commerce',
      icon: CommerceIcon,
      requires: ['COMPANY'],
      url: () => `/user/${getUserId()}/commerce`,
      children: [
        {
          name: t('common.Overview'),
          path: '/user/:userId/commerce',
          requires: ['COMPANY'],
          url: () => `/user/${getUserId()}/commerce`,
          providers: [
            'stripe',
            'paypal',
            'square',
            'gocardless',
            'shopify',
            'woocommerce',
            'prestashop',
          ],
          dataTypes: [
            'COMMERCE_TRANSACTION',
            'COMMERCE_DISPUTE',
            'COMMERCE_BALANCE',
            'COMMERCE_SUBSCRIPTION',
            'COMMERCE_TRANSACTION_INSIGHT',
            'COMMERCE_SUBSCRIPTION_INSIGHT',
            'COMMERCE_DISPUTE_INSIGHT',
            'COMMERCE_CUSTOMER',
            'COMMERCE_LOCATION',
            'COMMERCE_PRODUCT',
            'COMMERCE_ORDER',
          ],
        },
        {
          name: t('common.Customers'),
          path: '/user/:userId/commerce/customers',
          requires: ['COMPANY'],
          icon: CustomersIcon,
          url: () => `/user/${getUserId()}/commerce/customers`,
          dataTypes: ['COMMERCE_CUSTOMER'],
        },
        {
          name: t('common.Transactions'),
          path: '/user/:userId/commerce/transactions',
          requires: ['COMPANY'],
          icon: TransactionIcon,
          url: () => `/user/${getUserId()}/commerce/transactions`,
          dataTypes: ['COMMERCE_TRANSACTION', 'COMMERCE_TRANSACTION_INSIGHT'],
        },
        {
          name: t('common.Subscriptions'),
          path: '/user/:userId/commerce/subscriptions',
          requires: ['COMPANY'],
          icon: SubscriptionIcon,
          url: () => `/user/${getUserId()}/commerce/subscriptions`,
          dataTypes: ['COMMERCE_SUBSCRIPTION', 'COMMERCE_SUBSCRIPTION_INSIGHT'],
        },
        {
          name: t('common.Disputes'),
          path: '/user/:userId/commerce/disputes',
          requires: ['COMPANY'],
          icon: DisputeIcon,
          url: () => `/user/${getUserId()}/commerce/disputes`,
          dataTypes: ['COMMERCE_DISPUTE', 'COMMERCE_DISPUTE_INSIGHT'],
        },
        {
          name: t('common.Payouts'),
          path: '/user/:userId/commerce/payouts',
          requires: ['COMPANY'],
          icon: PayoutIcon,
          url: () => `/user/${getUserId()}/commerce/payouts`,
          dataTypes: ['COMMERCE_TRANSACTION', 'COMMERCE_TRANSACTION_INSIGHT'],
        },
        {
          name: t('common.Products'),
          path: '/user/:userId/commerce/products',
          requires: ['COMPANY'],
          icon: ProductIcon,
          url: () => `/user/${getUserId()}/commerce/products`,
          dataTypes: ['COMMERCE_PRODUCT'],
        },
        {
          name: t('common.Orders'),
          path: '/user/:userId/commerce/orders',
          requires: ['COMPANY'],
          icon: OrderIcon,
          url: () => `/user/${getUserId()}/commerce/orders`,
          dataTypes: ['COMMERCE_ORDER'],
        },
      ],
    },
    {
      name: t('common.News'),
      category: providerCategories.E_Reputation.category,
      path: '/user/:userId/google-news',
      icon: NewspaperIcon,
      requires: ['COMPANY'],
      dataTypes: ['NEWS'],
      url: () => `/user/${getUserId()}/google-news`,
      title: () => {
        return `${t('common.A la une sur')} ${
          fromLS('company', true)?.name || ''
        }`
      },
    },
    {
      name: t('common.providers.bdf'),
      category: providerCategories.Bdf.category,
      path: '/user/:userId/bdf',
      icon: AccountBalanceIcon,
      requires: ['COMPANY'],
      dataTypes: ['BDF_PAYMENT_INCIDENT', 'BDF_FINANCES', 'BDF_RATING'],
      url: () => `/user/${getUserId()}/bdf`,
    },
    {
      type: 'gutter',
      requires: ['COMPANY'],
      variant: 'default', // show only on large menu,
    },
    {
      name: 'settings',
      icon: SidebarBottom,
      type: 'custom',
      requires: ['COMPANY'],
    },
    {
      name: t('common.Data connections'),
      path: '/user/:userId/data-connections',
      requires: ['COMPANY'],
      icon: DataConnexionIcon,
      url: () => `/user/${getUserId()}/data-connections`,
      dataTypes: [],
      display: false,
    },
    {
      name: t('common.Data sync'),
      path: '/user/:userId/data-sync',
      requires: ['COMPANY'],
      icon: DataSyncIcon,
      url: () => `/user/${getUserId()}/data-sync`,
      dataTypes: [],
      display: false,
    },
  ]
}

export default BuildNavigations
