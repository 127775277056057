import styled from 'styled-components'
import Menu from '@mui/material/Menu'
import IconButton from '@mui/material/IconButton'

export const ConnectionMenuIcon = styled(IconButton)`
  border-radius: 5px;
  width: 30px;
  height: 30px;
  background: #f4f4f4;
  border: 1px solid #edeced;
  border-radius: 5px;

  &:hover,
  &.open {
    background: #deddde;
    border: 1px solid #c9c9ca;
    border-radius: 5px;
  }

  svg {
    font-size: 18px;
  }
`

export const ConnectionMenuRoot = styled(Menu)`
  .MuiMenu-paper {
    box-shadow: 0px 0px 50px rgba(82, 63, 105, 0.15);
    transform: translate(-14px, 5px) !important;
  }

  .MuiMenuItem-root {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #7e8299;
  }

  .MuiMenuItem-root:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: transparent;
  }
`
