import Box from '@mui/material/Box'
import styled from 'styled-components'

export const DataSyncAlertRoot = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  border: 1px solid;
  border-radius: 5px;
  margin-bottom: 20px;

  > svg {
    font-size: 20px;
    margin-top: 4px;
  }

  .Body {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #666666;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Title {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
  }

  .htmlContent {
    pointer-events: none;

    b {
      font-weight: 700;
      pointer-events: all !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .close {
    svg {
      font-size: 8px;
    }
  }

  .MuiButton-outlined {
    background: #ffffff;
    margin-top: 8px;
    font-weight: 600;
    padding-left: 12px;
    padding-right: 12px;

    svg {
      font-size: 16px;
    }
  }

  .Copy {
    > .ellipsis {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      color: #666666;
      max-width: calc(100% - 150px);
      padding: 6px 12px;
    }
  }

  .text-url {
    display: inline-flex;
    max-width: calc(100% - 35px) !important;
  }
  
  .btn-copy {
      text-wrap: nowrap;
  }  

  .unique-link-alert {
    padding: 3px 12px;

    svg {
      font-size: 1.2rem;
    }
  }

  .unique-link-button {
    color: #4195DA;
    border-color: #4195DA;
    background-color: #fff;
    margin-top: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    
    &:hover { border-color: #4195DA },
  }
`
