import { useSelector } from 'react-redux'

import {
  allDataConnectionsSelector,
  connectedDataConnectionsSelector,
} from '../../../redux/dataConnection'
import { providersByNameSelector } from '../../../redux/provider'

const useDataConnections = (dataTypes) => {
  const allDataConnections = useSelector(allDataConnectionsSelector)
  const data = useSelector(connectedDataConnectionsSelector)
  const dataConnections = JSON.parse(JSON.stringify(data))

  const providersByName = useSelector(providersByNameSelector)

  const allConnectedData = dataConnections
    .map((d) => ({ ...d, provider: providersByName[d.provider_name] }))
    .filter((d) => d.provider)

  const getConnectedFrom = (type = dataTypes) => {
    return allConnectedData
      .filter((d) => d.requested_data_types?.some((i) => type?.includes(i)))
      .filter((d) => d.provider)
  }

  const connected = !!getConnectedFrom()?.length

  const createdConnections = allDataConnections?.result?.filter((d) =>
    d.requested_data_types?.some((i) => dataTypes?.includes(i))
  )

  const connectedData = createdConnections?.filter(
    (s) => s.status === 'CONNECTED'
  )

  const connectedDataTypes = connectedData
    ?.map((c) => c.requested_data_types)
    .flat()

  const connectionToFinish = createdConnections?.filter(
    (s) => s.status === 'AUTH_REQUIRED' || s.status === 'CONNECTING'
  )

  const matchedConnectedDataTypes = dataTypes?.filter((d) =>
    connectedDataTypes?.includes(d)
  )

  return {
    connectedDataTypes,
    dataConnections,
    connectedData,
    connected,
    allConnectedData,
    getConnectedFrom,
    createdConnections,
    connectionToFinish,
    matchedConnectedDataTypes,
  }
}

export default useDataConnections
