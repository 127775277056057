import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const PayoutIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 13 14">
      <path d="M0.632478 8.03311L2.73394 10.1346C2.83612 10.2333 2.97296 10.2879 3.11501 10.2866C3.25706 10.2854 3.39294 10.2284 3.49338 10.128C3.59383 10.0275 3.6508 9.89165 3.65204 9.74961C3.65327 9.60756 3.59867 9.47071 3.49998 9.36854L1.39852 7.26707C1.33608 7.2034 1.28152 7.13247 1.23599 7.05579C1.24412 7.05579 1.25062 7.06012 1.25874 7.06012L9.75561 7.04279C9.89929 7.04279 10.0371 6.98571 10.1387 6.88411C10.2403 6.78251 10.2974 6.64472 10.2974 6.50103C10.2974 6.35735 10.2403 6.21955 10.1387 6.11796C10.0371 6.01636 9.89929 5.95928 9.75561 5.95928L1.262 5.97662C1.24683 5.97662 1.23437 5.9842 1.21974 5.98528C1.26779 5.89364 1.32873 5.80936 1.40068 5.73499L3.50215 3.63353C3.55389 3.58356 3.59516 3.52378 3.62355 3.45768C3.65195 3.39158 3.66689 3.3205 3.66752 3.24856C3.66814 3.17663 3.65444 3.10529 3.6272 3.03871C3.59996 2.97213 3.55973 2.91164 3.50886 2.86078C3.45799 2.80991 3.39751 2.76968 3.33093 2.74244C3.26435 2.7152 3.19301 2.7015 3.12108 2.70212C3.04914 2.70275 2.97805 2.71769 2.91196 2.74608C2.84586 2.77448 2.78608 2.81575 2.73611 2.86749L0.634644 4.96895C0.228392 5.37533 0.000173188 5.92642 0.000173238 6.50103C0.000173288 7.07565 0.228392 7.62674 0.634644 8.03311L0.632478 8.03311Z" />
      <path d="M9.20772 1.0835L10.2912 1.0835C10.7223 1.0835 11.1357 1.25473 11.4405 1.55952C11.7453 1.86432 11.9165 2.27771 11.9165 2.70876L11.9165 10.2933C11.9165 10.7244 11.7453 11.1377 11.4405 11.4425C11.1357 11.7473 10.7223 11.9186 10.2912 11.9186L9.20772 11.9186C9.06404 11.9186 8.92625 11.9756 8.82465 12.0772C8.72305 12.1788 8.66597 12.3166 8.66597 12.4603C8.66597 12.604 8.72305 12.7418 8.82465 12.8434C8.92625 12.945 9.06404 13.0021 9.20772 13.0021L10.2912 13.0021C11.0094 13.0012 11.6979 12.7156 12.2057 12.2077C12.7135 11.6999 12.9991 11.0115 13 10.2933L13 2.70876C12.9991 1.99061 12.7135 1.30212 12.2057 0.794317C11.6979 0.286511 11.0094 0.00084799 10.2912 -1.2161e-05L9.20772 -1.20662e-05C9.06404 -1.20537e-05 8.92624 0.0570654 8.82465 0.158663C8.72305 0.260262 8.66597 0.398059 8.66597 0.541742C8.66597 0.685423 8.72305 0.823222 8.82465 0.924819C8.92624 1.02642 9.06404 1.0835 9.20772 1.0835Z" />
    </SvgIcon>
  )
}

export default PayoutIcon
