import Card from '@mui/material/Card'
import styled from 'styled-components'

export const BaseCardRoot = styled(Card)`
  padding: 20px;
  border-radius: 5px;
  box-shadow: ${({ flat }) =>
    !flat ? '0px 4px 12px rgba(76, 87, 125, 0.02)' : 'none'};

  > .Header {
    h6 {
      font-size: 16px;
      color: ${({ theme }) => theme.palette.text.primary};
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-weight: 600;
    }

    .SubTitle {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 10px;
      line-height: 15px;
      color: #a1a5b7;
      gap: 5px;

      svg {
        font-size: 13px;
      }
    }
  }

  table {
    .MuiTableCell-root {
      padding: 8px 4px;
    }
  }

  .bt-see-more {
    color: rgb(80, 205, 137);
  }

  .bt-see-more:hover {
    background-color: rgb(232, 255, 243);
  }

  .link-inherit {
    color: inherit !important;
  }
`
