import React from 'react'
import Label from '../Label'

const colors = {
  ERROR: 'DANGER',
  PENDING: 'WARNING',
  SUCCESS: 'SUCCESS',
  ACTIVE: 'INFO',
}

const TransactionStatus = ({ status }) => {
  return <Label color={colors[status]}>{status}</Label>
}

export default TransactionStatus
