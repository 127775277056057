/* eslint-disable max-lines */
import Typography from '@mui/material/Typography'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import React, { useEffect, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers'

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useSelector } from 'react-redux'
import { Button } from '../../../components'
import { UserFormRoot } from './AddUser.style'
import { validateNaturalUser } from './AddUser.validation'
import UserFormHeader from './UserFormHeader'
import { useI18n } from '../../../context'
import { groupsSelector } from '../../../redux'

const NaturalUserForm = ({
  onSubmit,
  onClose,
  open,
  user,
  onBack,
  onUpdate,
  loading,
  serverErrors,
}) => {
  const groups = useSelector(groupsSelector)
  const filter = createFilterOptions()
  const listGroups = groups?.map((item) => ({ title: item, key: item })) ?? []

  const { t } = useI18n()
  const defaultVal = {
    first_name: '',
    last_name: '',
    birth_date: '',
    group: '',
  }
  const [values, setValues] = useState(defaultVal)
  const [errors, setErrors] = useState(defaultVal)

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
    setErrors({ ...errors, [e.target.name]: null })
  }

  const handleDateChange = (newVal) => {
    setValues({ ...values, birth_date: newVal })
    setErrors({ ...errors, birth_date: null })
  }

  const handleChangeGroup = (event, newValue) => {
    if (typeof newValue === 'string') {
      setValues({
        ...values,
        group: newValue,
      })
    } else if (newValue && newValue.inputValue) {
      setValues({
        ...values,
        group: newValue.inputValue,
      })
    } else {
      setValues({ ...values, group: newValue.title })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const err = validateNaturalUser(values, t)

    if (Object.keys(err).length) {
      setErrors({ ...errors, ...err })

      return onUpdate?.()
    }

    onSubmit({
      first_name: values.first_name,
      last_name: values.last_name,
      birth_date:
        typeof values.birth_date === 'object'
          ? values.birth_date.format('YYYY-MM-DD')
          : values.birth_date,
      group: values.group,
    })
  }

  useEffect(() => {
    if (!open) setValues(defaultVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (user) setValues({ ...defaultVal, ...user })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    setErrors({ ...serverErrors?.error_details })
    onUpdate?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverErrors?.error_details, open])

  return (
    <UserFormRoot onSubmit={handleSubmit}>
      <UserFormHeader onBack={onBack} onClose={onClose} />

      <Typography variant="button" className="subTitle" mb={1}>
        {t('common.Create natural user')}
      </Typography>

      <div>
        <FormLabel>{t('common.first_name')}</FormLabel>
        <TextField
          fullWidth
          id="first_name"
          margin="dense"
          name="first_name"
          value={values.first_name}
          onChange={handleChange}
          error={!!errors.first_name}
          helperText={errors.first_name}
          size="small"
        />
      </div>

      <div>
        <FormLabel>{t('common.last_name')}</FormLabel>
        <TextField
          fullWidth
          id="last_name"
          margin="dense"
          name="last_name"
          value={values.last_name}
          onChange={handleChange}
          error={!!errors.last_name}
          helperText={errors.last_name}
          size="small"
        />
      </div>

      <div>
        <FormLabel>{t('common.birth_date')}</FormLabel>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            inputFormat="DD/MM/YYYY"
            id="birth_date"
            value={values.birth_date}
            onChange={handleDateChange}
            KeyboardButtonProps={{ 'aria-label': t('common.birth_date') }}
            InputProps={{ readOnly: true }}
            maxDate={new Date()}
            autoOk
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                margin="dense"
                type="date"
                fullWidth
                error={!!errors.birth_date}
                helperText={errors.birth_date}
              />
            )}
          />
        </LocalizationProvider>
      </div>

      <div>
        <FormLabel>{t('common.Group')}</FormLabel>
        <Autocomplete
          id="group"
          value={values.group}
          error={!!errors.group}
          helperText={errors.group}
          size="small"
          onChange={handleChangeGroup}
          filterOptions={(options, params) => {
            const filtered = filter(options, params)

            const { inputValue } = params
            const isExisting = options.some(
              (option) => inputValue === option.key
            )

            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                title: `${t('common.Add')} "${inputValue}"`,
              })
            }

            return filtered
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={listGroups}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option
            }
            if (option.inputValue) {
              return option.inputValue
            }

            return option.title
          }}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} placeholder={t('common.select_or_edit')} />
          )}
        />
      </div>

      <Button
        className="SubmitButton"
        variant="contained"
        type="submit"
        loading={loading}
        fullWidth
        sx={{ marginTop: 1 }}
      >
        {t('common.Add')}
      </Button>
    </UserFormRoot>
  )
}

export default NaturalUserForm
