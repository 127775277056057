import Typography from '@mui/material/Typography'
import styled from 'styled-components'

export const ProgressValueRoot = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 16px;

  > span:first-child {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`
