/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { categoryProviders } from '../../config'

import providerService from '../../services/provider'
import { mapBy, mergeObj } from '../../utils'

export const providerByCategory = mapBy('name', categoryProviders)

const initialState = {
  providers: null,
  syncSettings: {},
  temp: null, // to keep track of the updating provider
}

export const getProviders = createAsyncThunk(
  'provider/getProviders',
  providerService.getProviders
)

export const createProvider = createAsyncThunk(
  'provider/createProvider',
  providerService.createProvider
)

export const deleteProvider = createAsyncThunk(
  'provider/deleteProvider',
  providerService.deleteProvider
)

export const getProvider = createAsyncThunk(
  'provider/getProvider',
  providerService.getProvider
)

export const updateProvider = createAsyncThunk(
  'provider/updateProvider',
  providerService.updateProvider
)

export const updateProviderCredentials = createAsyncThunk(
  'provider/updateProviderCredentials',
  providerService.updateProviderCredentials
)

export const updateProviderSettings = createAsyncThunk(
  'provider/updateProviderSettings',
  providerService.updateProviderSettings
)

export const getProviderSettings = createAsyncThunk(
  'provider/getProviderSettings',
  providerService.getProviderSettings
)

export const updateSyncSettings = createAsyncThunk(
  'provider/updateSyncSettings',
  providerService.updateSyncSettings
)

export const getSyncSettings = createAsyncThunk(
  'provider/getSyncSettings',
  providerService.getSyncSettings
)

const blackList = ['insee', 'inpi', 'inpi_rbe', 'bodacc']

const provider = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getProviders.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data?.result) {
        data.result = data.result
          .filter((d) => !blackList.includes(d.name))
          .map((p) => ({
            ...providerByCategory[p.name],
            ...p,
            image: `/assets/${p.name}.svg`,
          }))
      }
      state.providers = data
    },
    [getSyncSettings.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.syncSettings = mapBy('data_type_name', data)
    },
    [updateSyncSettings.pending]: (state, action) => {
      const input = action.meta.arg
      const { requestId } = action.meta

      state.temp = JSON.parse(JSON.stringify({ oldData: input, requestId }))

      input.forEach((d) => {
        state.syncSettings[d.data_type_name] = {
          ...state.syncSettings[d.data_type_name],
          ...d,
        }
      })
    },
    [updateSyncSettings.fulfilled]: (state, action) => {
      const { data, errors } = action.payload
      const { requestId } = action.meta
      const { oldData } = state.temp

      if (data) {
        data.forEach?.((d) => {
          state.syncSettings[d.data_type_name] = {
            ...state.syncSettings[d.data_type_name],
            ...d,
          }
        })
      }

      if (errors && oldData && state.temp?.requestId === requestId) {
        oldData.forEach((d) => {
          state.syncSettings[d.data_type_name] = {
            ...state.syncSettings[d.data_type_name],
            ...d,
          }
        })
      }
    },
    [updateProvider.pending]: (state, action) => {
      const input = action.meta.arg
      const { requestId } = action.meta
      const prov = state.providers?.result.find((p) => p.name === input.name)

      if (prov) {
        state.temp = JSON.parse(JSON.stringify({ ...prov, requestId }))
        prov.enabled = input.enable
      }
    },
    [updateProvider.fulfilled]: (state, action) => {
      const { errors } = action.payload
      const input = action.meta.arg
      const { requestId } = action.meta
      const prov = state.providers?.result.find((p) => p.name === input.name)

      if (errors && prov && state.temp?.requestId === requestId) {
        prov.enabled = state.temp.enable
      }
    },
    [getProviderSettings.fulfilled]: (state, action) => {
      const { data } = action.payload
      const input = action.meta.arg

      if (data) {
        const prov = state.providers?.result.find((p) => p.name === input)

        mergeObj(prov, data)
      }
    },
    [updateProviderSettings.pending]: (state, action) => {
      const input = action.meta.arg
      const { requestId } = action.meta
      const prov = state.providers?.result.find((p) => p.name === input.name)

      if (prov) {
        state.temp = JSON.parse(JSON.stringify({ ...prov, requestId }))
        mergeObj(prov, input)
      }
    },
    [updateProviderSettings.fulfilled]: (state, action) => {
      const { errors } = action.payload
      const input = action.meta.arg
      const { requestId } = action.meta
      const { requestId: _, ...saveProvider } = state.temp
      const prov = state.providers?.result.find((p) => p.name === input.name)

      if (errors && prov && state.temp?.requestId === requestId) {
        Object.keys(prov).forEach((k) => {
          delete prov[k]
        })
        mergeObj(prov, saveProvider)
      }
      state.temp = null
    },
  },
})

export default provider.reducer
