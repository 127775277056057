/* eslint-disable max-lines */
import Dialog from '@mui/material/Dialog'
import React, { useEffect, useState } from 'react'
import Zoom from '@mui/material/Zoom'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { TextField, Typography, FormLabel, IconButton } from '@mui/material'
import { useSelector } from 'react-redux'
import { EditOutlined } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { Button, Snackbar } from '../../../components'
import { useI18n } from '../../../context'
import { updateUser, groupsSelector } from '../../../redux'
import { useThunk } from '../../../hooks'
import { UserFormRoot } from '../AddUser/AddUser.style'
import UserFormHeader from '../AddUser/UserFormHeader'
import EditUserIcon from '../../../components/icons/EditUserIcon'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />
})

const EditUserModal = ({ user }) => {
  const [open, setOpen] = React.useState(false)

  const [values, setValues] = useState({ name: '', group: '' })
  const { t } = useI18n()
  const groups = useSelector(groupsSelector)
  const filter = createFilterOptions()
  const { dispatch, loading, errorMsg, data, errors } = useThunk()

  const listGroups = groups?.map((item) => ({ title: item, key: item })) ?? []

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleChangeGroup = (event, newValue) => {
    if (typeof newValue === 'string') {
      setValues({ ...values, group: newValue })
    } else if (newValue && newValue.inputValue) {
      setValues({ ...values, group: newValue.inputValue })
    } else {
      setValues({ ...values, group: newValue?.title || '' })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const { id } = user

    dispatch(() => updateUser({ id, input: values }))
  }

  useEffect(() => {
    if (user) {
      setValues({ name: user.name || '', group: user.group || '' })
    }
  }, [user])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!loading) {
      setOpen(false)
    }
  }, [loading])

  return (
    <>
      <IconButton aria-label="Edit user" onClick={handleOpen}>
        <Tooltip title={t('common.Modify')}>
          <EditOutlined />
        </Tooltip>
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        TransitionComponent={Transition}
      >
        <UserFormRoot onSubmit={handleSubmit}>
          <UserFormHeader onClose={handleClose} icon={<EditUserIcon />} />
          <Typography variant="button" className="subTitle" mb={1}>
            {t('common.edit_user')}
          </Typography>
          {user.type === 'LEGAL' && (
            <>
              <div>
                <FormLabel>{t('common.siren')}</FormLabel>
                <TextField
                  disabled
                  fullWidth
                  margin="dense"
                  name="name"
                  value={user?.siren}
                  size="small"
                />
              </div>
              <div>
                <FormLabel>{t('common.name')}</FormLabel>
                <TextField
                  fullWidth
                  margin="dense"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  size="small"
                />
              </div>
            </>
          )}
          {user.type === 'NATURAL' && (
            <>
              <div>
                <FormLabel>{t('common.first_name')}</FormLabel>
                <TextField
                  disabled
                  fullWidth
                  id="first_name"
                  margin="dense"
                  name="first_name"
                  value={user.first_name}
                  size="small"
                />
              </div>

              <div>
                <FormLabel>{t('common.last_name')}</FormLabel>
                <TextField
                  disabled
                  fullWidth
                  id="last_name"
                  margin="dense"
                  name="last_name"
                  value={user.last_name}
                  size="small"
                />
              </div>

              <div>
                <FormLabel>{t('common.birth_date')}</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled
                    inputFormat="DD/MM/YYYY"
                    id="birth_date"
                    value={user.birth_date}
                    onChange={handleChange}
                    KeyboardButtonProps={{
                      'aria-label': t('common.birth_date'),
                    }}
                    InputProps={{ readOnly: true }}
                    autoOk
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        margin="dense"
                        type="date"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}

          <div>
            <FormLabel>{t('common.Group')}</FormLabel>
            <Autocomplete
              value={values.group}
              size="small"
              onChange={handleChangeGroup}
              filterOptions={(options, params) => {
                const filtered = filter(options, params)
                const { inputValue } = params
                const isExisting = options.some(
                  (option) => inputValue === option.key
                )

                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `${t('common.Add')} "${inputValue}"`,
                  })
                }

                return filtered
              }}
              options={listGroups}
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.title
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('common.select_or_edit')}
                />
              )}
            />
          </div>

          <Button
            className="SubmitButton"
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            loading={loading}
            onClick={handleSubmit}
          >
            {t('common.Save')}
          </Button>
        </UserFormRoot>
      </Dialog>

      <Snackbar open={!loading && !!data} severity="success">
        {t('common.userUpdated')}
      </Snackbar>

      <Snackbar open={!loading && !!errors} error={!!errors}>
        {errorMsg}
      </Snackbar>
    </>
  )
}

export default EditUserModal
