import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Typography from '@mui/material/Typography'
import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { ProgressValueRoot } from './ProgressValue.style'

const ProgressValue = ({
  className = '',
  value,
  positiveIndicator,
  text = `${value > 0 ? '+' : ''}${value} %`,
  children,
  ...other
}) => {
  const getColorIcon = (val) => {
    if (val === 0) {
      return { color: 'info.main', Icon: ArrowForwardIcon }
    }

    return (val > 0 && positiveIndicator) || (val < 0 && !positiveIndicator) > 0
      ? { color: 'primary.main', Icon: ArrowUpwardIcon }
      : { color: 'error.main', Icon: ArrowDownwardIcon }
  }

  const { color, Icon } = getColorIcon(value)

  if (value === null) return null

  return (
    <ProgressValueRoot
      component="span"
      {...other}
      className={`ProgressValue ${className}`}
    >
      <Typography component="span" color={color}>
        <Icon /> {text}
      </Typography>
      {children}
    </ProgressValueRoot>
  )
}

export default ProgressValue
