import React from 'react'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import CloseIcon from '../../icons/CloseIcon'
import syncAlertData from './DataSyncAlert.data'
import { DataSyncAlertRoot } from './DataSyncAlert.style'

const DataSyncAlert = ({
  status,
  title,
  children,
  onClose,
  open = true,
  ...other
}) => {
  const Icon = syncAlertData[status]?.icon || syncAlertData.DEFAULT?.icon
  const color = syncAlertData[status] || syncAlertData.DEFAULT

  return (
    <Collapse in={open}>
      <DataSyncAlertRoot
        {...other}
        style={{
          borderColor: color.border || color.color,
          background: color.bg,
        }}
      >
        {Icon}
        <Box flexGrow={1}>
          <Box
            display="flex"
            justifyContent="space-between"
            aria-label="close"
            mb={1}
          >
            <Typography className="Title" style={{ color: color.color }}>
              {title}
            </Typography>
            <IconButton className="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography component="div" className="Body">
            {children}
          </Typography>
        </Box>
      </DataSyncAlertRoot>
    </Collapse>
  )
}

export default DataSyncAlert
