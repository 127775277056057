const validateLegalUser = ({ siren, name }, t) => {
  let errors = {}

  if (!siren?.trim()) {
    errors = { ...errors, siren: t('common.Siren required') }
  }

  if (!name) {
    errors = { ...errors, name: t('common.name required') }
  }

  return errors
}

const validateNaturalUser = ({ first_name, last_name, birth_date }, t) => {
  let errors = {}

  if (!first_name?.trim()) {
    errors = { ...errors, first_name: t('common.first_name required') }
  }

  if (!last_name) {
    errors = { ...errors, last_name: t('common.last_name required') }
  }

  if (!birth_date) {
    errors = { ...errors, birth_date: t('common.birth_date required') }
  }

  return errors
}

export { validateLegalUser, validateNaturalUser }
