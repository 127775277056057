import React from 'react'
import Box from '@mui/material/Box'

const colors = {
  DEFAULT: { color: 'rgb(24 28 50)', bgcolor: 'rgb(239 242 245)' },
  INFO: {
    color: 'rgb(0 158 247)',
    bgcolor: 'rgb(241 250 255)',
  },
  WARNING: {
    color: 'rgb(255 199 0)',
    bgcolor: 'rgb(255 248 221)',
  },
  DANGER: {
    color: 'rgb(241 65 108)',
    bgcolor: 'rgb(255 245 248)',
  },
  DANGER_INVERSED: {
    color: 'rgb(255 245 248)',
    bgcolor: 'rgb(241 65 108)',
  },
  SUCCESS: {
    color: 'rgb(80 205 137)',
    bgcolor: 'rgb(232 255 243)',
  },
}

const Label = ({ children, color = 'DEFAULT', ...other }) => {
  return (
    <Box
      sx={{
        borderRadius: '5px',
        width: 'fit-content',
        padding: '5px 10px',
        fontWeight: '600',
        ...other,
        ...(colors[color] || colors.DEFAULT),
      }}
    >
      {children}
    </Box>
  )
}

export default Label
