/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { /* useDispatch, */ useSelector } from 'react-redux'
import Box from '@mui/material/Box'

import ClockIcon from '../icons/ClockIcon'
import { useAuth, useI18n } from '../../context'
import { useInterval, useThunk } from '../../hooks'
import { useDataConnections } from '../../pages/DataConnections/DataConnectionTable'
import { useDataSyncs } from '../../pages/DataSyncs'
import { activeProvidersSelector } from '../../redux/provider'
import { RefreshIcon } from '../icons'
import { format } from '../../utils'
import {
  addDataSyncByTypeSelector,
  loadingTypesSelector,
} from '../../redux/dataSync'
import ProgressIndicator from '../ProgressIndicator'

const useSyncher = ({
  dataTypes,
  refresh,
  callbacks = [],
  hasData = false,
  noData,
  silent,
}) => {
  const { state } = useLocation()
  const callBackTk = useThunk()
  const { t } = useI18n()
  const { enqueueSnackbar: enqueue, closeSnackbar } = useSnackbar()
  const { userId } = useAuth()

  const enqueueSnackbar = !silent ? enqueue : () => ''

  const loadingTypes = useSelector(loadingTypesSelector)

  const addDataSyncByType = useSelector(addDataSyncByTypeSelector)

  const addDataSyncRes =
    dataTypes?.map((d) => addDataSyncByType[d]).find(Boolean) || {}

  const { errors, data: addSyncData } = addDataSyncRes

  const loadingTypesFound = loadingTypes?.filter((type) =>
    dataTypes?.includes(type)
  )

  const activedProviders = useSelector(activeProvidersSelector)

  const active = activedProviders.filter((p) =>
    p.data_types?.some((i) => dataTypes.includes(i))
  )

  const hasDataRef = useRef(hasData)

  if (hasData) hasDataRef.current = true

  const {
    status,
    fetching,
    synched,
    getSyncs,
    getLastSyncs,
    matchedSyncs,
    pendingSyncs,
  } = useDataSyncs({
    dataTypes,
  })

  const [loading, setLoading] = useState(!!loadingTypesFound?.length)

  const syncLaunched = useRef(false)

  if (loadingTypesFound?.length) syncLaunched.current = true

  const { connected } = useDataConnections(dataTypes)

  function syncCallback() {
    getSyncs()
    getLastSyncs()
  }

  useInterval({
    callback: syncCallback,
    interval: 5000,
    stop: () => pendingSyncs?.length === 0,
    start: !!addSyncData || !!loadingTypesFound?.length,
    reset: status,
  })

  const runCallbacks =
    (state?.refresh && refresh) || (synched && syncLaunched.current)

  useEffect(() => {
    if (!runCallbacks) return

    setLoading(true)

    callbacks.forEach((ft) => {
      if (ft) callBackTk.dispatch(ft)
    })

    const id = setTimeout(() => setLoading(false), 500)

    return () => clearTimeout(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.refresh, refresh, synched, loadingTypesFound?.length, userId])

  useEffect(() => {
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  useEffect(() => {
    if (fetching || loadingTypesFound?.length) {
      setLoading(true)
    } else if (errors || status?.includes?.('ERROR') || synched) {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, errors, fetching, status, synched, loadingTypesFound?.length])

  useEffect(() => {
    if (!status || synched || fetching || !syncLaunched.current) return

    const key = enqueueSnackbar(
      `${t('common.syncFailed')}: ${status}` || t('common.error.500'),
      { variant: 'error', onClick: () => closeSnackbar('1'), key: '1' }
    )

    const id = setTimeout(() => closeSnackbar(key), 6000)

    return () => clearTimeout(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching, status, synched])

  useEffect(() => {
    let errorTimerId
    let successTimerId

    if (addSyncData) {
      getSyncs()
    }

    return () => {
      clearTimeout(errorTimerId)
      clearTimeout(successTimerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSyncData, errors])

  const showLoader = loading && !!callbacks?.length

  const showSyncFailedAlert =
    !hasDataRef.current &&
    status?.includes?.('ERROR') &&
    !loading &&
    !!callbacks?.length &&
    syncLaunched.current &&
    !showLoader

  const showNoData =
    !hasData &&
    !loading &&
    !showLoader &&
    !showSyncFailedAlert &&
    !!callbacks?.length

  const onRefresh = () => {
    // handleSync()
  }

  // eslint-disable-next-line no-unused-vars
  const RefreshIconButton = connected && (
    <Tooltip title={t('common.Sync')}>
      <IconButton
        aria-label="sync"
        onClick={onRefresh}
        className={`RefreshButton ${loading ? 'rotate' : ''}`}
      >
        <RefreshIcon fontSize="small" sx={{ fontSize: '16px' }} />
      </IconButton>
    </Tooltip>
  )

  const syncS = showSyncFailedAlert || showNoData || showLoader

  const Status = syncS && (
    <Box className="SyncStatus">
      {(showNoData || showSyncFailedAlert) && (
        <Typography mb={1}>{noData ?? t('common.no_data')}</Typography>
      )}

      {showLoader && (
        <Box position="relative" sx={{ pt: 4, pb: 4 }}>
          <ProgressIndicator circular />
        </Box>
      )}
    </Box>
  )

  const LastSyncDate = !!matchedSyncs?.[0]?.completed_at && (
    <Tooltip title={t('common.last_sync_date')} arrow>
      <Typography
        className="SubTitle"
        variant="body2"
        whiteSpace="nowrap"
        width="fit-content"
      >
        <ClockIcon />
        {format(matchedSyncs[0].completed_at, 'dd MMM yy - HH:mm:ss')}
      </Typography>
    </Tooltip>
  )

  return {
    connected,
    Status,
    synched,
    status,
    onRefresh,
    loading,
    dataTypes,
    matchedSyncs,
    LastSyncDate,
    hasData: !showNoData,
    active,
  }
}

export default useSyncher
