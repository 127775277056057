import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useI18n, useAuth } from '../../context'
import ConnectionIcon from '../icons/ConnectionIcon'
import SyncIcon from '../icons/SyncIcon'
import { SidebarBottomRoot } from './SidebarUser.style'
import DataConnexionIcon from '../icons/DataConnexionIcon'
import UniqueLinkDialog from '../../pages/Users/UniqueLink/UniqueLinkDialog'

const SidebarBottom = () => {
  const { currentUser } = useAuth()
  const { t } = useI18n()
  const { pathname } = useLocation()

  const [open, setOpen] = React.useState(false)

  const handleOpen = (v) => () => {
    setOpen(v)
  }

  if (!currentUser) return null

  const connectionLink = `/user/${currentUser.id}/data-connections`
  const syncLink = `/user/${currentUser.id}/data-sync`

  return (
    <SidebarBottomRoot
      direction="row"
      className="SidebarBottom"
      spacing="10px"
      alignContent="center"
    >
      <Tooltip title={t('common.Connexions')}>
        <IconButton
          component={Link}
          to={connectionLink}
          sx={{ padding: '12px' }}
          className={connectionLink === pathname ? 'active' : ''}
        >
          <ConnectionIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('common.Synchronisations')}>
        <IconButton
          component={Link}
          to={syncLink}
          sx={{ padding: '12px' }}
          className={syncLink === pathname ? 'active' : ''}
        >
          <SyncIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('common.unique_link')}>
        <IconButton
          component={Link}
          to={Link}
          onClick={handleOpen(true)}
          sx={{ padding: '12px' }}
          className={open ? 'active' : ''}
        >
          <DataConnexionIcon />
        </IconButton>
      </Tooltip>
      <UniqueLinkDialog
        user={currentUser}
        open={open}
        onClose={handleOpen(false)}
      />
    </SidebarBottomRoot>
  )
}

export default React.memo(SidebarBottom)
