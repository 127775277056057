import { DEFAULT_PAGE_SIZE, isProd, MAP_TOKEN } from '../../config'
import ApiClient from '../apiClient'
import HttpClient from '../apiClient/HttpClient'
import { getUserId } from '../user'

const client = new ApiClient()

const getCompanyProfile = async ({ userId = getUserId() }) => {
  const res = await client.get(`/users/${userId}/company-profile`)

  if (res.data) res.data.userId = userId

  res.userId = userId

  return res
}

const getProfileByUsers = ({ userIds }) => {
  return Promise.all(
    userIds.map((id) => getCompanyProfile({ userId: id }))
  ).then((res) => res.map((r) => r.data || { userId: r.userId }))
}

const getCompanies = ({
  query,
  officer_last_name = null,
  officer_birth_date = null,
}) => {
  return client.get(`/companies`, {
    params: { query, officer_last_name, officer_birth_date },
  })
}

const getCompanyBySiren = ({ siren }) => {
  return client.get(`/companies`, {
    params: { query: siren },
  })
}

const getCompaniesAutocomplete = async ({ query }) => {
  const res = await client.get('/companies-autocomplete', {
    params: { query },
  })

  return res.data
}

const getTimelines = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/timeline`, { params: { page, per_page } })
}

const getCorporateOffices = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/corporate-offices`, {
    params: { page, per_page },
  })
}

const getCorporateOffice = ({ officeId, userId = getUserId() }) => {
  return client.get(`/users/${userId}/corporate-offices/${officeId}`)
}

const getCollectiveProcedures = async ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  const res = await client.get(`/users/${userId}/collective-procedures`, {
    params: { page, per_page },
  })

  if (res.data) res.data.userId = userId

  res.userId = userId

  return res
}

const getDataConnections = async ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  const res = await client.get(`/users/${userId}/data-connections`, {
    params: { page, per_page },
  })

  if (res.data) res.data.userId = userId

  res.userId = userId

  return res
}

const getPclByUsers = ({ userIds }) => {
  return Promise.all(
    userIds.map((userId) =>
      getCollectiveProcedures({ userId, page: 1, per_page: 1 })
    )
  ).then((res) => res.map((r) => r.data || { userId: res.userId }))
}

const getDataConnectionsByUsers = ({ userIds }) => {
  return Promise.all(
    userIds.map((userId) => getDataConnections({ userId }))
  ).then((res) => res.map((r) => r.data || { userId: res.userId }))
}

const searchAddress = async ({ address_line1, city, country, postal_code }) => {
  try {
    const address = `${address_line1} ${postal_code} ${city}`

    const api = new HttpClient(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURI(
        address
      )}.json?country=${country}&postcode=${postal_code}&access_token=${MAP_TOKEN}`
    )

    const res = await api.get()

    return res?.features?.[0]?.center
  } catch (error) {
    if (!isProd) console.error(error)

    return null
  }
}

const getBankingRelationships = ({ userId = getUserId() }) => {
  return client.get(`/users/${userId}/banking-relationships`)
}

const getTrademarks = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/trademarks`, {
    params: { page, per_page },
  })
}

const companyService = {
  getTimelines,
  getCompanyProfile,
  getCollectiveProcedures,
  getCorporateOffices,
  getCompanies,
  getCompaniesAutocomplete,
  getCompanyBySiren,
  getCorporateOffice,
  searchAddress,
  getBankingRelationships,
  getPclByUsers,
  getDataConnectionsByUsers,
  getProfileByUsers,
  getTrademarks,
}

export default companyService
