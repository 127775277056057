import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const NoAntecedantIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 115 115">
      <circle cx={57.5} cy={57.5} r={57.5} fill="#50CD89" />
      <g clipPath="url(#NoAntecedantIcona)">
        <path
          d="M80.218 39.97 49.125 71.06a2.25 2.25 0 0 1-3.188 0L33.913 59.025a2.25 2.25 0 0 0-3.188 0 2.251 2.251 0 0 0 0 3.188L42.752 74.24a6.768 6.768 0 0 0 9.563 0l31.09-31.084a2.248 2.248 0 0 0 0-3.186 2.25 2.25 0 0 0-3.188 0Z"
          fill="#041F3D"
        />
      </g>
      <defs>
        <clipPath id="NoAntecedantIcona">
          <path fill="#fff" transform="translate(30 30)" d="M0 0h54v54H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default NoAntecedantIcon
