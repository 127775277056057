const bgCheckSelector = (state) => state.franceBank.bgCheck

const bgStatusSelector = (state) => state.franceBank.bgCheck.status

const bgDiagramSelector = (state) =>
  state.franceBank.bgCheck.backgroundCheck?.details

const bgReasonsSelector = (state) =>
  state.franceBank.bgCheck.backgroundCheck?.reasons

const bgCheckSelectorV2 = (state) => state.franceBank.bgCheckV2

const bgStatusSelectorV2 = (state) => state.franceBank.bgCheckV2.status

const bgDiagramSelectorV2 = (state) =>
  state.franceBank.bgCheckV2.backgroundCheck?.details

const bgReasonsSelectorV2 = (state) =>
  state.franceBank.bgCheckV2.backgroundCheck?.reasons

const bgWarningsSelector = (state) =>
  state.franceBank.bgCheck.backgroundCheck?.warnings

const bgWarningsSelectorV2 = (state) =>
  state.franceBank.bgCheckV2.backgroundCheck?.warnings

export {
  bgCheckSelector,
  bgStatusSelector,
  bgDiagramSelector,
  bgReasonsSelector,
  bgCheckSelectorV2,
  bgStatusSelectorV2,
  bgDiagramSelectorV2,
  bgReasonsSelectorV2,
  bgWarningsSelector,
  bgWarningsSelectorV2,
}
