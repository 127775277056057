import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const BackIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 9 15" fill="none">
      <path
        d="M7.47009 14.9962L1.29946 8.82557C0.947955 8.47396 0.750488 7.99713 0.750488 7.49995C0.750488 7.00276 0.947955 6.52594 1.29946 6.17432L7.46634 0.00744629L8.35009 0.891196L2.18321 7.05807C2.06604 7.17528 2.00022 7.33422 2.00022 7.49995C2.00022 7.66567 2.06604 7.82462 2.18321 7.94182L8.35384 14.1124L7.47009 14.9962Z"
        fill="#666666"
      />
    </SvgIcon>
  )
}

export default BackIcon
