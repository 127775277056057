import React, { useState } from 'react'

import HelpIcon from '@mui/icons-material/Help'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Box,
  Collapse,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import { Button } from '../../../components'
import { useI18n } from '../../../context'
import { AdvancedSettingsRoot } from './AdvancedSettings.style'

const AdvancedSettings = ({ values, setValues, updateHeight }) => {
  const { t } = useI18n()

  const [open, setOpen] = useState(false)

  const hnandleClick = () => {
    setOpen((v) => !v)
    updateHeight()
  }

  const handleChange = (e) => {
    setValues({ ...values, skip_permissions: e.target.checked })
  }

  return (
    <AdvancedSettingsRoot>
      <Button
        onClick={hnandleClick}
        color="inherit"
        endIcon={
          <KeyboardArrowDownIcon
            className={open ? 'expandIcon' : 'collapseIcon'}
          />
        }
      >
        {t('common.Advanced settings')}
      </Button>

      <Collapse in={open} timeout={0}>
        <Typography mb={1} className="tooltipText">
          {t('common.Callback URL')}
          <Typography variant="body2">{`(${t('common.Optional')})`}</Typography>

          <Tooltip title={t('common.callbackUrlText')} arrow>
            <HelpIcon />
          </Tooltip>
        </Typography>

        <TextField
          variant="outlined"
          placeholder="https://example.com"
          size="small"
          fullWidth
        />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={1}
        >
          <Typography mb={1} className="tooltipText">
            {t('common.Skip permissions')}

            <Tooltip title={t('common.skipAuthorisationInfo')} arrow>
              <HelpIcon />
            </Tooltip>
          </Typography>

          <Switch onChange={handleChange} />
        </Box>
      </Collapse>
    </AdvancedSettingsRoot>
  )
}

export default AdvancedSettings
