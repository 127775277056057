import styled, { css } from 'styled-components'
import PerfectScrollbar from 'react-perfect-scrollbar'

const getColor = ({ theme, color }) =>
  color ||
  theme.scrollBar.color ||
  (theme.palette.mode === 'light' ? '#999' : theme.palette.background.paper)

const getBackgroundColor = ({ theme, background }) =>
  background ||
  theme.scrollBar.background ||
  (theme.palette.mode === 'light' ? '#eee' : theme.palette.background.paper)

const getThumbSize = ({ theme, size }) =>
  theme.scrollBar[size] || theme.scrollBar.default || 6

const getRailSize = ({ theme, size }) => getThumbSize({ theme, size }) + 4

const getOffset = ({ theme, size, offset }) =>
  !offset ? 0 : getRailSize({ theme, size }) + 2

const getRadius = ({ variant, theme }) =>
  (variant || theme.scrollBar.variant) === 'square' ? 0 : undefined

const StyledPerfectScrollbar = styled(PerfectScrollbar)`
  &.ps--active-x {
    padding-bottom: ${getOffset}px;
    margin-bottom: -${getOffset}px;
  }

  &.ps--active-y {
    padding-right: ${getOffset}px;
    margin-right: -${getOffset}px;
  }

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `}

  .ps__thumb-x,
  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: ${getColor};
    height: ${getThumbSize}px !important;
    border-radius: ${getRadius} !important;
  }

  .ps__thumb-y,
  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: ${getColor};
    width: ${getThumbSize}px !important;
    border-radius: ${getRadius} !important;
  }

  .ps__rail-x {
    height: ${getRailSize}px !important;
  }

  .ps__rail-y {
    width: ${getRailSize}px !important;
  }

  .ps__rail-x:hover,
  .ps__rail-y:hover,
  .ps__rail-x:focus,
  .ps__rail-y:focus,
  .ps__rail-x.ps--clicking,
  .ps__rail-y.ps--clicking {
    background-color: ${getBackgroundColor} !important;
  }

  .ps__rail-x,
  .ps__rail-y {
    opacity: ${({ visiblility, theme }) =>
      (theme.scrollBar.visiblility || visiblility) === 'always'
        ? '0.6 !important'
        : undefined};
  }
`

export default StyledPerfectScrollbar
