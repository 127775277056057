import Typography from '@mui/material/Typography'
import Hidden from '@mui/material/Hidden'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, Link } from 'react-router-dom'

import { clientInfoSelector } from '../../redux/user'
import { QlogoDark } from '../icons'
import UserSearch from '../UserSearch'
import UserAvatarMenu from './UserAvatarMenu'
import { AppbarRoot } from './Appbar.style'
import { useNav } from '../../context'
import { getAllDataSyncs } from '../../redux/dataSync'
import { getAllDataConnections } from '../../redux/dataConnection'

const Appbar = ({ onMenuClick, ...other }) => {
  const user = useSelector(clientInfoSelector)
  const { pathname } = useLocation()
  const userName = user?.name

  const { navigations, getNavUrl } = useNav()
  const dispatch = useDispatch()

  const [selectedUser, setSelectedUser] = useState(null)

  const history = useHistory()

  const handleUserSelect = (newUser) => {
    setSelectedUser(newUser)

    if (newUser?.id) {
      history.push({
        ...history.location,
        pathname: `/user/${newUser.id}/company`,
        state: { ...history.location.state, selecteduser: newUser?.id },
      })

      sessionStorage.setItem('selecteduser', newUser?.id)

      dispatch(getAllDataConnections({ page: 1, per_page: 1000 }))
      dispatch(getAllDataSyncs({ page: 1, per_page: 1000 }))
    }
  }

  return (
    <AppbarRoot position="sticky" {...other} elevation={0}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{ opacity: user ? 1 : 0 }}
      >
        <Stack
          flexGrow={1}
          direction="row"
          spacing={2}
          alignItems="center"
          className="DesktopMenu"
        >
          <Box onClick={onMenuClick} className="NavIcon" edge="start">
            <QlogoDark />
          </Box>

          <Hidden lgDown>
            {navigations.slice(0, 3).map((nav) => {
              const { icon: Icon, name, path } = nav
              const url = getNavUrl(nav)

              return (
                <Box
                  display="flex"
                  alignItems="center"
                  gap="12px"
                  key={name}
                  className={`NavItem${path === pathname ? ' active' : ''}`}
                  component={Link}
                  to={url}
                >
                  <Icon fontSize="small" />
                  <span>{name}</span>
                </Box>
              )
            })}
          </Hidden>
        </Stack>

        <Box display="flex" alignItems="center" gap={2}>
          <UserSearch value={selectedUser} onChange={handleUserSelect} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            className="right"
          >
            <Hidden mdDown>
              <Typography
                variant="h6"
                className="ellipsis"
                maxWidth="150px"
                title={userName}
              >
                {userName}
              </Typography>
            </Hidden>
            <UserAvatarMenu />
          </Stack>
        </Box>
      </Box>
    </AppbarRoot>
  )
}

export default Appbar
