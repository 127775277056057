import { useDispatch, useSelector } from 'react-redux'

import {
  getAllDataSyncs,
  allDataSyncsSelector,
  allSyncsLoadingSelector,
  latestDataSyncsSelector,
  getLatestDataSyncs,
} from '../../../redux/dataSync'
import { MAX_PAGE_SIZE } from '../../../config'

const useDataSyncs = ({ dataTypes }) => {
  const data = useSelector(allDataSyncsSelector)
  const lastSyncs = useSelector(latestDataSyncsSelector)
  const loading = useSelector(allSyncsLoadingSelector)

  const dispatch = useDispatch()
  const dataSyncs = JSON.parse(JSON.stringify(data?.result || []))
  const lastDataSyncs = JSON.parse(JSON.stringify(lastSyncs || []))

  const matchedSyncs = dataSyncs.filter((d) => dataTypes?.includes(d.data_type))

  const status = matchedSyncs?.[0]?.status

  const synched = status === 'SUCCESS'
  const fetching = status === 'FETCHING' || status === 'QUEUED'

  const getSyncs = () => {
    dispatch(getAllDataSyncs({ page: 1, per_page: MAX_PAGE_SIZE }))
  }

  const getLastSyncs = () => {
    dispatch(getLatestDataSyncs({}))
  }

  const pendingSyncs = matchedSyncs?.filter(
    (s) => s.status === 'FETCHING' || s.status === 'QUEUED'
  )

  const errorSyncs = lastDataSyncs
    .filter((d) => dataTypes?.includes(d.data_type))
    ?.filter((s) => s.status?.includes('ERROR'))
    .filter(
      (value, index, self) =>
        index === self.findIndex((s) => s.data_type === value.data_type)
    )

  return {
    dataSyncs,
    matchedSyncs,
    getSyncs,
    getLastSyncs,
    loading,
    fetching,
    status,
    synched,
    fetched: !loading && !!data,
    pendingSyncs,
    errorSyncs,
  }
}

export default useDataSyncs
