import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const CompanyUserIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M11 0H3a3 3 0 0 0-3 3v21h14V3a3 3 0 0 0-3-3zM6 19H3v-2h3zm0-4H3v-2h3zm0-4H3V9h3zm0-4H3V5h3zm5 12H8v-2h3zm0-4H8v-2h3zm0-4H8V9h3zm0-4H8V5h3zm10-2h-5v19h8V8a3 3 0 0 0-3-3zm0 14h-2v-2h2zm0-4h-2v-2h2zm0-4h-2V9h2z" />
    </SvgIcon>
  )
}

export default CompanyUserIcon
