import React from 'react'

import { useI18n } from '../../context'
import { getUserId } from '../../services/user'
import DataSyncAlert from './DataSyncAlert'
import DataType, { syncProviders } from './DataType'

const PendingConnectionAlert = ({
  onClose,
  closedAlert,
  connectionToFinish,
}) => {
  const { t } = useI18n()

  const id = `PendingConnection_${syncProviders(
    connectionToFinish
  )}_${getUserId()}`

  const closed = !!closedAlert?.[id]
  const open = !!connectionToFinish?.length && !closed

  const handleClose = () => {
    onClose?.(id)
  }

  return (
    <DataSyncAlert
      title={t('common.alert.pendingConnection.title')}
      status={connectionToFinish?.[0]?.status}
      onClose={handleClose}
      open={open}
    >
      {t('common.alert.pendingConnection')}

      <DataType syncs={connectionToFinish} provider copy />
    </DataSyncAlert>
  )
}

export default PendingConnectionAlert
