import ApiClient from '../apiClient'

const client = new ApiClient()

const getUserId = () => {
  try {
    return sessionStorage.selecteduser
  } catch (error) {
    return ''
  }
}

const getClientInfo = () => client.get(`/clients`)

const getUsers = ({
  page = 1,
  query,
  created_before,
  type,
  sort_field,
  created_after,
  direction,
  per_page = 100,
  group,
  state,
  provider,
} = {}) => {
  return client.get('/users', {
    params: {
      page,
      per_page,
      ...(query && { query }),
      ...(type && { type }),
      ...(sort_field && { sort_field }),
      ...(direction && { direction }),
      ...(created_after && { created_after }),
      ...(created_before && { created_before }),
      ...(group && { group }),
      ...(state && { state }),
      ...(provider && { provider }),
    },
  })
}

const createUser = async ({ type, ...input }) => {
  return client.post(`/users/${type}`, input)
}

const deleteUser = async ({ id }) => client.delete(`/users/${id}`)

const getUser = ({ id }) => client.get(`/users/${id}`)

const getMetrics = () => client.get(`/metrics`)

const getGroups = () => client.get(`/groups`)

const getClientSettings = () => client.get(`/client-settings`)

const updateClientSettings = (input) => client.patch(`/client-settings`, input)

const updateUser = ({ id, input }) => {
  return client.patch(`/users/${id}`, input)
}

const generateApiKey = () => client.put(`/profile/api-key`)

const getApiKey = () => client.get(`/profile/api-key`)

const userService = {
  getClientInfo,
  getUsers,
  createUser,
  deleteUser,
  getUser,
  getMetrics,
  getGroups,
  getClientSettings,
  updateClientSettings,
  generateApiKey,
  getApiKey,
  updateUser,
}

export { getUserId }
export default userService
