import { isDev, isProd } from '../../config'
import HttpClient from './HttpClient'
import errorsText from '../../i18n/en/errors.json'

/**
 * Backend api base url
 */
export const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v6`

let expiredJwt = ''
/**
 * REST Client that use native fetch to connect with the backend API.
 *
 * @see https://developer.mozilla.org/fr/docs/Web/API/Fetch_API/Using_Fetch
 *
 * @example <caption>Usage</caption>
 * const client = new HttpClient()
 * const { data, errors } = await client.get('/users')
 * client.post('/users', userInput)
 * client.delete('/users/1')
 */

class ApiClient extends HttpClient {
  constructor(baseURL = API_BASE_URL) {
    super({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        locale: localStorage.getItem('language'),
      },
      interceptors: {
        request() {
          const accessToken = localStorage.getItem('jwt')

          if (accessToken) {
            const Authorization = `Bearer ${accessToken}`

            return {
              headers: {
                Authorization,
              },
            }
          }
        },
        async response(res, opts) {
          if (opts.blob)
            return {
              data: res.ok ? await res.blob() : undefined,
              errors: res.ok ? undefined : [await res.json?.()],
            }

          let payload

          try {
            payload = res.status === 204 ? null : await res.json()
          } catch (error) {
            if (!isProd) console.error(error)
            payload = null
          }

          if (res.ok || res.status === 204) return { data: payload }

          // chef if request is POST because POST can return 401 even if jwt is valid
          if (res.status === 401 && opts.method !== 'POST') {
            // logout
            if (localStorage.jwt) {
              // token expired
              if (expiredJwt === localStorage.jwt) {
                localStorage.removeItem('jwt')
                localStorage.removeItem('user')

                sessionStorage.setItem('from', window.location.pathname)

                // window.location.pathname = '/'
                setTimeout(() => window.location.reload())
              }

              expiredJwt = localStorage.jwt
            }
          }

          // Format backend error to be displayed on the ui

          let errors = payload?.errors || payload?.error || payload

          errors = Array.isArray(errors) ? errors : [errors]

          if (isDev) {
            console.error(errors)
          }

          errors = errors.map((error) => {
            const key = errorsText[`error.${error?.error_code}`]
              ? error?.error_code
              : undefined

            const msg =
              error?.error_details && Object.values(error.error_details)[0]

            return {
              ...error,
              message: msg || error?.error_message || error?.message,
              key: msg || key,
            }
          })

          return {
            errors: Array.isArray(errors) ? errors : [errors],
            status: res.status,
          }
        },
      },
    })
  }
}

export default ApiClient
