import Typography from '@mui/material/Typography'
import styled from 'styled-components'

export const DataTypeText = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;

  > svg {
    font-size: 4px !important;
  }
`
