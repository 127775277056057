import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ListViewIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 30 30">
      <rect y={10.586} width={8.824} height={8.824} rx={2} fill="#848484" />
      <rect
        x={10.586}
        y={10.586}
        width={19.412}
        height={8.824}
        rx={2}
        fill="#5B5B5B"
      />
      <rect width={8.824} height={8.824} rx={2} fill="#848484" />
      <rect x={10.586} width={19.412} height={8.824} rx={2} fill="#5B5B5B" />
      <rect y={21.18} width={8.824} height={8.824} rx={2} fill="#848484" />
      <rect
        x={10.586}
        y={21.18}
        width={19.412}
        height={8.824}
        rx={2}
        fill="#5B5B5B"
      />
    </SvgIcon>
  )
}

export default ListViewIcon
