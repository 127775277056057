/* eslint-disable react/no-danger */
import React from 'react'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { useHistory } from 'react-router-dom'
import { collectiveProceduresSelector } from '../../redux/company'
import { useAuth, useI18n } from '../../context'
import Button from '../Button'
import { PCLLabel } from './CompanyPCL.style'

const CompanyPCL = () => {
  const data = useSelector(collectiveProceduresSelector)
  const procedures = data?.result
  const { t } = useI18n()
  const history = useHistory()
  const { userId } = useAuth()

  const handleOpen = () => {
    setTimeout(() => {
      history.push(`/user/${userId}/institutional/collective-procedures`)
    })
  }

  if (!procedures?.length) return null

  return (
    <PCLLabel>
      <Typography color="error">
        <InfoOutlinedIcon />{' '}
        <span dangerouslySetInnerHTML={{ __html: t('common.pclInfo') }} />
      </Typography>

      <Button
        variant="outlined"
        size="small"
        onClick={handleOpen}
        color="error"
      >
        {t('common.show')}
      </Button>
    </PCLLabel>
  )
}

export default CompanyPCL
