import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const BuildingIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <g clipPath="url(#clip0_1036_96)">
        <path
          d="M8.25 0H2.25C1.65326 0 1.08097 0.237053 0.65901 0.65901C0.237053 1.08097 0 1.65326 0 2.25L0 18H10.5V2.25C10.5 1.65326 10.2629 1.08097 9.84099 0.65901C9.41903 0.237053 8.84674 0 8.25 0V0ZM4.5 14.25H2.25V12.75H4.5V14.25ZM4.5 11.25H2.25V9.75H4.5V11.25ZM4.5 8.25H2.25V6.75H4.5V8.25ZM4.5 5.25H2.25V3.75H4.5V5.25ZM8.25 14.25H6V12.75H8.25V14.25ZM8.25 11.25H6V9.75H8.25V11.25ZM8.25 8.25H6V6.75H8.25V8.25ZM8.25 5.25H6V3.75H8.25V5.25ZM15.75 3.75H12V18H18V6C18 5.40326 17.7629 4.83097 17.341 4.40901C16.919 3.98705 16.3467 3.75 15.75 3.75ZM15.75 14.25H14.25V12.75H15.75V14.25ZM15.75 11.25H14.25V9.75H15.75V11.25ZM15.75 8.25H14.25V6.75H15.75V8.25Z"
          fill="#BCBCC8"
        />
      </g>
      <defs>
        <clipPath id="clip0_1036_96">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default BuildingIcon
