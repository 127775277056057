import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import ListPagination from '../../../components/ListPagination'
import Table from '../../../components/Table'
import { dataSyncsSelector, getDataSyncs } from '../../../redux/dataSync'
import { DataSyncTableRoot } from './DataSyncTable.style'
import { buildColumns } from './DataSyncTable.util'
import { useI18n } from '../../../context'
import { DEFAULT_PAGE_SIZE } from '../../../config'

const DataSyncTable = () => {
  const { t } = useI18n()
  const columns = buildColumns(t)
  const [selectedRows, setSelectedRows] = useState([])

  const data = useSelector(dataSyncsSelector)
  const dispatch = useDispatch()

  const syncs = data?.result || []

  const [page, setPage] = useState(1)

  const handleChange = (event, value) => {
    setPage(value)
  }

  const handleSelectChange = (rows) => {
    setSelectedRows(rows)
  }

  useEffect(() => {
    if (page === data?.current_page) return

    dispatch(getDataSyncs({ page, per_page: DEFAULT_PAGE_SIZE }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <DataSyncTableRoot>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
        mb={2}
      >
        {data?.last_page > 1 && (
          <ListPagination
            count={Math.ceil(
              (data?.total || DEFAULT_PAGE_SIZE) / DEFAULT_PAGE_SIZE
            )}
            page={page}
            onChange={handleChange}
          />
        )}
      </Stack>

      {!!syncs?.length && (
        <Table
          columns={columns}
          rows={syncs}
          className="Table"
          rowsPerPageOptions={[]}
          onSelectedRowChange={handleSelectChange}
          // getRowSelected={(row) => row.id}
          selectedRows={selectedRows}
        />
      )}

      {!syncs?.length && <Typography mb={2}>{t('common.no_data')}</Typography>}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        marginTop={2}
      >
        {data?.last_page > 1 && (
          <ListPagination
            count={Math.ceil(
              (data?.total || DEFAULT_PAGE_SIZE) / DEFAULT_PAGE_SIZE
            )}
            page={page}
            onChange={handleChange}
          />
        )}
      </Stack>
    </DataSyncTableRoot>
  )
}

export default DataSyncTable
