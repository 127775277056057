import Box from '@mui/material/Box'
import styled from 'styled-components'

export const NavBreadcrumbRoot = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;

  h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #041f3d;
  }

  .MuiBreadcrumbs-li {
    font-size: 15px;
    color: #7e8299;
    font-weight: 500;

    p {
      color: #7e8299;
    }
  }

  &.Primary {
    .MuiBreadcrumbs-li {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #041f3d;

      p {
        color: #041f3d;
      }
    }
  }
`
