import { useEffect } from 'react'
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration'

const ServiceWorkerRegister = () => {
  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        window.location.reload()
      },
    })
  }, [])

  return null
}

export default ServiceWorkerRegister
