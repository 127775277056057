/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import lastDayOfMonth from 'date-fns/lastDayOfMonth'
import subMonths from 'date-fns/subMonths'

import commerceService from '../../services/commerce'
import { fromLS, sortInsights } from '../../utils'

const initialState = {
  balances: null,
  subscriptions: null,
  transactions: null,
  disputes: null,
  transactionInsights: null,
  subscriptionInsights: null,
  disputesInsights: null,
  minDate: new Date().toISOString(),
  maxDate: lastDayOfMonth(new Date()).toISOString(),
  dates: [],
  ...fromLS('commerceDate', true),
  latestRange: null,
  customers: null,
  products: null,
  orders: null,
}

export const getCommerceSubscriptions = createAsyncThunk(
  'commerce/getCommerceSubscriptions',
  commerceService.getCommerceSubscriptions
)

export const getCommerceBalances = createAsyncThunk(
  'commerce/getCommerceBalances',
  commerceService.getCommerceBalances
)

export const getCommerceTransactions = createAsyncThunk(
  'commerce/getCommerceTransactions',
  commerceService.getCommerceTransactions
)

export const getCommercePayouts = createAsyncThunk(
  'commerce/getCommercePayouts',
  commerceService.getCommerceTransactions
)

export const getCommerceTransactionInsights = createAsyncThunk(
  'commerce/getCommerceTransactionInsights',
  commerceService.getCommerceTransactionInsights
)

export const getCommerceDisputes = createAsyncThunk(
  'commerce/getCommerceDisputes',
  commerceService.getCommerceDisputes
)

export const getCommerceCustomers = createAsyncThunk(
  'commerce/getCommerceCustomers',
  commerceService.getCommerceCustomers
)

export const getCommerceSubscriptionsInsights = createAsyncThunk(
  'commerce/getCommerceSubscriptionsInsights',
  commerceService.getCommerceSubscriptionsInsights
)

export const getCommerceDisputesInsights = createAsyncThunk(
  'commerce/getCommerceDisputesInsights',
  commerceService.getCommerceDisputesInsights
)

export const getCommerceProducts = createAsyncThunk(
  'commerce/getCommerceProducts',
  commerceService.getCommerceProducts
)

export const getCommerceOrders = createAsyncThunk(
  'commerce/getCommerceOrders',
  commerceService.getCommerceOrders
)

const updateDates = (data, state) => {
  const saved = fromLS('commerceDate', 1)

  if (data.length) {
    data.forEach((r) => {
      state.dates.push(
        r.source_created_at || r.source_updated_at || `${r?.month}-01`
      )
    })

    state.dates = [
      ...new Set(state.dates.map((d) => new Date(d).toISOString())),
    ].sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    state.minDate = new Date(state.dates[0]).toISOString()
    state.maxDate = new Date(state.dates[state.dates.length - 1])
    state.maxDate = lastDayOfMonth(state.maxDate)

    state.latestRange = {
      minDate: subMonths(state.maxDate, 1).toISOString(),
      maxDate: state.maxDate.toISOString(),
    }

    state.maxDate = saved?.maxDate || state.maxDate
    state.minDate = saved?.minDate || state.minDate

    const { dates } = state

    localStorage.setItem('commerceDate', JSON.stringify({ ...saved, dates }))
  }
}

const commerce = createSlice({
  name: 'commerce',
  initialState,
  reducers: {
    updateCommerceDate: (state, action) => {
      Object.keys(action.payload).forEach((k) => {
        state[k] = action.payload[k].toISOString()
      })

      const { dates, minDate, maxDate } = state

      localStorage.setItem(
        'commerceDate',
        JSON.stringify({ dates, minDate, maxDate })
      )
    },
  },
  extraReducers: {
    [getCommerceBalances.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        // sortInsights(data.result)
        // updateDates(data.result, state)
      }

      state.balances = data
    },
    [getCommerceDisputes.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        // sortInsights(data.result)
        // updateDates(data.result, state)
      }
      state.disputes = data
    },
    [getCommerceCustomers.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        // sortInsights(data.result)
        updateDates(data.result, state)
      }
      state.customers = data
    },
    [getCommerceProducts.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        // sortInsights(data.result)
        updateDates(data.result, state)
      }
      state.products = data
    },
    [getCommerceOrders.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        // sortInsights(data.result)
        updateDates(data.result, state)
      }
      state.orders = data
    },
    [getCommerceSubscriptions.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        // sortInsights(data.result)
        // updateDates(data.result, state)
      }
      state.subscriptions = data
    },
    [getCommerceTransactions.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        // sortInsights(data.result)
        // updateDates(data.result, state)
      }

      state.transactions = data
    },
    [getCommercePayouts.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        // sortInsights(data.result)
        // updateDates(data.result, state)
      }
      state.payouts = data
    },

    [getCommerceTransactionInsights.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        sortInsights(data)
        updateDates(data, state)
      }
      state.transactionInsights = data
    },
    [getCommerceSubscriptionsInsights.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        sortInsights(data)
        updateDates(data, state)
      }
      state.subscriptionInsights = data
    },
    [getCommerceDisputesInsights.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        sortInsights(data)
        updateDates(data, state)
      }
      state.disputesInsights = data
    },
  },
})

export default commerce.reducer

export const { updateCommerceDate } = commerce.actions
