import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ChevronRightIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="m15.4 9.88-4.59-4.59a1 1 0 0 0-1.41 0 1 1 0 0 0 0 1.42l4.6 4.58a1 1 0 0 1 0 1.42l-4.6 4.58a1 1 0 0 0 1.41 1.42l4.59-4.59a3 3 0 0 0 0-4.24Z" />
    </SvgIcon>
  )
}

export default ChevronRightIcon
