import React from 'react'

import DataSyncIcon from '../../icons/DataSyncIcon'
import NoConnection from '../../icons/NoConnection'
import PendingConnection from '../../icons/PendingConnection'
import WarnOutlinedIcon from '../../icons/WarnOutlinedIcon'
import DataConnexionIcon from '../../icons/DataConnexionIcon'

const syncAlertData = {
  FETCH_ERROR: { icon: <WarnOutlinedIcon />, color: '#FFC700', bg: '#FFF8DD' },
  AUTH_ERROR: { icon: <WarnOutlinedIcon />, color: '#FFC700', bg: '#FFF8DD' },
  INTERNAL_ERROR: {
    icon: <WarnOutlinedIcon />,
    color: '#FFC700',
    bg: '#FFF8DD',
  },
  AUTH_REQUIRED: {
    icon: <PendingConnection />,
    color: '#000000',
    bg: '#FFFFFF',
    border: '#AEAEAE',
  },
  CONNECTING: {
    icon: <PendingConnection />,
    color: '#000000',
    bg: '#FFFFFF',
    border: '#AEAEAE',
  },
  FETCHING: {
    icon: <DataSyncIcon />,
    color: '#000000',
    bg: '#FFFFFF',
    border: '#AEAEAE',
  },
  QUEUED: {
    icon: <DataSyncIcon />,
    color: '#000000',
    bg: '#FFFFFF',
    border: '#AEAEAE',
  },
  CONNECTED: {
    icon: <DataSyncIcon sx={{ color: '#009EF7' }} />,
    color: '#009EF7',
    bg: '#F1FAFF',
    border: '#009EF7',
  },
  DEFAULT: {
    icon: <NoConnection sx={{ color: '#009EF7' }} />,
    color: '#009EF7',
    bg: '#F1FAFF',
    border: '#009EF7',
  },
  UNIQUE_LINK: {
    icon: <DataConnexionIcon sx={{ color: '#009EF7' }} />,
    color: '#009EF7',
    bg: '#F1FAFF',
    border: '#009EF7',
  },
}

export default syncAlertData
