import { createSelector } from '@reduxjs/toolkit'
import min from 'date-fns/min'
import max from 'date-fns/max'

import { groupBy } from '../../utils'
import { financialStatementsSelector } from '../financialStatement'

const taxReturnAnalysisSelector = (state) => state.taxReturn.taxReturnAnalysis

const taxReturnAnalysisListSelector = (state) =>
  state.taxReturn.taxReturnAnalysisList

const taxReturnsSelector = (state) => state.taxReturn.taxReturns

const sectorAnalysisSelector = (state) => state.taxReturn.sectorAnalysis

const actsSelector = (state) => state.taxReturn.acts

const sectorAnalysisByIdSelector = (state) => state.taxReturn.sectorAnalysisById

const articlesOfAssociationSelector = (state) =>
  state.taxReturn.articlesOfAssociation

const registrationProofSelector = (state) => state.taxReturn.registrationProof

const taxClearanceCertificateSelector = (state) =>
  state.taxReturn.taxClearanceCertificate

const taxReturnAnalysisDateSelector = (state) =>
  new Date(state.taxReturn.taxReturnAnalysisDate)

const yearTaxReturnAnalysisListSelector = createSelector(
  taxReturnAnalysisListSelector,
  taxReturnAnalysisDateSelector,
  (list, date) =>
    list?.filter(
      (t) => new Date(t.closing_date).getFullYear() === date.getFullYear()
    )
)

const taxReturnAnalysisDateRangeSelector = createSelector(
  taxReturnAnalysisSelector,
  (data) => {
    const dates = data?.map((t) => new Date(t.closing_date))

    return data?.length ? { min: min(dates), max: max(dates) } : undefined
  }
)

const taxReturnAndFinancialStatements = createSelector(
  taxReturnsSelector,
  financialStatementsSelector,
  (taxReturn, financialStatements) => {
    const taxReturnsByYear = groupBy('closing_year', taxReturn?.result)
    const financialStatementsByYear = groupBy(
      'closing_year',
      financialStatements?.result
    )

    return [
      ...new Set([
        ...Object.keys(taxReturnsByYear),
        ...Object.keys(financialStatementsByYear),
      ]),
    ]
      .sort()
      .reverse()
      .map((y) => ({
        closing_year: y,
        taxReturn: {
          STANDALONE:
            taxReturnsByYear[y]?.find((d) => d?.type === 'COMPLETE') ||
            taxReturnsByYear[y]?.find((d) => d?.type === 'SIMPLIFIED'),
          CONSOLIDATED: taxReturnsByYear[y]?.find(
            (d) => d?.type === 'CONSOLIDATED'
          ),
        },
        financialStatement: {
          STANDALONE: financialStatementsByYear[y]?.find(
            (d) => d?.type === 'STANDALONE' || d?.type === null
          ),
          CONSOLIDATED: financialStatementsByYear[y]?.find(
            (d) => d?.type === 'CONSOLIDATED'
          ),
        },
      }))
  }
)

export {
  taxReturnAnalysisSelector,
  taxReturnAnalysisListSelector,
  taxReturnsSelector,
  registrationProofSelector,
  articlesOfAssociationSelector,
  actsSelector,
  taxReturnAnalysisDateSelector,
  yearTaxReturnAnalysisListSelector,
  taxReturnAnalysisDateRangeSelector,
  sectorAnalysisSelector,
  taxReturnAndFinancialStatements,
  sectorAnalysisByIdSelector,
  taxClearanceCertificateSelector,
}
