import ApiClient from '../apiClient'
import { getUserId } from '../user'

const client = new ApiClient()

const getDataConnections = ({
  userId = getUserId(),
  page = 1,
  per_page = 20,
}) => {
  return client.get(`/users/${userId}/data-connections`, {
    params: { page, per_page },
  })
}

const addDataConnection = ({ userId = getUserId(), ...input }) => {
  return client.post(`/users/${userId}/data-connections`, input)
}

const updateDataConnection = ({
  userId = getUserId(),
  dataConnectionId,
  ...input
}) => {
  return client.patch(
    `/users/${userId}/data-connections/${dataConnectionId}`,
    input
  )
}

const getDataConnection = ({ userId = getUserId(), dataConnectionId }) => {
  return client.get(`/users/${userId}/data-connections/${dataConnectionId}`)
}

const deleteDataConnection = ({ userId = getUserId(), dataConnectionId }) => {
  return client.delete(`/users/${userId}/data-connections/${dataConnectionId}`)
}

const dataConnectionService = {
  getDataConnections,
  addDataConnection,
  getDataConnection,
  deleteDataConnection,
  updateDataConnection,
}

export default dataConnectionService
