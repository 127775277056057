import React, { forwardRef } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { BaseCardRoot } from './BaseCard.style'
import Scrollbar from '../Scrollbar'

const BaseCard = forwardRef((props, ref) => {
  const {
    title,
    children,
    right,
    header,
    onTileClick,
    loading,
    justifyContent = 'space-between',
    alignItems = 'flex-start',
    className = '',
    height = '100%',
    Status,
    LastSyncDate,
    dataTypes,
    RefreshIcon,
    left,
    hasData,
    scroll,
    suppressScrollX,
    ...other
  } = props

  const showChildren = !loading && (hasData || !dataTypes?.length)

  return (
    <BaseCardRoot
      elevation={0}
      {...other}
      sx={{ height, ...other.sx }}
      className={`BaseCard ${className} ${showChildren ? 'filled' : ''}`}
      ref={ref}
    >
      {(title || left || RefreshIcon || right) && (
        <Box
          display="flex"
          justifyContent={justifyContent}
          alignItems={alignItems}
          gap={2}
          mb={2}
          className="Header"
        >
          {!left && (
            <Box flexGrow={1} className="Left">
              {title && (
                <Typography
                  sx={{ cursor: onTileClick ? 'pointer' : undefined }}
                  onClick={onTileClick}
                  variant="h6"
                >
                  {title}
                </Typography>
              )}
              {LastSyncDate}
            </Box>
          )}
          {left && left}

          {right && right}
          {!right && RefreshIcon}
        </Box>
      )}

      {Status}
      {!loading && header}
      {showChildren &&
        (scroll ?? suppressScrollX ? (
          <Scrollbar maxHeight="300px" suppressScrollX={suppressScrollX}>
            {children}
          </Scrollbar>
        ) : (
          children
        ))}
    </BaseCardRoot>
  )
})

BaseCard.displayName = 'BaseCard'

export default BaseCard
