import ButtonBase from '@mui/material/ButtonBase'
import styled from 'styled-components'

const height = 33

export const NavItemRoot = styled(ButtonBase)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding-right: 16px;
  white-space: pre;
  border-radius: 5px;
  width: 100%;
  height: ${height}px;
  transition: width 0.3s ease-out, height 0.3s ease-out,
    margin-left 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 1;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #7e8299;

  &.SubNavItem {
    color: #5b5b5b;
    margin-left: 16px;
    width: calc(100% - 16px);
  }

  &:hover {
    background: #f4f4f4;
  }

  &.hide {
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
  }

  &.active {
    color: ${({ theme }) => theme.palette.text.primary};

    &.SubNavItem {
      border-left: 4px solid ${({ theme }) => theme.palette.primary.main};
      border-radius: 0 5px 5px 0;

      .left > svg:first-child {
        margin-left: 12px !important;
      }
    }
  }

  &.compact {
    width: 49px;
    height: ${height}px;
    margin-left: 8px;
    margin-right: 8px;
    color: white;

    &:hover,
    &.active {
      background: #f4f4f4;
      color: inherit;

      svg {
        color: inherit;
      }
    }

    .itemIcon {
      display: none;
    }

    .itemText {
      opacity: 0;
      width: 0;
    }
  }

  .itemText {
    font-size: inherit;
    padding-left: 0.8rem;
    display: flex;
    align-items: center;
    text-align: left;
    flex-grow: 1;
    transition: all 0.2s;
  }

  &.active {
    background: #f4f4f4;
    color: #000000;
  }

  .right {
    margin-left: 8px;
    height: 17px;

    svg {
      font-size: 17px;
    }
  }

  .left {
    display: flex;
    align-items: center;

    > svg:first-child {
      vertical-align: middle;
      margin-left: 16px;
      font-size: 13px;
    }

    &:not(.has-icon) {
      // padding-left: 40px;
    }
  }

  .itemIcon {
    display: flex;
    align-items: center;
    align-items: center;
  }

  .chevron {
    color: #7e8299;
    font-size: 16px;
  }

  .expandIcon {
    transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform: rotate(90deg);
  }

  .collapseIcon {
    transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform: rotate(0deg);
  }
`

export const NavLabelItemRoot = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
`
