import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const PendingConnection = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 20">
      <path
        d="M11.2917 10C13.2125 8.43134 14.5138 6.23141 14.9633 3.7925C15.0319 3.32555 14.999 2.84932 14.867 2.39621C14.735 1.94309 14.5069 1.52373 14.1983 1.16667C13.8849 0.801615 13.4964 0.508434 13.0593 0.307104C12.6223 0.105773 12.147 0.00102587 11.6658 0L3.33667 0C2.85477 0.000261535 2.37861 0.104646 1.94079 0.306008C1.50297 0.50737 1.11383 0.800955 0.800005 1.16667C0.490598 1.5244 0.262131 1.94475 0.13024 2.39896C-0.00165135 2.85318 -0.033853 3.33052 0.0358388 3.79833C0.483265 6.23542 1.78331 8.43377 3.70334 10C1.78328 11.5668 0.48351 13.7658 0.0366723 16.2033C-0.0328831 16.6708 -0.000690482 17.1478 0.13105 17.6017C0.262791 18.0556 0.490974 18.4757 0.800005 18.8333C1.11383 19.199 1.50297 19.4926 1.94079 19.694C2.37861 19.8954 2.85477 19.9997 3.33667 20H11.6658C12.1477 19.9996 12.6238 19.8951 13.0616 19.6938C13.4994 19.4924 13.8886 19.1989 14.2025 18.8333C14.5116 18.4757 14.74 18.0557 14.872 17.6018C15.004 17.1479 15.0366 16.6709 14.9675 16.2033C14.5159 13.7654 13.2132 11.567 11.2917 10ZM12.3558 17.1325C12.2796 17.2455 12.1769 17.3381 12.0566 17.4022C11.9363 17.4663 11.8021 17.4998 11.6658 17.5H3.33251C3.19608 17.5001 3.06173 17.4667 2.94124 17.4027C2.82075 17.3387 2.71781 17.2461 2.64145 17.1331C2.5651 17.02 2.51766 16.89 2.50332 16.7543C2.48897 16.6186 2.50815 16.4815 2.55917 16.355C3.42563 14.4899 4.74307 12.87 6.39251 11.6417L6.97584 11.1758C7.1233 11.0585 7.30616 10.9947 7.49459 10.9947C7.68301 10.9947 7.86587 11.0585 8.01334 11.1758L8.58834 11.635C10.2377 12.8677 11.5572 14.4884 12.43 16.3533C12.4824 16.4794 12.503 16.6163 12.4901 16.7522C12.4772 16.888 12.4311 17.0186 12.3558 17.1325ZM10.29 15.8333H4.70667C5.45541 14.7323 6.37568 13.7583 7.4325 12.9483L7.49917 12.8933L7.55834 12.9392C8.61477 13.755 9.53655 14.7316 10.29 15.8333Z"
        fill="black"
      />
    </SvgIcon>
  )
}

export default PendingConnection
