import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import React from 'react'

import Checkbox from '@mui/material/Checkbox'
import Scrollbar from '../../../components/Scrollbar'
import { useI18n } from '../../../context'
import DataTypesCheckGroup from '../../DataConnections/UpdateConnexion/DataTypesCheckGroup'
import { SyncDataTypesResult } from './SyncDataTypes.style'

const DataTypeSearchResult = ({
  onCheck,
  onCheckCategory,
  values,
  data,
  catResult,
  resultCategories,
  open,
}) => {
  const { t } = useI18n()

  return (
    <Scrollbar
      suppressScrollX
      update={data.length}
      updateAfter={open && data.length > 2 ? 400 : undefined}
      visiblility="always"
      background="transparent"
      size="small"
      variant="square"
    >
      <SyncDataTypesResult>
        {catResult.map((r) => (
          <Box
            display="flex"
            flexDirection="column"
            gap={0.5}
            paddingLeft="5px"
            key={r}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name={r.category}
                  checked={
                    !r.dataTypes.some(
                      (dataType) =>
                        !values.requested_data_types.includes(dataType)
                    )
                  }
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                  onChange={onCheckCategory}
                />
              }
              className="Title"
              display={resultCategories.length >= 1 ? undefined : 'none'}
              label={t(`common.${r.category}`)}
            />
            <DataTypesCheckGroup
              className={r.category}
              values={values}
              onChange={onCheck}
              minSplit={2}
              types={r.dataTypes}
            />
          </Box>
        ))}

        {!resultCategories.length && (
          <Typography>{t('common.No result found')}</Typography>
        )}
      </SyncDataTypesResult>
    </Scrollbar>
  )
}

export default DataTypeSearchResult
