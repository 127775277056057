import React from 'react'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import AutocompleteCompanies from './AutocompleteCompanies'

const AutocompleteDialog = ({ onClose, open }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg" keepMounted fullWidth>
        <AutocompleteCompanies fromSearch />
        <IconButton
          aria-label="close"
          onClick={onClose}
          id="bt-search"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
    </>
  )
}

export default AutocompleteDialog
