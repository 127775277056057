/* eslint-disable max-lines */
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 100%;
    height: 100%;
    overflow: hidden;
  }

  body {
    font-size: 12px !important;
    line-height: 1.833;
    overflow-y: auto;
    position: relative;
    min-height: 100%;
    color: ${({ theme }) => theme.palette.text.primary}
  }

  * {
    font-family: 'Poppins', sans-serif !important;
  }

  html,
  body {
    overflow-x: hidden;
  }

  strong, b {
    font-weight: bolder;
  }

  /* Media Queries above 992px */
  @media (min-width: 1680px) {
    body {
      font-size: 14px !important;
    }
  }

  .text-center {
    text-align: center;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  .d-none {
    display: none;
  }

  .ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  /* clear cross button on input */

  input::-ms-clear,
  input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button,
  input::-webkit-search-results-button,
  input::-webkit-search-results-decoration {
    display: none;
  }

  /** Material input */
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }

  
/* Change the input autofill colors in webkit browsers */

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  a {
    // color: inherit; /* blue colors for links too */
    color: #2196F3;
    text-decoration: inherit; /* no underline */
  }

  .rotate {
    animation:spin 1.5s linear infinite;
  }

  @keyframes spin { 
      100% {
          transform:rotate(360deg); 
      } 
  }

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  .BaseCard .MuiPaper-root {
    box-shadow: none !important;
    border: 1px solid gainsboro;
  }

  .card-title {
    line-height: 29px;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0;
  }

  .card-content {
    margin-top: 15px;
  }

  .delete-btn {
    color: #fff !important;
    background-color: #F1416C !important;
    :hover {
      background-color: #D9214E !important
    }
  }

  .widget-title {
    font-size: 14px;
    font-weight: 600;
    :hover {
        text-decoration: underline;
    }
  }

  .badge {
    display: inline-flex;
    align-items: center;
    padding: 0.325rem 0.5rem;
    font-size: 0.85rem;
    font-weight: 600;
    line-height: 1;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.325rem;
  }

  .badge-success {
    background-color: #50CD89;
  }

  .badge-danger {
    background-color: #F1416C;
  }

  .badge-default {
    color: #7E8299;
    background-color: #F5F8FA;
  }

  .iconBox {
    display: flex;
    align-items: center;
    margin-right: -12px;
  }

  .me-4 {
    margin-right: 1rem !important;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .d-flex {
    display: flex !important;
  }

  .text-danger {
    color: #f1416c !important;
  }

  .text-warning {
    color: #ffc700 !important;
  }

  .text-success {
    color: #50cd89 !important;
  }

  .text-default {
    color: #757575 !important;
  }
`

export default GlobalStyle
