import Box from '@mui/material/Box'
import styled from 'styled-components'

export const UpdateConnexionRoot = styled(Box)`
  padding: 16px 20px;

  @media (min-width: 500px) {
    min-width: 425px;
  }

  h6 {
    font-weight: 600;
    font-size: 16px;
  }

  .subTitle {
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: #a1a5b7;
  }

  .toggler {
    font-weight: 500;
    font-size: 9px !important;
    line-height: 14px;
    color: #7e8299;

    &:not(:hover) {
      background-color: transparent !important;
    }
  }

  .list {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }

  .MuiAlert-root {
    color: #912741;
    background: #fad9e2;
  }
`
