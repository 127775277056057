import { combineReducers } from '@reduxjs/toolkit'

import dataSyncReducer from './dataSync'
import dataConnectionReducer from './dataConnection'
import providerReducer from './provider'
import userReducer from './user'
import companyReducer from './company'
import webhookReducer from './webhook'
import documentReducer from './document'
import taxReturnReducer from './taxReturn'
import taxAccountReducer from './taxAccount'
import financialStatementReducer from './financialStatement'
import vatDeclarationReducer from './vatDeclaration'
import corporateTaxReducer from './corporateTax'
import courtDecisionReducer from './courtDecision'
import bankingReducer from './banking'
import commerceReducer from './commerce'
import contactReducer from './contact'
import googleNewsReducer from './googleNews'
import franceBankReducer from './franceBank'
import webInfoReducer from './webInfo'
import officerReducer from './officer'
import reportReducer from './report'
import banqueDeFranceReducer from './banqueDeFrance'
import complyCubeReducer from './complyCube'

const rootReducer = combineReducers({
  user: userReducer,
  dataConnection: dataConnectionReducer,
  dataSync: dataSyncReducer,
  provider: providerReducer,
  company: companyReducer,
  webhook: webhookReducer,
  document: documentReducer,
  taxReturn: taxReturnReducer,
  financialStatement: financialStatementReducer,
  vatDeclaration: vatDeclarationReducer,
  taxAccount: taxAccountReducer,
  corporateTax: corporateTaxReducer,
  courtDecision: courtDecisionReducer,
  banking: bankingReducer,
  commerce: commerceReducer,
  contact: contactReducer,
  googleNews: googleNewsReducer,
  franceBank: franceBankReducer,
  webInfo: webInfoReducer,
  officer: officerReducer,
  report: reportReducer,
  banqueDeFrance: banqueDeFranceReducer,
  complyCube: complyCubeReducer,
})

export default rootReducer
