import { createTheme } from '@mui/material/styles'

import baseTheme from './baseTheme'

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } })

const theme = createTheme({
  name: 'light',
  palette: {
    primary: { main: '#3DFFC0' },
    secondary: { main: '#041F3D' },
    error: { main: '#f1416c' },
    background: { default: '#f4f4f4' },
    anger: createColor('#F40B27'),
    qDark: createColor('#181C32'),
    warning: { main: '#FFC700' },
    info: { main: 'rgba(148,146,146,0.96)' },
  },
  ...baseTheme,
})

export default theme
