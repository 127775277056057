import React from 'react'

import UncheckIcon from '../../components/icons/UncheckIcon'
import CheckIcon from '../../components/icons/CheckIcon'

const baseTheme = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '18px',
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          lineHeight: '18px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '12px',
        },
        body2: {
          fontSize: '12px',
        },
        subtitle1: {
          fontSize: '14px',
          textTransform: 'initial',
          fontWeight: 600,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <UncheckIcon sx={{ fontSize: '15px' }} />,
        checkedIcon: <CheckIcon sx={{ fontSize: '15px' }} />,
      },
    },
  },
  shape: {
    borderRadius: 5,
  },
  scrollBar: {
    small: 4,
    default: 6,
    medium: 8,
    large: 11,
  },
}

export default baseTheme
