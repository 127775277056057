/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import contactService from '../../services/contact'

const initialState = {
  contacts: null,
}

export const getContacts = createAsyncThunk(
  'contact/getContacts',
  contactService.getContacts
)

const contact = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getContacts.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.contacts = data
    },
  },
})

export default contact.reducer
