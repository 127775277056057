import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import bankingService from '../../services/banking'

const initialState = {
  bankingAccounts: null,
  creditInsights: null,
  transactions: null,
  cashFlow: null,
}

export const getCreditInsights = createAsyncThunk(
  'banking/getCreditInsights',
  bankingService.getCreditInsights
)

export const getBankingAccounts = createAsyncThunk(
  'banking/getBankingAccounts',
  bankingService.getBankingAccounts
)

export const getTransactions = createAsyncThunk(
  'banking/getTransactions',
  bankingService.getTransactions
)

export const getTransaction = createAsyncThunk(
  'banking/getTransaction',
  bankingService.getTransaction
)

export const getSwift = createAsyncThunk(
  'banking/getSwift',
  bankingService.getSwift
)

export const getCashFlow = createAsyncThunk(
  'banking/getCashFlow',
  bankingService.getCashFlow
)

const banking = createSlice({
  name: 'banking',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getBankingAccounts.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.bankingAccounts = data
    },
    [getCashFlow.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.cashFlow = data
    },
    [getCreditInsights.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data?.cash_flows || data?.loans || data?.risks) {
        state.creditInsights = data
      } else state.creditInsights = null
    },
    [getCreditInsights.rejected]: (state) => {
      state.creditInsights = null
    },
    [getTransactions.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.transactions = data
    },
  },
})

export default banking.reducer
