import Stack from '@mui/material/Stack'
import styled from 'styled-components'

export const VerticalNavRoot = styled(Stack)`
  > .WhiteNav {
    .NavItem {
      color: #041f3d;
    }
  }

  .DarkNav {
    background-color: white;
    min-height: calc(100vh - 244px);
  }

  &.compact {
    .WhiteNav,
    .DarkNav {
      padding-left: 0;
      padding-right: 0;
    }
  }
`
