import React, { useState } from 'react'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'

import { EnIcon, FrIcon } from '../icons'
import { useI18n } from '../../context'
import { LanguageButton, LanguageMenuRoot } from './Appbar.style'

const icon = {
  fr: <FrIcon />,
  en: <EnIcon />,
}

const LanguageMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => setAnchorEl(null)

  const handleOpen = (e) => setAnchorEl(e.currentTarget)

  const { currentLanguage, setCurrentLanguage } = useI18n()

  const handleLangageChange = (language) => () => {
    setCurrentLanguage(language)
    handleClose()
  }

  return (
    <>
      <LanguageButton onClick={handleOpen} className="LanguageMenuButton">
        <ListItemIcon>{icon[currentLanguage]}</ListItemIcon>
      </LanguageButton>

      <LanguageMenuRoot
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLangageChange('fr')}>
          <ListItemIcon>
            <FrIcon />
          </ListItemIcon>
          <ListItemText primary="Français" />
        </MenuItem>
        <MenuItem onClick={handleLangageChange('en')}>
          <ListItemIcon>
            <EnIcon />
          </ListItemIcon>
          <ListItemText primary="English" />
        </MenuItem>
      </LanguageMenuRoot>
    </>
  )
}

export default LanguageMenu
