import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ConnectionErrorIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 65 65" fill="none">
      <path
        d="M32.5 0C14.55 0 0 14.55 0 32.5S14.55 65 32.5 65 65 50.45 65 32.5C64.98 14.559 50.441.02 32.5 0Zm10.833 39.506a2.708 2.708 0 1 1-3.827 3.827L32.5 36.33l-7.004 7.003a2.708 2.708 0 0 1-3.83-3.83l7.005-7.003-7.004-7.004a2.708 2.708 0 0 1 3.83-3.83l7.003 7.005 7.006-7.004a2.708 2.708 0 1 1 3.827 3.827L36.33 32.5l7.003 7.006Z"
        fill="#F14E6D"
      />
    </SvgIcon>
  )
}

export default ConnectionErrorIcon
