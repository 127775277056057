/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import financialStatementService from '../../services/financialStatement'

const initialState = {
  financialStatements: null,
}

export const getFinancialStatements = createAsyncThunk(
  'financialStatement/getFinancialStatements',
  financialStatementService.getFinancialStatements
)

const financialStatement = createSlice({
  name: 'financialStatement',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getFinancialStatements.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.financialStatements = data
    },
  },
})

export default financialStatement.reducer
