/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import googleNewsService from '../../services/googleNews'

const initialState = {
  googleNews: null,
}

export const getGoogleNews = createAsyncThunk(
  'googleNews/getGoogleNews',
  googleNewsService.getGoogleNews
)

const googleNews = createSlice({
  name: 'googleNews',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getGoogleNews.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.googleNews = data
    },
  },
})

export default googleNews.reducer
