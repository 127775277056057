/* eslint-disable max-lines */
import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import { Avatar, Box } from '@mui/material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import CardContent from '@mui/material/CardContent'
import min from 'date-fns/min'
import { Business } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import AddUser from '../../pages/Users/AddUser'
import MakerIcon from '../icons/MakerIcon'
import { CompanyRoot } from './Company.style'
import { formatAmount, format } from '../../utils'
import { useI18n } from '../../context'

const Company = ({ company, fromSearch }) => {
  const { t } = useI18n()
  const [user, setUser] = useState()

  const handleOpen = () => {
    document.getElementById('bt-search').click()

    setUser({
      type: 'legal',
      name: company?.name,
      siren: company.registration_number,
    })
  }

  const closingDate = company?.rcs_closing_date || company?.insee_closing_date
  const date =
    closingDate &&
    min(
      [company.rcs_closing_date, company.insee_closing_date]
        .filter(Boolean)
        .map((n) => new Date(n))
    )

  const handleClose = () => {
    setUser(false)
  }

  return (
    <CompanyRoot>
      <Paper variant="outlined" className="card-company">
        <CardContent pb={3}>
          <Stack className="stack-header">
            <Avatar
              variant="circular"
              sx={{ background: 'rgba(220, 220, 223, 0.5)' }}
            >
              <Business
                color={date ? 'disabled' : 'primary'}
                fontSize="medium"
              />
            </Avatar>
            <Box mx={2} sx={{ width: '100%' }}>
              <Typography variant="h6" className="heading">
                {company.name}{' '}
                <Typography className="stack-siren" variant="p">
                  {company.registration_number}
                </Typography>
              </Typography>
              <Typography variant="p">{company.legal?.form}</Typography>
              {company?.registration_date && (
                <Typography variant="caption" sx={{ fontSize: 'x-small' }}>
                  {',  '}
                  {t('common.companyCreatedOn', {
                    date: format(company.registration_date, 'PPP'),
                  })}
                </Typography>
              )}
              {date && (
                <Typography variant="caption">
                  {',  '}
                  <Typography
                    color="red"
                    sx={{ fontSize: 'x-small' }}
                    variant="caption"
                  >
                    {t('common.companyClosedAt', {
                      date: format(date, 'PPP'),
                    })}
                  </Typography>
                </Typography>
              )}
              <Stack
                direction="row"
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Stack direction="row" className="stack-infos">
                  <Stack direction="row" className="stack-info">
                    <MakerIcon className="stack-icon" />
                    <Typography
                      variant="inherit"
                      className="ellipsis"
                      textTransform="capitalize"
                    >
                      {company.address.city}{' '}
                      {company.address.postal_code && (
                        <span> {company.address.postal_code}</span>
                      )}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack className="paper-footer-subcontent">
                  <Button
                    onClick={handleOpen}
                    variant="outlined"
                    color="secondary"
                  >
                    <AddIcon />
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Box mt={2}>
            <Paper variant="rounded outlined" className="paper-footer">
              <CardContent sx={{ paddingBottom: '12px !important' }}>
                <Stack direction="column" sx={{ display: 'flex' }}>
                  <Stack className="paper-footer-content">
                    <Typography variant="h6 subtitle1">
                      {company.activity.ape_name} {company.activity.ape_code}
                    </Typography>
                    {company.capital?.amount && (
                      <Stack className="paper-footer-subcontent ">
                        <Typography
                          variant="inherit"
                          className="ellipsis"
                          textTransform="capitalize"
                        >
                          Capital:{' '}
                          <span>
                            {formatAmount(
                              company.capital.amount,
                              company.capital?.currency
                            )}
                          </span>
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </CardContent>
            </Paper>
          </Box>
        </CardContent>
      </Paper>
      <AddUser
        open={!!user}
        user={user}
        onClose={handleClose}
        parentType={user?.type}
        fromSearch={fromSearch}
      />
    </CompanyRoot>
  )
}

export default Company
