import Dialog from '@mui/material/Dialog'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import Zoom from '@mui/material/Zoom'
import { useHistory } from 'react-router-dom'

import LegalUserForm from './LegalUserForm'
import NaturalUserForm from './NaturalUserForm'
import UserSelect from './UserSelect'
import { createUser, getGroups, getMetrics } from '../../../redux'
import { useThunk } from '../../../hooks'
import { Button, Snackbar } from '../../../components'
import { useI18n } from '../../../context'
import { resetSyncs, updateLoadingTypes } from '../../../redux/dataSync'
import { resetConnections } from '../../../redux/dataConnection'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />
})

const AddUser = ({ open, user, onClose, parentType, fromSearch }) => {
  const [index, setIndex] = useState(0)
  const [type, setType] = useState('legal')
  const history = useHistory()

  const { t } = useI18n()

  const swiperRef = useRef()
  const { dispatch, errorMsg, loading, errors, data, setErrors, ...tk } =
    useThunk()
  const tk2 = useThunk()

  const handleChangeIndex = (newIndex) => {
    setIndex(newIndex)
  }

  const handleSubmit = (values) => {
    dispatch(() => createUser({ type, ...values }))
  }

  const handleUpdate = () => {
    setTimeout(() => swiperRef.current?.updateHeight())
  }

  const handleView = () => {
    tk.run(updateLoadingTypes())
    tk.run(resetSyncs())
    tk.run(resetConnections())
    tk.run(getGroups())

    setTimeout(() => {
      history.push(`/user/${data?.id}/company`)
    })
  }

  const handleChangeType = (newType) => {
    setType(newType)
    setIndex(1)
  }

  const handleBack = () => {
    setIndex(0)
  }

  useEffect(() => {
    setType(parentType)
  }, [parentType])

  const Form = useMemo(() => {
    return type === 'natural' ? NaturalUserForm : LegalUserForm
  }, [type])

  const swiperAction = (actions) => {
    swiperRef.current = actions
  }

  useEffect(() => {
    if (!open) {
      setIndex(0)
      setErrors(null)
    } else {
      tk2.dispatch(() => getGroups())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (user) setIndex(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (data) {
      onClose()
      setIndex(0)
      tk.run(getMetrics())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!loading && !!data && fromSearch) {
      window.location.replace(`/user/${data?.id}/company`)
    }
  }, [data, fromSearch, loading])

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        fullWidth
        maxWidth="xs"
        TransitionComponent={Transition}
        keepMounted
        transitionDuration={{ enter: 400, exit: 200 }}
      >
        <SwipeableViews
          index={index}
          onChangeIndex={handleChangeIndex}
          animateHeight
          ref={swiperRef}
          action={swiperAction}
        >
          <UserSelect onSelect={handleChangeType} onClose={onClose} />
          <Form
            serverErrors={errors?.[0]}
            onSubmit={handleSubmit}
            onClose={onClose}
            onBack={handleBack}
            onUpdate={handleUpdate}
            loading={loading}
            open={open}
            user={user}
          />
        </SwipeableViews>
      </Dialog>

      <Snackbar
        open={!loading && !!data}
        severity="success"
        id="snackbar-user"
        action={
          <Button
            onClick={handleView}
            variant="contained"
            disableElevation
            color="primary"
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: '#EDF3EE',
              background: '#295C2C',
              borderRadius: '2px',
            }}
          >
            {t('common.Accéder')}
          </Button>
        }
      >
        {t('common.userAdded')}
      </Snackbar>

      <Snackbar open={!loading && !!errors} error={!!errors}>
        {errorMsg}
      </Snackbar>
    </>
  )
}

export default AddUser
