import { useState, useEffect, useCallback, useRef } from 'react'

export const useVisbilityState = ({
  onVisible,
  onChange,
  value = 'visible',
}) => {
  const [visibilityState, setVisibilityState] = useState(value)

  const onVisibleCallback = useRef()
  const onChangeCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    onVisibleCallback.current = onVisible
    onChangeCallback.current = onChange
  })

  const handleVisbilityChange = useCallback(() => {
    if (onVisibleCallback.current && document.visibilityState === 'visible') {
      onVisibleCallback.current()
    }

    onChangeCallback.current?.(document.visibilityState)

    setVisibilityState(document.visibilityState)
  }, [])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisbilityChange)

    return () =>
      document.removeEventListener('visibilitychange', handleVisbilityChange)
  }, [handleVisbilityChange])

  return visibilityState
}
