import React, { forwardRef } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import MuiButton from '@mui/material/Button'

const Button = forwardRef((props, ref) => {
  const { children, loading, loaderSize = 20, disableRadius, ...other } = props

  return (
    <MuiButton
      {...{ ref, $disableRadius: disableRadius }}
      {...other}
      disableElevation
      disabled={loading || other.disabled}
      endIcon={
        loading ? (
          <CircularProgress color="inherit" size={loaderSize} />
        ) : (
          other.endIcon
        )
      }
    >
      {children}
    </MuiButton>
  )
})

Button.displayName = 'Button'

export default Button
