/**
 * Handles variable substitution on the provided string. It scans through the string looking for
 * expressions enclosed in curly braces. If an expression is found, use it as a key on the object,
 * and if the key has a string value or number value, it is substituted for the bracket expression
 * and it repeats.
 * @namespace Utils
 * @param {string} str the provided string. If i
 * @param {object | null} obj values to replace in the provided string. If null or empty, the string is return as is
 */

const supplant = (str, obj) => {
  if (!obj || Object.keys(obj).length === 0) return str

  return str?.replace(/{([^{}]*)}/g, (a, b) => {
    const value = obj[b]

    return typeof value === 'string' || typeof value === 'number' ? value : a
  })
}

const downloadURI = (uri, name = '') => {
  const link = document.createElement('a')

  link.setAttribute('download', name)
  link.href = uri
  document.body.appendChild(link)
  link.click()
  link.remove()
}

const truncate = (text, size) => {
  if (text.length > size) return `${text.substring(0, size)}...`

  return text
}

const getAddress = (address) => {
  if (!address) return address

  // eslint-disable-next-line global-require
  const { useI18n } = require('../context')

  return [
    address.address_line1,
    address.postal_code,
    address.city,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    address.country ? useI18n().t(`country.${address.country}`) : '',
  ]
    .filter((n) => n?.trim?.().length)
    .join(', ')
}

const copyToClipboard = (text) => {
  const field = document.createElement('textarea')

  field.style.height = '0px'
  field.style.width = '0px'
  field.style.position = 'fixed'
  field.style.top = '-9999px'

  document.body.appendChild(field)
  // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
  field.value = text
  field.select()
  field.setSelectionRange?.(0, 99999) /* For mobile devices */

  // document.execCommand('copy')
  navigator.clipboard.writeText(field.value)
  document.body.removeChild(field)
}

const formatDataType = (type) => {
  return type
    ?.split('_')
    .map((n) => n[0].toUpperCase() + n.slice(1).toLowerCase())
    .join(' ')
}

const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

const validColor = (strColor, defaultColor = '') => {
  const s = new Option().style

  s.color = strColor

  return s.color !== '' ? strColor : defaultColor
}

const isValidHttpUrl = (value) => {
  let url

  try {
    url = new URL(value)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export {
  supplant,
  downloadURI,
  truncate,
  getAddress,
  copyToClipboard,
  formatDataType,
  camelToSnakeCase,
  validColor,
  isValidHttpUrl,
}
