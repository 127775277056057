import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const BackgroundCheck = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" width="512" height="512">
      <path d="m11 17.5a6.5 6.5 0 0 1 11-4.684v-9.816a3 3 0 0 0 -3-3h-12a5.006 5.006 0 0 0 -5 5v14a5.006 5.006 0 0 0 5 5h10.5a6.5 6.5 0 0 1 -6.5-6.5zm-4-10.5a1 1 0 0 1 1-1h8a1 1 0 0 1 0 2h-8a1 1 0 0 1 -1-1zm16.707 16.707a1 1 0 0 1 -1.414 0l-2.407-2.407a4.457 4.457 0 0 1 -2.386.7 4.5 4.5 0 1 1 4.5-4.5 4.457 4.457 0 0 1 -.7 2.386l2.407 2.407a1 1 0 0 1 0 1.414z" />
    </SvgIcon>
  )
}

export default BackgroundCheck
