/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import documentService from '../../services/document'

const initialState = {
  inseeRegistrationProof: null,
  beneficialOwners: null,
  registrationProof: null,
  acts: null,
  articlesOfAssociation: null,
}

export const getBeneficialOwners = createAsyncThunk(
  'document/getBeneficialOwners',
  documentService.getBeneficialOwners
)

export const getRegistrationProof = createAsyncThunk(
  'document/getRegistrationProof',
  documentService.getRegistrationProof
)

export const getActs = createAsyncThunk(
  'document/getActs',
  documentService.getActs
)

export const getInseeRegistrationProof = createAsyncThunk(
  'document/getInseeRegistrationProof',
  documentService.getInseeRegistrationProof
)

export const getArticlesOfAssociation = createAsyncThunk(
  'document/getArticlesOfAssociation',
  documentService.getArticlesOfAssociation
)

const document = createSlice({
  name: 'document',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getInseeRegistrationProof.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.inseeRegistrationProof = data
    },
    [getRegistrationProof.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.registrationProof = data
    },
    [getArticlesOfAssociation.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.articlesOfAssociation = data
    },
    [getBeneficialOwners.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.beneficialOwners = data
    },
    [getActs.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.acts = data
    },
    [getInseeRegistrationProof.pending]: (state) => {
      state.inseeRegistrationProof = null
    },
    [getRegistrationProof.pending]: (state) => {
      state.registrationProof = null
    },
    [getArticlesOfAssociation.pending]: (state) => {
      state.articlesOfAssociation = null
    },
    [getBeneficialOwners.pending]: (state) => {
      state.beneficialOwners = null
    },
    [getActs.pending]: (state) => {
      state.acts = null
    },
  },
})

export default document.reducer
