import React, { useState } from 'react'
import Divider from '@mui/material/Divider'
import { NavLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import ExpandableNavItem from './ExpandableNavItem'
import NavItem from './NavItem'
import NavLabelItem from './NavLabelItem'
import { getNavUrl } from '../../context'
import { VerticalNavRoot } from './VerticalNav.style'

const VerticalNav = ({ items, compact, onNavItemClick, showPrimaryNav }) => {
  const [expand, setExpand] = useState(false)

  const renderLevels = (data) => {
    return data
      .map((item, index) => {
        if (item.display === false) return null

        const compactOnly = item.variant === 'compact'
        const defaultOnly = item.variant === 'default'
        const hide = (compactOnly && !compact) || (defaultOnly && compact)

        if (item.type === 'separator') {
          return hide ? null : <Divider key={index} />
        }

        if (item.type === 'gutter') {
          return hide ? null : <Box flexGrow={1} key={index} />
        }

        if (item.type === 'custom') {
          const Component = item.icon

          return <Component key={index} />
        }

        if (item.type === 'label') {
          return <NavLabelItem item={item} key={index} compact={compact} />
        }

        if (item.children) {
          return (
            <ExpandableNavItem
              compact={compact}
              item={item}
              key={index}
              spacing={1}
              expand={expand === item.path}
              setExpand={setExpand}
            >
              {renderLevels(item.children)}
            </ExpandableNavItem>
          )
        }

        const url = getNavUrl(item)

        return (
          <NavItem
            compact={compact}
            item={item}
            onClick={onNavItemClick}
            name="child"
            component={NavLink}
            key={index}
            to={url}
            exact
          />
        )
      })
      .filter(Boolean)
  }

  const navs = renderLevels(items)

  return (
    <VerticalNavRoot
      className={`navigation ${compact ? ' compact' : ''}`}
      spacing={1}
    >
      {showPrimaryNav && (
        <Stack bgcolor="white" p="16px" pb={0} className="WhiteNav" spacing={1}>
          {navs.slice(0, 3)}
        </Stack>
      )}

      {navs.slice(3).length > 3 && (
        <Stack
          p="16px"
          pt={showPrimaryNav ? 0 : undefined}
          className="DarkNav"
          spacing={1}
        >
          {navs.slice(3)}
        </Stack>
      )}
    </VerticalNavRoot>
  )
}

export default React.memo(VerticalNav)
