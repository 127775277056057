import ApiClient from '../apiClient'
import { getUserId } from '../user'

const client = new ApiClient()

const getBackgroundCheck = ({ userId = getUserId() }) => {
  return client.get(`/users/${userId}/background-check`)
}

const getBackgroundCheckV2 = ({ userId = getUserId() }) => {
  return client.get(`/users/${userId}/background-check-v2`)
}

const franceBankService = {
  getBackgroundCheck,
  getBackgroundCheckV2,
}

export default franceBankService
