import { DEFAULT_PAGE_SIZE } from '../../config'
import ApiClient from '../apiClient'
import { getUserId } from '../user'

const client = new ApiClient()

const getDataSyncs = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/sync`, { params: { page, per_page } })
}

const getLatestDataSyncs = ({ userId = getUserId(), dataType = [] }) => {
  return client.get(
    `/users/${userId}/sync/latest${dataType ? `?data_type=${dataType}` : ''}`
  )
}

const addDataSync = ({ userId = getUserId(), ...input }) => {
  return client.post(`/users/${userId}/sync`, input)
}

const getDataSync = ({ userId = getUserId(), dataSyncId }) => {
  return client.get(`/users/${userId}/sync/${dataSyncId}`)
}

const dataSyncService = {
  getDataSyncs,
  getDataSync,
  addDataSync,
  getLatestDataSyncs,
}

export default dataSyncService
