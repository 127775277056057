import React, { useEffect } from 'react'
import Collapse from '@mui/material/Collapse'
import { useHistory, useLocation } from 'react-router-dom'
import Stack from '@mui/material/Stack'

import NavItem from './NavItem'
import { useTheme } from '../../context'

const ExpandableNavItem = ({
  item,
  compact,
  expand,
  setExpand,
  children,
  spacing,
}) => {
  const { push } = useHistory()
  const { pathname } = useLocation()

  const { theme } = useTheme()

  const url =
    typeof item.url === 'string' ? item.url : item.url?.() || item.path

  const isActive = pathname === url

  const hasActiveChild = item.children?.some(
    (i) =>
      i.path === pathname ||
      pathname === (typeof i.url === 'string' ? i.url : i.url?.() || i.path)
  )

  const handleClick = () => {
    if (compact && item.icon && url) {
      return push(url)
    }

    if (item.routing && url) push(url)

    // if (hasActiveChild) return

    setExpand((v) => (v === item.path ? null : item.path))
  }

  useEffect(() => {
    if (!isActive && !hasActiveChild && expand) setExpand(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isActive])

  useEffect(() => {
    // OPEN DROPDOWN IF CHILD IS ACTIVE
    if (hasActiveChild) setExpand(item.path)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasActiveChild])

  const active = isActive && (compact || item.routing)

  const color =
    active || hasActiveChild
      ? `${theme.palette.text.primary} !important`
      : undefined

  const itemColor = active
    ? `${theme.palette.text.primary} !important`
    : undefined

  return (
    <Stack
      // bgcolor={active || hasActiveChild ? '#0B344A' : undefined}
      borderRadius="5px"
    >
      <NavItem
        compact={compact}
        item={item}
        expand={expand}
        onClick={handleClick}
        className={active ? 'active' : ''}
        sx={{ color }}
      />
      <Collapse
        in={compact ? true : !!expand}
        timeout="auto"
        className="ExpandableNavItems"
        sx={{ '& .NavItem': { color: itemColor } }}
        // sx={{ marginLeft: !compact ? '16px' : undefined }}
      >
        <Stack mt={compact ? undefined : spacing} spacing={spacing}>
          {children}
        </Stack>
      </Collapse>
    </Stack>
  )
}

export default ExpandableNavItem
