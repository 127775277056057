import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const RefreshIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 511.494 511.494">
      <path d="M478.291 255.492c-16.133.143-29.689 12.161-31.765 28.16-15.37 105.014-112.961 177.685-217.975 162.315S50.866 333.006 66.236 227.992 179.197 50.307 284.211 65.677a192.168 192.168 0 0 1 96.907 43.959l-24.107 24.107c-8.33 8.332-8.328 21.84.004 30.17a21.333 21.333 0 0 0 15.142 6.246h97.835c11.782 0 21.333-9.551 21.333-21.333V50.991c-.003-11.782-9.556-21.331-21.338-21.329a21.333 21.333 0 0 0-15.078 6.246l-28.416 28.416C320.774-29.34 159.141-19.568 65.476 86.152S-18.415 353.505 87.304 447.17s267.353 83.892 361.017-21.828a255.752 255.752 0 0 0 61.607-132.431c2.828-17.612-9.157-34.183-26.769-37.011a32.265 32.265 0 0 0-4.868-.408z" />
    </SvgIcon>
  )
}

export default RefreshIcon
