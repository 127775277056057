/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import vatDeclarationService from '../../services/vatDeclaration'

const initialState = {
  vatDeclarations: null,
}

export const getVatDeclarations = createAsyncThunk(
  'vatDeclaration/getVatDeclarations',
  vatDeclarationService.getVatDeclarations
)

const vatDeclaration = createSlice({
  name: 'vatDeclaration',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getVatDeclarations.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.vatDeclarations = data
    },
  },
})

export default vatDeclaration.reducer
