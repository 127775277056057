import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const BgCheckErrorIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" width="512" height="512">
      <path d="M24,12A12,12,0,1,1,12,0,12.013,12.013,0,0,1,24,12ZM13,5H11V15h2Zm0,12H11v2h2Z" />
    </SvgIcon>
  )
}

export default BgCheckErrorIcon
