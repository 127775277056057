import ApiClient from '../apiClient'
import { getUserId } from '../user'
import { DEFAULT_PAGE_SIZE } from '../../config'

const client = new ApiClient()

const getConformityCheck = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/conformity-checks`, {
    params: { page, per_page },
  })
}

const complyCubeService = {
  getConformityCheck,
}

export default complyCubeService
