const companiesSelector = (state) => state.company.companies

const profileSelector = (state) => state.company.profile

const collectiveProceduresSelector = (state) =>
  state.company.collectiveProcedures

const companiesAutocompleteSelector = (state) =>
  state.company.companiesAutocomplete

const hasProceduresSelector = (state) =>
  !!state.company.collectiveProcedures?.result?.length

const corporateOfficesSelector = (state) => state.company.corporateOffices

const timelinesSelector = (state) => state.company.timelines

const bankingRelationshipsSelector = (state) =>
  state.company.bankingRelationships

const pclByUserSelector = (state) => state.company.pclByUser

const dataConnectionsByUserSelector = (state) =>
  state.company.dataConnectionsByUser

const profileByUserSelector = (state) => state.company.profileByUser

const infoLoadingSelector = (state) => state.company.infoLoading

const trademarksSelector = (state) => state.company.trademarks

export {
  companiesSelector,
  profileSelector,
  timelinesSelector,
  corporateOfficesSelector,
  collectiveProceduresSelector,
  dataConnectionsByUserSelector,
  hasProceduresSelector,
  bankingRelationshipsSelector,
  pclByUserSelector,
  profileByUserSelector,
  infoLoadingSelector,
  trademarksSelector,
  companiesAutocompleteSelector,
}
