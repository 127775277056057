import Box from '@mui/material/Box'
import styled from 'styled-components'

export const AdvancedSettingsRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 4px;

  .MuiCollapse-root {
    .MuiCollapse-vertical {
      opacity: 0;
      transition: opacity 0.3s ease-out;
    }
  }

  .MuiCollapse-root.MuiCollapse-entered {
    .MuiCollapse-vertical {
      opacity: 1;
    }
  }

  .MuiTextField-root {
    font-size: 11px;
  }

  .tooltipText {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 3px;
    margin-top: 8px;

    svg {
      color: #c9c9c9;
      font-size: 18px;
      margin-left: 2px;
    }

    p {
      font-weight: 500;
      font-size: 9px;
      line-height: 14px;
      color: #7e8299;
    }
  }

  > button {
    justify-content: flex-start;
    margin-left: -8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-decoration-line: underline;
    width: fit-content;

    &:hover {
      text-decoration-line: underline;
    }

    .MuiButton-endIcon {
      margin-left: 3px;
    }
  }

  .expandIcon {
    transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform: rotate(0deg);
  }

  .collapseIcon {
    transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform: rotate(-90deg);
  }
`
