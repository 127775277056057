import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ConnectionIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M5.22116 10.778C4.84979 10.4066 4.57109 9.97327 4.36708 9.51056L5.41384 8.46379C5.53052 8.97117 5.77921 9.45055 6.16391 9.83525C6.69929 10.3706 7.41069 10.6653 8.16743 10.6653C8.92417 10.6653 9.63624 10.3706 10.171 9.83525L13.838 6.16824C14.3733 5.63286 14.668 4.92146 14.668 4.16472C14.668 3.40799 14.3733 2.69592 13.838 2.1612C12.7332 1.05643 10.9364 1.05643 9.83092 2.1612L9.16086 2.83193C8.73148 2.72592 8.28811 2.66525 7.83407 2.66525C7.69539 2.66525 7.55871 2.67525 7.42203 2.68525L8.88817 1.21911C10.513 -0.406371 13.1566 -0.406371 14.7814 1.21911C16.4062 2.84393 16.4062 5.48751 14.7814 7.11233L11.1144 10.7793C10.3276 11.5668 9.28087 12.0001 8.1681 12.0001C7.05533 12.0001 6.00856 11.5668 5.22182 10.7793L5.22116 10.778ZM0 11.8321C0 12.9449 0.433374 13.9916 1.22078 14.7784C2.00752 15.5658 3.05362 15.9992 4.16706 15.9992C5.28049 15.9992 6.32659 15.5658 7.11333 14.7784L8.58014 13.3116C8.44346 13.3216 8.30611 13.3316 8.1681 13.3316C7.71406 13.3316 7.27068 13.2709 6.84131 13.1649L6.17058 13.8356C5.63519 14.371 4.92379 14.6657 4.16706 14.6657C3.41032 14.6657 2.69892 14.371 2.16354 13.8356C1.62815 13.3002 1.33346 12.5888 1.33346 11.8321C1.33346 11.0754 1.62815 10.3633 2.16354 9.82859L5.83055 6.16158C6.36593 5.62619 7.07733 5.3315 7.83407 5.3315C8.59081 5.3315 9.30221 5.62619 9.83692 6.16091C10.2216 6.54628 10.4703 7.02566 10.587 7.53304L11.6338 6.48627C11.4297 6.02356 11.151 5.59019 10.7797 5.21882C9.99294 4.43142 8.94617 3.99804 7.8334 3.99804C6.72063 3.99804 5.67387 4.43142 4.88713 5.21882L1.22078 8.88583C0.433374 9.67257 0 10.7193 0 11.8321Z" />
    </SvgIcon>
  )
}

export default ConnectionIcon
