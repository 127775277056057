import Box from '@mui/material/Box'
import styled from 'styled-components'

export const PCLLabel = styled(Box)`
  background: #fad9e2;
  border-radius: 5px;
  flex-grow: 1;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  gap: 24px;
  min-height: 52px;

  b {
    font-weight: 700;
  }

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 12px;

    svg {
      font-size: 15px;
    }
  }

  button {
    padding: 1px 8px;
  }
`
