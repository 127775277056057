/* eslint-disable max-lines */
import React from 'react'

import { useI18n } from '../../context'
import { getUserId } from '../../services/user'
import DataSyncAlert from './DataSyncAlert'
import DataType, { syncProviders } from './DataType'

const PendingSyncAlert = ({ onClose, closedAlert, pendingSyncs }) => {
  const { t } = useI18n()

  const id = `PendingSync_${syncProviders(pendingSyncs)}_${getUserId()}`

  const closed = !!closedAlert?.[id]
  const open = !!pendingSyncs?.length && !closed

  const handleClose = () => {
    onClose?.(id)
  }

  return (
    <DataSyncAlert
      title={t('common.alert.pendingSync.title')}
      status="FETCHING"
      onClose={handleClose}
      open={open}
    >
      {t('common.alert.pendingSync')}

      <DataType syncs={pendingSyncs} />
    </DataSyncAlert>
  )
}

export default PendingSyncAlert
