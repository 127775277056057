import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const TrademarkIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-trademark"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M4.5 9h5m-2.5 0v6" />
        <path d="M13 15v-6l3 4l3 -4v6" />
      </svg>
    </SvgIcon>
  )
}

export default TrademarkIcon
