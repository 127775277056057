/* eslint-disable react/destructuring-assignment */
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const DataSyncIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_295_2534)">
        <path
          d="M2.07673 7.12271C2.56416 3.84203 5.64451 1.57388 8.95691 2.05665C10.087 2.22135 11.1472 2.69894 12.0152 3.43441L11.2338 4.20834C10.9719 4.46779 10.9719 4.88844 11.2339 5.14783C11.3597 5.27233 11.5302 5.3423 11.708 5.34233H14.7841C15.1545 5.34233 15.4548 5.04491 15.4548 4.67802V1.63143C15.4547 1.26454 15.1543 0.967181 14.7839 0.967243C14.6061 0.967274 14.4356 1.03725 14.3098 1.16174L13.4378 2.02535C10.1126 -0.910381 5.01406 -0.620466 2.04994 2.67291C0.999834 3.83964 0.318377 5.28585 0.0899885 6.83239C-5.90757e-05 7.38067 0.375695 7.89744 0.929248 7.98663C0.979114 7.99466 1.02948 7.99899 1.08001 7.99961C1.58646 7.99419 2.01158 7.62017 2.07673 7.12271Z"
          fill={props.fill}
        />
        <path
          d="M15.0732 7.9996C14.5667 8.00502 14.1416 8.37905 14.0765 8.87651C13.5891 12.1572 10.5087 14.4253 7.19631 13.9426C6.06619 13.7779 5.00605 13.3003 4.13802 12.5648L4.91943 11.7909C5.18134 11.5315 5.18127 11.1108 4.91928 10.8514C4.79354 10.7269 4.62301 10.6569 4.44521 10.6569H1.36922C0.998781 10.6569 0.698486 10.9543 0.698486 11.3212V14.3678C0.698581 14.7347 0.998938 15.0321 1.36938 15.032C1.54718 15.032 1.71771 14.962 1.84345 14.8375L2.71541 13.9739C6.03984 16.9099 11.138 16.6209 14.1025 13.3283C15.1531 12.1613 15.8349 10.7146 16.0632 9.16751C16.1536 8.61929 15.7782 8.10227 15.2247 8.01271C15.1746 8.00459 15.124 8.00019 15.0732 7.9996Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_295_2534">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default DataSyncIcon
