import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const DisputeIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 13 13">
      <path d="M13 8.66667V13H8.66667C7.90676 12.9992 7.16042 12.7986 6.50252 12.4183C5.84462 12.038 5.29829 11.4914 4.91833 10.8333C5.32668 10.8304 5.73364 10.7854 6.13275 10.699C6.43671 11.0791 6.82229 11.3858 7.26093 11.5966C7.69956 11.8074 8.18001 11.9168 8.66667 11.9167H11.9167V8.66667C11.9165 8.17984 11.8068 7.69928 11.5957 7.26063C11.3845 6.82198 11.0773 6.43649 10.6968 6.13275C10.784 5.73371 10.8297 5.32676 10.8333 4.91833C11.4914 5.29829 12.038 5.84462 12.4183 6.50252C12.7986 7.16042 12.9992 7.90676 13 8.66667ZM9.75 4.875C9.75 3.58207 9.23639 2.34209 8.32215 1.42785C7.40791 0.513615 6.16793 0 4.875 0C3.58207 0 2.34209 0.513615 1.42785 1.42785C0.513615 2.34209 0 3.58207 0 4.875L0 9.75H4.875C6.16749 9.74857 7.40663 9.23449 8.32056 8.32056C9.23449 7.40663 9.74857 6.16749 9.75 4.875ZM1.08333 4.875C1.08333 4.12508 1.30571 3.392 1.72234 2.76846C2.13898 2.14493 2.73116 1.65894 3.42399 1.37196C4.11683 1.08497 4.87921 1.00989 5.61472 1.15619C6.35023 1.30249 7.02584 1.66361 7.55611 2.19389C8.08639 2.72416 8.44751 3.39977 8.59381 4.13528C8.74011 4.87079 8.66503 5.63317 8.37804 6.32601C8.09106 7.01885 7.60507 7.61102 6.98154 8.02766C6.358 8.44429 5.62492 8.66667 4.875 8.66667H1.08333V4.875Z" />
    </SvgIcon>
  )
}

export default DisputeIcon
