/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import dataSyncService from '../../services/dataSync'
import { getUserId } from '../../services/user'

const initialState = {
  dataSyncs: null,
  latestDataSyncs: null,
  allSyncs: null,
  allSyncsLoading: false,
  loadingTypes: [],
  addDataSyncByType: {},
  user_id: getUserId(),
}

export const getDataSync = createAsyncThunk(
  'dataSync/getDataSync',
  dataSyncService.getDataSync
)

export const getLatestDataSyncs = createAsyncThunk(
  'dataSync/getLatestDataSyncs',
  dataSyncService.getLatestDataSyncs
)

export const addDataSync = createAsyncThunk(
  'dataSync/addDataSync',
  dataSyncService.addDataSync
)

export const getDataSyncs = createAsyncThunk(
  'dataSync/getDataSyncs',
  dataSyncService.getDataSyncs
)

export const getAllDataSyncs = createAsyncThunk(
  'dataSync/getAllDataSyncs',
  dataSyncService.getDataSyncs
)

const dataSync = createSlice({
  name: 'dataSync',
  initialState,
  reducers: {
    updateLoadingTypes: (state, action) => {
      state.loadingTypes = action.payload || []
    },
    resetSyncs: () => initialState,
  },
  extraReducers: {
    [addDataSync.pending]: (state, action) => {
      const { data_types = [] } = action.meta.arg

      state.loadingTypes = data_types

      data_types?.forEach((type) => {
        delete state.addDataSyncByType[type]
      })
    },
    [addDataSync.fulfilled]: (state, action) => {
      const { data_types = [] } = action.meta.arg
      const { data, errors } = action.payload

      data_types?.forEach((type) => {
        state.addDataSyncByType[type] = { data, errors }
      })
    },
    [getDataSyncs.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.dataSyncs = data

      if (!state.allSyncs) {
        state.allSyncs = data
      } else {
        state.allSyncs.result = JSON.parse(
          JSON.stringify([...(data?.result || []), ...state.allSyncs.result])
        ).sort(
          (a, b) =>
            new Date(b.requested_at).getTime() -
            new Date(a.requested_at).getTime()
        )
      }

      state.loadingTypes = state.allSyncs?.result
        ?.filter(
          (sync) => sync.status === 'FETCHING' || sync.status === 'QUEUED'
        )
        .map((s) => s.data_type)
    },
    [getAllDataSyncs.pending]: (state) => {
      state.allSyncsLoading = true
    },
    [getAllDataSyncs.rejected]: (state) => {
      state.allSyncsLoading = false
    },
    [getAllDataSyncs.fulfilled]: (state, action) => {
      const { data, status } = action.payload

      state.allSyncsLoading = false
      state.status = status

      if (data) {
        data.user_id = getUserId()
      }

      state.user_id = getUserId()

      if (state.user_id !== getUserId()) {
        state.loadingTypes = []
      }

      state.allSyncs = data

      state.loadingTypes = state.allSyncs?.result
        ?.filter(
          (sync) => sync.status === 'FETCHING' || sync.status === 'QUEUED'
        )
        .map((s) => s.data_type)
    },
    [getLatestDataSyncs.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.latestDataSyncs = data
    },
  },
})

export const { updateLoadingTypes, resetSyncs } = dataSync.actions

export default dataSync.reducer
