import React from 'react'

import { useNav } from '../../context'
import Scrollbar from '../Scrollbar'
import SidebarUser from '../SidebarUser'
import VerticalNav from '../VerticalNav'
import { MenuContent, SidebarRoot } from './Sidebar.style'

const Sidebar = ({
  open,
  variant,
  onClose,
  compact,
  onCompactChange: _,
  ...other
}) => {
  const { navigations } = useNav()

  return (
    <SidebarRoot
      elevation={0}
      anchor="left"
      onClose={onClose}
      open={open}
      variant={variant}
      className={variant + (compact ? ' compact' : '')}
      ModalProps={{ keepMounted: true }}
      PaperProps={{ elevation: 2 }}
    >
      <SidebarUser />

      <Scrollbar
        background="transparent"
        suppressScrollX
        offset={false}
        maxHeight={
          variant === 'temporary'
            ? 'calc(100vh - 100px)'
            : 'calc(100vh - 244px)'
        }
      >
        <MenuContent
          {...other}
          className="MenuContent"
          // bgcolor={userId ? 'secondary.main' : 'white'}
        >
          <VerticalNav
            items={navigations}
            onNavItemClick={onClose}
            compact={compact}
            showPrimaryNav={variant === 'temporary'}
          />
        </MenuContent>
      </Scrollbar>
    </SidebarRoot>
  )
}

export default React.memo(Sidebar)
