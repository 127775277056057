import { useEffect, useState } from 'react'
import { isProd } from '../config'
import { useI18n } from '../context'

/**
 * Hook to handle asynchronous action lifecycle (pending, fulfilled, rejected).

 * @param action async to dispatch
 * @param payloadErrors indicates whether to check error fied on the payload object
 * @param payloadData indicates whether to check data fied on the payload object
 *
 * @example <caption> Initialize with async action</caption>
 * const { dispatch, loading, error } = useAsync(() => fetch(someData))
 *
 * // and later call dispatch() when you want to run the action
 *
 * @example <caption> Dispatch with async action</caption>
 * const { dispatch, loading, error } = useAsync()
 *
 * // and later call dispatch(() => fetch(someData)) when you want to run login action
 *
 * @namespace Hooks
 */
const useAsync = (action, payloadErrors = true, payloadData = true) => {
  const [errors, setErrors] = useState()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const { t } = useI18n()

  const getErrorsFromPayload = (payload) => {
    if (!payloadErrors) return

    const error = payload?.error || payload?.errors

    if (!error) return

    return Array.isArray(error) ? error : [error]
  }

  const dispatch = async (newAction = action) => {
    setLoading(true)
    setErrors(undefined)
    setData(undefined)

    if (!newAction) return

    newAction()
      ?.then((result) => {
        const err = getErrorsFromPayload(result)

        if (err) {
          setErrors(err)
        } else {
          setErrors(undefined)

          const keys = typeof result === 'object' ? Object.keys(result) : []

          if (payloadData && keys.length === 1) {
            setData(result[keys[0]])
          } else {
            setData(result)
          }
        }
        setLoading(false)
      })
      .catch((err) => {
        if (!isProd) console.error(err)

        setErrors([{ message: 'An error has occured', code: '500' }])
        setLoading(false)
      })
  }

  useEffect(() => {
    if (action) dispatch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const errorMsg =
    errors?.[0] &&
    (errors?.[0]?.key
      ? t(`error.${errors?.[0]?.key}`)
      : errors?.[0]?.message || t('common.error.500'))

  return { data, loading, dispatch, errors, errorMsg, setErrors }
}

export default useAsync
