import React, { useContext, createContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { ProgressIndicator } from '../../components'
import ErrorPage from '../../pages/error'
import { getCollectiveProcedures } from '../../redux/company'
import { getAllDataConnections } from '../../redux/dataConnection'
import { getAllDataSyncs, getLatestDataSyncs } from '../../redux/dataSync'
import { getProviders, getSyncSettings } from '../../redux/provider'
import {
  getClientInfo,
  userByIdSelector,
  getUser,
  appStatusSelector,
  getClientSettings,
  getApiKey,
} from '../../redux'
import { getUserId } from '../../services/user'
import { login, logout } from '../../services/user/authHelper'
import { getHashParams } from '../../utils'

const AuthContext = createContext({
  logout: () => {},
  currentUser: null,
  userId: null,
})

const userLinks = ['/user', '/users', '/settings']

const onClient = typeof sessionStorage !== 'undefined'

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { pathname, state } = location

  const usersById = useSelector(userByIdSelector)
  const appStatus = useSelector(appStatusSelector)

  const paramUserId = pathname.includes('/user/')
    ? pathname
        .substring(pathname.indexOf('/user/') + 6)
        .split('/')
        .shift()
    : null

  const userId = userLinks.includes(pathname) ? getUserId() : paramUserId

  const currentUser = usersById[userId]

  if (onClient) {
    sessionStorage.setItem('selecteduser', userId || '')
  }

  const selectedUser = userId || state?.selecteduser

  const handleLogin = () => login(location)

  useEffect(() => {
    if (!localStorage.jwt) return

    dispatch(getClientInfo({}))
    dispatch(getProviders({ page: 1, per_page: 1000 }))
    dispatch(getSyncSettings())
    dispatch(getClientSettings())
    dispatch(getApiKey())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.jwt])

  useEffect(() => {
    if (!localStorage.jwt) return

    if (!userId) return

    dispatch(getAllDataConnections({ page: 1, per_page: 1000 }))
    dispatch(getCollectiveProcedures({}))
    dispatch(getAllDataSyncs({ page: 1, per_page: 1000 }))
    dispatch(getLatestDataSyncs({}))
    dispatch(getUser({ id: userId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, localStorage.jwt])

  const hash = getHashParams()

  useEffect(() => {
    if (hash.access_token) {
      localStorage.setItem('jwt', hash.access_token)
      localStorage.setItem('jwtDate', new Date().toISOString())

      const redirect = hash.state ? JSON.parse(window.atob(hash.state)) : '/'

      if (redirect.search?.includes('logout')) {
        redirect.search = undefined
      }

      history.replace(redirect)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    if (!localStorage.jwt) {
      login(location)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!localStorage.jwt) return <ProgressIndicator circular anchor="window" />

  const value = {
    logout,
    login: handleLogin,
    currentUser,
    userId: selectedUser,
  }

  if (appStatus.notFound) return <ErrorPage status="404" />

  if (appStatus.maintenanceMode) {
    return <ErrorPage status="503" title="Maintenance en cours" />
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => {
  return useContext(AuthContext)
}

export { useAuth }
export default AuthProvider
