import { DEFAULT_PAGE_SIZE } from '../../config'
import ApiClient from '../apiClient'
import { getUserId } from '../user'

const client = new ApiClient()

const getCreditInsights = ({ userId = getUserId() }) => {
  return client.get(`/users/${userId}/banking-credit-insights`)
}

const getCashFlow = ({ userId = getUserId() }) => {
  return client.get(`/users/${userId}/banking-cashflow`)
}

const getBankingAccounts = ({
  userId = getUserId(),
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(`/users/${userId}/banking-accounts`, {
    params: { page, per_page },
  })
}

const getBankingAccount = ({ userId = getUserId(), bankAccountId }) => {
  return client.get(`/users/${userId}/banking-accounts/${bankAccountId}`)
}

const getTransactions = ({
  userId = getUserId(),
  bankAccountId,
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
}) => {
  return client.get(
    `/users/${userId}/banking-accounts/${bankAccountId}/transactions`,
    { params: { page, per_page } }
  )
}

const getTransaction = ({
  userId = getUserId(),
  bankAccountId,
  transactionId,
}) => {
  return client.get(
    `/users/${userId}/banking-accounts/${bankAccountId}/transactions/${transactionId}`
  )
}

const getSwift = ({ bic }) => {
  return client.get(`/swift/${bic}`)
}

const bankingService = {
  getBankingAccounts,
  getCreditInsights,
  getBankingAccount,
  getCashFlow,
  getTransactions,
  getSwift,
  getTransaction,
}

export default bankingService
