import React, { Fragment, useEffect, useRef } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'

import { useSize } from '../../hooks'
import StyledPerfectScrollbar from './Scrollbar.style'

const Scrollbar = ({
  children,
  update,
  updateAfter,
  size = 'default',
  background,
  visiblility,
  variant,
  color,
  style,
  className = '',
  maxHeight,
  maxWidth,
  offset = true,
  sx,
  ...options
}) => {
  const isTouchscreen = useMediaQuery('(hover: none) and (pointer: coarse)')
  const ref = useRef(null)
  const { height, width } = useSize()

  const window = useSize()

  const ScrollbarRoot = isTouchscreen ? Fragment : StyledPerfectScrollbar

  const scrollbarProps = !isTouchscreen && {
    options,
    ref,
    size,
    background,
    visiblility,
    variant,
    color,
    style,
    maxHeight,
    offset,
  }

  useEffect(() => {
    ref.current?.updateScroll()
  }, [height, width, update])

  useEffect(() => {
    const timer = setTimeout(() => ref.current?.updateScroll(), updateAfter)

    return () => clearTimeout(timer)
  }, [updateAfter])

  useEffect(() => {
    const timer = setTimeout(() => ref.current?.updateScroll(), 500)

    return () => clearTimeout(timer)
  }, [window.height, window.width])

  return (
    <Box
      className={`Scrollbar ${className}`}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      position="relative"
      role="region"
      overflow={isTouchscreen ? 'auto' : undefined}
      sx={sx}
    >
      <ScrollbarRoot {...scrollbarProps}>{children}</ScrollbarRoot>
    </Box>
  )
}

export default Scrollbar
