import { createSelector } from '@reduxjs/toolkit'
import { groupBy } from '../../utils'

const dataConnectionStateSelector = (state) => state.dataConnection

const dataConnectionsSelector = (state) => state.dataConnection.dataConnections

const hasDataConnectionsSelector = (state) =>
  state.dataConnection.dataConnections?.last_page > 0

const allDataConnectionsSelector = (state) =>
  state.dataConnection.allDataConnections

const dataConnectionsUserSelector = (state) =>
  state.dataConnection.connectedDataConnections?.user_id

const connectionStatusSelector = (state) => state.dataConnection.status

const connectedDataConnectionsSelector = createSelector(
  allDataConnectionsSelector,
  (data) => data?.result.filter((d) => d.status === 'CONNECTED') || []
)

const connectionsByProviderSelector = createSelector(
  allDataConnectionsSelector,
  (data) => groupBy('provider_name', data?.result || [])
)

export {
  dataConnectionStateSelector,
  dataConnectionsSelector,
  hasDataConnectionsSelector,
  connectedDataConnectionsSelector,
  dataConnectionsUserSelector,
  connectionStatusSelector,
  connectionsByProviderSelector,
  allDataConnectionsSelector,
}
