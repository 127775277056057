import React from 'react'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'

import { useI18n, useAuth } from '../../../context'
import { activeProvidersSelector } from '../../../redux/provider'
import { CategoryRoot } from './ProviderCategory.style'
import { NATURAL, excludeCategoriesForNatural } from '../../../config'

const ProviderCategory = ({ value, dataTypes, onChange }) => {
  const { t } = useI18n()
  const data = useSelector(activeProvidersSelector)
  const { currentUser } = useAuth()

  const providers = data.filter(
    (provider, index, self) =>
      index === self.findIndex((p) => p.category === provider.category)
  )

  const activeCategories = data
    .filter(
      (p) =>
        dataTypes?.length && dataTypes.some((d) => p.data_types?.includes(d))
    )
    .map((p) => p.category)

  const handleCategorySelect = (newVal) => () => {
    onChange(newVal)
  }

  return (
    <CategoryRoot>
      <Typography>{t('common.Catégories')}</Typography>

      <Typography
        onClick={handleCategorySelect('')}
        className={value === '' || value === 'idle' ? 'active' : ''}
      >
        {t('common.category.All')}
      </Typography>

      {providers.map(
        ({ category }) =>
          !(
            currentUser.type === NATURAL &&
            excludeCategoriesForNatural.includes(category)
          ) && (
            <Typography
              key={category}
              onClick={handleCategorySelect(category)}
              className={category === value ? 'active' : ''}
              sx={{
                pointerEvents:
                  dataTypes?.length && !activeCategories?.includes(category)
                    ? 'none'
                    : undefined,
              }}
              color={
                dataTypes?.length && !activeCategories?.includes(category)
                  ? 'darkgrey'
                  : undefined
              }
            >
              {t(`common.${category}`)}
            </Typography>
          )
      )}
    </CategoryRoot>
  )
}

export default ProviderCategory
