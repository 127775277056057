import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import DataSyncTable from './DataSyncTable'
import { PageContent } from '../../components'
import { useAuth } from '../../context'
import { getDataSyncs } from '../../redux/dataSync'
import { useThunk } from '../../hooks'

const DataSyncs = () => {
  const { userId } = useAuth()
  const { dispatch, loading } = useThunk()
  const { state } = useLocation()

  useEffect(() => {
    dispatch(() => getDataSyncs({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  useEffect(() => {
    if (state?.refresh) dispatch(() => getDataSyncs({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.refresh])

  return (
    <PageContent loading={loading}>
      <DataSyncTable />
    </PageContent>
  )
}

export default DataSyncs
