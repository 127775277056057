import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const SyncIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M7.96954 1.33352C8.85388 1.33642 9.72886 1.51468 10.5438 1.85797C11.3588 2.20127 12.0977 2.7028 12.7175 3.33352H10.6362C10.4594 3.33352 10.2898 3.40376 10.1648 3.52878C10.0398 3.6538 9.96954 3.82337 9.96954 4.00018C9.96954 4.177 10.0398 4.34656 10.1648 4.47159C10.2898 4.59661 10.4594 4.66685 10.6362 4.66685H13.3982C13.7265 4.66667 14.0413 4.53619 14.2734 4.30405C14.5055 4.07192 14.636 3.75713 14.6362 3.42885V0.666851C14.6362 0.49004 14.566 0.32047 14.4409 0.195446C14.3159 0.0704219 14.1463 0.000183976 13.9695 0.000183976C13.7927 0.000183976 13.6232 0.0704219 13.4981 0.195446C13.3731 0.32047 13.3029 0.49004 13.3029 0.666851V2.05218C12.201 1.05982 10.8446 0.39379 9.3856 0.128683C7.92661 -0.136424 6.42262 0.00985861 5.04206 0.551149C3.6615 1.09244 2.45888 2.00736 1.56888 3.19347C0.678874 4.37957 0.136631 5.79001 0.00286938 7.26685C-0.00574171 7.35969 0.00507989 7.4533 0.0346447 7.54173C0.0642094 7.63015 0.111869 7.71145 0.174587 7.78044C0.237305 7.84943 0.313706 7.9046 0.398921 7.94243C0.484137 7.98027 0.5763 7.99993 0.669536 8.00018C0.832595 8.00226 0.990559 7.94341 1.11251 7.83515C1.23446 7.72688 1.31161 7.57701 1.32887 7.41485C1.47728 5.75535 2.24085 4.21132 3.46959 3.08608C4.69833 1.96083 6.30341 1.33571 7.96954 1.33352Z" />
      <path d="M15.2701 8.00018C15.107 7.9981 14.949 8.05695 14.8271 8.16521C14.7051 8.27347 14.628 8.42335 14.6107 8.58551C14.5005 9.85433 14.0287 11.0649 13.2512 12.0736C12.4737 13.0824 11.4231 13.8469 10.2242 14.2765C9.02522 14.7062 7.7282 14.7829 6.48697 14.4976C5.24574 14.2123 4.1124 13.5769 3.2214 12.6668H5.30273C5.47955 12.6668 5.64912 12.5966 5.77414 12.4716C5.89916 12.3466 5.9694 12.177 5.9694 12.0002C5.9694 11.8234 5.89916 11.6538 5.77414 11.5288C5.64912 11.4037 5.47955 11.3335 5.30273 11.3335H2.54073C2.37813 11.3334 2.21711 11.3654 2.06687 11.4276C1.91663 11.4898 1.78012 11.5809 1.66514 11.6959C1.55016 11.8109 1.45898 11.9474 1.39679 12.0976C1.33461 12.2479 1.30265 12.4089 1.30273 12.5715V15.3335C1.30273 15.5103 1.37297 15.6799 1.498 15.8049C1.62302 15.9299 1.79259 16.0002 1.9694 16.0002C2.14621 16.0002 2.31578 15.9299 2.44081 15.8049C2.56583 15.6799 2.63607 15.5103 2.63607 15.3335V13.9482C3.73795 14.9405 5.09434 15.6066 6.55334 15.8717C8.01233 16.1368 9.51632 15.9905 10.8969 15.4492C12.2774 14.9079 13.4801 13.993 14.3701 12.8069C15.2601 11.6208 15.8023 10.2103 15.9361 8.73351C15.9447 8.64067 15.9339 8.54706 15.9043 8.45863C15.8747 8.37021 15.8271 8.28891 15.7643 8.21992C15.7016 8.15093 15.6252 8.09576 15.54 8.05793C15.4548 8.02009 15.3626 8.00042 15.2694 8.00018H15.2701Z" />
    </SvgIcon>
  )
}

export default SyncIcon
