import Stack from '@mui/material/Stack'
import styled from 'styled-components'

export const SidebarBottomRoot = styled(Stack)`
  padding-left: 16px;
  background-color: white;
  width: 100%;
  bottom: 0px;
  position: sticky;
  padding-bottom: 8px;
  padding-top: 8px;
  margin-top: -8px !important;

  .MuiIconButton-root {
    background: rgba(220, 220, 223, 0.5);

    :hover {
      background: rgba(220, 220, 223, 0.9);
    }

    svg {
      color: #848484;
      font-size: 16px;
    }

    &.active svg {
      color: black;
    }
  }
`
