import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import BackIcon from '../../../components/icons/BackIcon'
import { Button } from '../../../components'
import { useAuth, useI18n } from '../../../context'
import ConnexionActions from '../UpdateConnexion/ConnexionActions'
import ConnexionHeader from '../UpdateConnexion/ConnexionHeader'
import DataTypesCheckGroup from '../UpdateConnexion/DataTypesCheckGroup'
import AdvancedSettings from '../AdvancedSettings'
import { allowedDataTypesSelector } from '../../../redux/provider'
import { AddConnectionRoot } from '../AddConnection/AddConnection.style'
import { LEGAL } from '../../../config'

const ConnectionForm = ({
  onClose,
  provider,
  onAdd,
  loading,
  updateHeight,
  onBack,
}) => {
  const { t } = useI18n()
  const { currentUser } = useAuth()

  const types =
    currentUser.type !== LEGAL &&
    ['company_legal_fr', 'sandbox_public'].includes(provider?.name)
      ? ['BACKGROUND_CHECK', 'BACKGROUND_CHECK_V2']
      : provider?.data_types || []

  const allowedDataTypes = useSelector(allowedDataTypesSelector)
  const filterTypes = types.filter((type) => allowedDataTypes?.includes(type))

  const [values, setValues] = useState({
    provider_name: '',
    requested_data_types: types,
    skip_permissions: false,
    return_url: '',
  })

  const selectAll = filterTypes?.length === values.requested_data_types?.length
  const selectOne = values.requested_data_types?.length > 0

  const handleCheckAllToggle = () => {
    setValues({
      ...values,
      requested_data_types: selectAll ? [] : filterTypes,
    })
  }

  const handleCheck = (e) => {
    const { name } = e.target
    const dataTypes = values.requested_data_types

    if (dataTypes.includes(name)) {
      setValues({
        ...values,
        requested_data_types: dataTypes.filter((i) => i !== name),
      })
    } else {
      setValues({
        ...values,
        requested_data_types: [...values.requested_data_types, name],
      })
    }
  }

  const handleAdd = () => {
    onAdd(values)
  }

  useEffect(() => {
    setValues({
      ...values,
      requested_data_types: filterTypes,
      provider_name: provider?.name,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider?.name])

  return (
    <AddConnectionRoot>
      <ConnexionHeader
        title={t('common.Add connexion')}
        subTitle={t(`common.providers.${provider?.name}`)}
        onClose={onClose}
      />

      <Box display="flex" gap={1} alignItems="center">
        <Typography className="list">
          {t('common.authorizedDataTypes')}
        </Typography>

        <Button
          variant="text"
          color="inherit"
          className="toggler"
          onClick={handleCheckAllToggle}
        >
          {selectAll ? t('common.Uncheck all') : t('common.Check all')}
        </Button>
      </Box>

      <DataTypesCheckGroup
        values={values}
        allowedTypes={allowedDataTypes}
        onChange={handleCheck}
        types={types}
      />

      <AdvancedSettings
        updateHeight={updateHeight}
        values={values}
        setValues={setValues}
      />

      <ConnexionActions
        onOk={handleAdd}
        okText={t('common.Valider')}
        onClose={onBack}
        loading={loading}
        disabled={!selectOne}
        cancelText={<BackIcon />}
      />
    </AddConnectionRoot>
  )
}

export default ConnectionForm
