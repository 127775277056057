import { BASE_URL } from '../../config'

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const login = (currentState) => {
  const redirectUrl = `${BASE_URL}`
  const state = window.btoa(JSON.stringify(currentState))
  const url = `${API_BASE_URL}/oauth/login?response_type=token&client_id=&redirect_uri=${redirectUrl}&scope=api&state=${state}`

  window.location.replace(url)
}

export const logout = () => {
  localStorage.removeItem('jwt')
  localStorage.removeItem('user')
  sessionStorage.setItem('from', window.location.pathname)

  const redirectUrl = `${BASE_URL}`
  const url = `${API_BASE_URL}/oauth/logout?redirect_uri=${redirectUrl}`

  window.location.replace(url)
}
