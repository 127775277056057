import { unwrapResult } from '@reduxjs/toolkit'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { isProd } from '../config'

import { useI18n } from '../context'

/**
 * Hook to handle asynchronous thunk action lifecycle (pending, fulfilled, rejected).
 *
 * This hook is intended for use in any component that needs to know about request
 * status (loading, error, data). For components that need only the payload data,
 * you can useDispatch as usual.
 *
 * With this hook, we don't need to save request status (loading, error) in redux anymore,
 * so we gain for performance especially for reducers that have many async actions
 *
 * @param action async action to dispatch
 * @param payloadErrors indicates whether to check error fied on the payload object
 * @param payloadData indicates whether to check data fied on the payload object
 *
 * @example <caption>Initialize with async thunck action</caption>
 * const { dispatch, loading, error } = useThunk(() => login(inp))
 *
 * // and later call dispatch() when you want to run login action
 *
 * @example <caption>Dispatch with async thunck action</caption>
 * const { dispatch, loading, error } = useThunk()
 *
 * // and later call dispatch(() => login(userInput)) when you want to run login action
 *
 * @namespace Hooks
 */
const useThunk = (action, payloadErrors = true, payloadData = true) => {
  const dispatch = useDispatch()
  const { t } = useI18n()

  const [errors, setErrors] = useState()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)

  const getErrorsFromPayload = (payload) => {
    if (!payloadErrors) return

    const error = payload?.error || payload?.errors

    if (!error) return

    return Array.isArray(error) ? error : [error]
  }

  const handleDispatch = async (newAction = action) => {
    setLoading(true)
    setErrors(undefined)
    setData(undefined)

    if (!newAction) return

    dispatch(newAction())
      .then(unwrapResult)
      .then((result) => {
        const err = getErrorsFromPayload(result)

        if (err) {
          setErrors(err)
        } else {
          setErrors(undefined)

          const keys = typeof result === 'object' ? Object.keys(result) : []

          if (payloadData && keys.length === 1) {
            setData(result[keys[0]])
          } else {
            setData(result)
          }
        }
        setLoading(false)
      })
      .catch((err) => {
        if (!isProd) console.error(err)

        setErrors([{ message: t('common.error500'), code: '500' }])
        setLoading(false)
      })
  }

  const errorMsg =
    errors?.[0] &&
    (errors?.[0]?.key
      ? t(`error.${errors?.[0]?.key}`) || errors?.[0]?.message
      : t('common.error.500'))

  return {
    data,
    loading,
    dispatch: handleDispatch,
    run: dispatch,
    errors,
    errorMsg,
    setErrors,
  }
}

export default useThunk
