import { useEffect, useRef, useState } from 'react'

const useSize = ({ w = 0, h = 0 } = {}) => {
  const ref = useRef()
  const [width, setWidth] = useState(w)
  const [height, setHeight] = useState(h)

  useEffect(() => {
    const updateWidth = () => {
      const _width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth

      const _height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight

      setWidth(ref.current ? ref.current?.offsetWidth || w : _width)
      setHeight(ref.current ? ref.current?.offsetHeight || h : _height)
    }

    window.addEventListener('resize', updateWidth)
    updateWidth()

    return () => window.removeEventListener('resize', updateWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { ref, width, height }
}

export { useSize }
