import ApiClient from '../apiClient'

const client = new ApiClient()

const getWebhooks = () => {
  return client.get(`/webhooks`)
}

const getWebhook = ({ eventType }) => {
  return client.get(`/webhooks/${eventType}`)
}

const updateWebhook = ({ eventType, url, ...input }) => {
  return client.put(`/webhooks/${eventType}`, { url, ...input })
}

const deleteWebhook = ({ eventType }) => {
  return client.delete(`/webhooks/${eventType}`)
}

const webhookService = {
  getWebhooks,
  getWebhook,
  deleteWebhook,
  updateWebhook,
}

export default webhookService
