import { DEFAULT_PAGE_SIZE } from '../../config'
import ApiClient from '../apiClient'

const client = new ApiClient()

const getClientInfo = () => client.get(`/clients`)

const getProviders = async ({
  page = 1,
  per_page = DEFAULT_PAGE_SIZE,
} = {}) => {
  return client.get('/providers', { params: { page, per_page } })
}

const createProvider = async ({ type, ...input }) => {
  return client.post(`/providers/${type}`, input)
}

const deleteProviderCredentials = async ({ name }) => {
  return client.delete(`/providers/${name}/credentials`)
}

const updateProvider = async ({ name, enable }) => {
  return client.put(`/providers/${name}`, { enable })
}

const updateProviderSettings = async ({ name, ...input }) => {
  return client.put(`/providers/${name}/settings`, input)
}

const getProviderSettings = (name) => {
  return client.get(`/providers/${name}/settings`)
}

const updateProviderCredentials = async ({ name, ...input }) => {
  return client.put(`/providers/${name}/credentials`, input)
}

const getProvider = async ({ name }) => {
  return client.get(`/providers/${name}`)
}

const getSyncSettings = () => {
  return client.get(`/sync-settings`)
}

const updateSyncSettings = (input) => {
  return client.patch(
    `/sync-settings`,
    input.map((s) => ({
      data_type_name: s.data_type_name,
      frequency: s.frequency,
      schedule: s.schedule,
      initial_sync: s.initial_sync,
      month_history: s.month_history,
    }))
  )
}

const providerService = {
  getClientInfo,
  getProviders,
  createProvider,
  deleteProviderCredentials,
  getProvider,
  updateProviderSettings,
  updateProviderCredentials,
  updateProvider,
  getProviderSettings,
  getSyncSettings,
  updateSyncSettings,
}

export default providerService
