import Autocomplete from '@mui/material/Autocomplete'
import styled, { css } from 'styled-components'

export const UserSearchRoot = styled(Autocomplete)`
  width: 210px;
  margin-left: 0;

  .MuiInputBase-root {
    border-radius: 10px;
    background: white;
    padding: 6px 16px;
    padding-left: 16px !important;
  }

  * {
    border: none;
  }

  input::placeholder {
    color: ${({ theme }) => theme.palette.text.primary};
    opacity: 1;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      width: 300px;
      margin-left: 32px;

      .MuiInputBase-root {
        padding: 9px 16px;
      }
    }
  `}
`
