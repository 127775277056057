import React from 'react'

import ErrorPage from './pages/error'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      // Vous pouvez afficher n'importe quelle UI de repli.
      return <ErrorPage status="500" />
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children
  }
}

export default ErrorBoundary
