import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import styled from 'styled-components'

export const ProviderItemRoot = styled(ButtonBase)`
  display: flex;
  gap: 12px;
  padding: 8px;
  padding-right: 16px;
  text-align: left;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  .disabled-logo {
    filter: grayscale(100%);
  }

  .disabled-text {
    color: #b3b3b3 !important;
  }

  .MuiAvatar-root {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 35px;
    height: 35px;

    img {
      object-fit: contain;
      width: 29px;
      height: 29px;
    }
  }

  &:hover,
  &.Mui-disabled {
    background: #f4f4f4;
  }

  &.Mui-disabled {
    .MuiAvatar-root {
      background: rgba(217, 217, 217, 0.66);
    }
  }

  svg {
    font-size: 18px;
    pointer-events: all;
    color: #c9c9c9;
  }

  > .MuiBox-root {
    > .MuiTypography-body1 {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }

    > .MuiTypography-body2 {
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #a1a5b7;
    }
  }
`

export const ConnectionCountRoot = styled(Box)`
  width: 42px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  gap: 7px;
  cursor: pointer;

  svg {
    font-size: 13px;
  }
`
