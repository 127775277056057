import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const CopyIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <g clipPath="url(#CopyIcona)">
        <path
          d="M6.5 10A2.503 2.503 0 0 0 9 7.5V3.122a1.986 1.986 0 0 0-.586-1.415L7.293.586A1.986 1.986 0 0 0 5.878 0H3.5A2.503 2.503 0 0 0 1 2.5v5A2.503 2.503 0 0 0 3.5 10h3ZM2 7.5v-5A1.5 1.5 0 0 1 3.5 1s2.46.007 2.5.012V2a1 1 0 0 0 1 1h.988C7.993 3.04 8 7.5 8 7.5A1.5 1.5 0 0 1 6.5 9h-3A1.5 1.5 0 0 1 2 7.5ZM11 4v5.5A2.503 2.503 0 0 1 8.5 12H4a.5.5 0 0 1 0-1h4.5A1.5 1.5 0 0 0 10 9.5V4a.5.5 0 0 1 1 0Z"
          fill="#B3B3B3"
        />
      </g>
      <defs>
        <clipPath id="CopyIcona">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default CopyIcon
