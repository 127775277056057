import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const AntecedantIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" width="512" height="512">
      <path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z" />
    </SvgIcon>
  )
}

export default AntecedantIcon
